<script lang="jsx">
import { Icon } from '@shein-aidc/icon-vue2'
import SizeTitle from './SizeTitle'
export default {
  name: 'MallSelectContent',
  functional: true,
  props: {
    wrapExposeData: {
      type: Object,
      default: () => ({}),
    },
    mallTips: {
      type: String,
      default: '',
    },
    mallList: {
      type: Array,
      default: () => [],
    },
    handleChooseCheck: {
      type: Function,
      default: () => {},
    },
    isOnSale: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    mallCode: {
      type: String,
      default: '',
    },
    currentDisable: {
      type: Boolean,
      default: false,
    },
  },
  render(h, { props, listeners }) {
    const { wrapExposeData, isOnSale, mallList, handleChooseCheck, bold, title, mallCode, mallTips, currentDisable } =
      props || {}

    const mallItemContent = () =>
      mallList.map((item, index) => {
        const flag = item.mall_code == mallCode

        const chooseStatus = handleChooseCheck({
          attr_id: -1,
          mall_code: item.mall_code,
        })
        const isActive = flag && isOnSale && chooseStatus > 0

        const isSkuSoldOut = flag && (!isOnSale || chooseStatus == 0)
        const isSoldOut = !isOnSale || chooseStatus == 0 || currentDisable
        const style = { order: index + 1 }
        if (chooseStatus !== undefined) {
          return (
            <li
              key={index}
              class={[
                'goods-size__sizes-item',
                { 'size-active': isActive },
                { 'size-sold-out': isSkuSoldOut },
                { 'sold-out': isSoldOut },
              ]}
              style={style}
              type="text"
              aria-label={item.mall_name}
              v-on:click={() => listeners['mallClick'](item.mall_code)}
            >
              {item.mall_name}
            </li>
          )
        }
      })

    const mallTipsContent = () =>
      mallTips ? (
        <div class="goods-size__sizes-malltip">
          <Icon name="sui_icon_lightbulb2_24px" size="24px" color="#ef9b00" class="icon-light" />
          <div
            class="context"
            domPropsInnerHTML={mallTips}
          ></div>
        </div>
      ) : null

    return (
      <div
        {...{
          directives: [
            {
              name: 'expose',
              value: {
                id: 'expose_switch_mall.comp_sub-sales-attr',
                prefix: 'comp_sub-sales-attr',
                data: wrapExposeData,
              },
            },
          ],
        }}
        class="goods-size__mall"
      >
        <SizeTitle
          bold={bold}
          title={title}
        />
        <ul class="goods-size__sizes choose-size">{mallItemContent()}</ul>
        {mallTipsContent()}
      </div>
    )
  },
}
</script>
