// @ts-nocheck
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

const useHandleRightTag = ({
  defaultColorList = [],
  customizedTags,
  hasCustomizedTag,
  showLargeImage = false,
  isShowThickeningAbt = false,
  isShowNewTags = false,
}) => {
  const colorList = defaultColorList
  /** 有自定义标签需要等自定义标签数据回来再展示 */
  if (hasCustomizedTag && customizedTags === false) return colorList

  const { cssRight, siteUID } = useAppConfigs().$envs
  const isUs = !!~siteUID.indexOf('us')

  colorList.map(item => {
    /** 是否有自定义标签 */
    const customizedTag = customizedTags && customizedTags?.find(tag => tag.goodsId === item?.goods_id) || null
    if (customizedTag) {
      /** 自定义标签优先级高 */
      item.tag = {
        ...customizedTag,
        // 大图跟小图支持使用不同的样式
        class: showLargeImage ? customizedTag.bigContainerClass : customizedTag.class,
      }
    } else if (item?.isEco === '1') {
      /** 环保标签 */
      item.tag = {
        goodsId: item?.goods_id,
        type: 'image',
        funType: 'evo',
        class: showLargeImage ? 'large-eco-tag' : 'eco-tag',
        value: cssRight ? '//img.ltwebstatic.com/images3_ccc/2024/10/10/e8/1728547164df1a42cf52782ac9f22ecdb541a7fa1c.png' : '//img.ltwebstatic.com/images3_ccc/2024/09/26/4e/1727353949f470aa5d372776c6db1d0e61e5281d68.png',
      }
    } else if (item?.isNew === '1' && isShowNewTags) {
      /** new 标签 */
      item.tag = {
        goodsId: item?.goods_id,
        type: 'image',
        funType: 'new',
        class: showLargeImage ? 'large-new-tag' : 'new-tag',
        value: cssRight ? '//img.ltwebstatic.com/images3_ccc/2024/10/11/5b/17286260770c1ce60f13a429ee051a0e7f95785650.png' : '//img.ltwebstatic.com/images3_ccc/2024/10/11/5b/1728626077f562513c709f3fbd10bf3167bd9dfcef.png',
      }
    } else if (item?.lowPrice === '1') {
      /** 低价标签 */
      item.tag = {
        goodsId: item?.goods_id,
        type: 'image',
        funType: 'lowPrice',
        class: showLargeImage ? 'large-low-price-tag' : 'low-price-tag',
        value: cssRight ? '//img.ltwebstatic.com/images3_ccc/2024/10/11/4d/1728617483b9d49c429684f137dab29e356e8694c0.png' : '//img.ltwebstatic.com/images3_ccc/2024/10/11/c7/1728617411040bbdfeb78852fd58a094a2180b7a49.png',
      }
    } else if (item?.hot_color === '1') {
      /** hot 标签 */
      item.tag = {
        goodsId: item?.goods_id,
        type: 'image',
        funType: 'hot',
        class: showLargeImage ? 'large-hot-tag' : 'hot-tag',
        value: cssRight ? '//img.ltwebstatic.com/images3_ccc/2024/10/10/6d/1728547129831115ea4fad4b79f1caec6ca4617c24.png' : '//img.ltwebstatic.com/images3_ccc/2024/09/26/36/1727353722d5ac6151867d1504299e8bd37f299251.png',
      }
    } else if (item?.isShowThickening === '1' && isShowThickeningAbt) {
      item.tag = {
        goodsId: item?.goods_id,
        type: 'image',
        funType: 'thickening',
        class: showLargeImage ? 'large-thickening-tag' : 'thickening-tag',
        value: isUs ? '//img.ltwebstatic.com/images3_ccc/2024/11/19/26/173199720190bdee4d5621bc3a8711dfc8368c03ac.png' : cssRight
        ? '//img.ltwebstatic.com/images3_ccc/2024/11/19/66/1731997137d59cf577d54ffb57744fcc0dc744d8a2.png'
        : '//img.ltwebstatic.com/images3_ccc/2024/11/19/f8/17319971798747b4825a46092207aa4a1352d09351.png',
      }
    }
  })
  return colorList
}
export default useHandleRightTag
