<template>
  <div
    class="crop-image-container"
    :style="{
      'background-color': containerBackground,
      'padding-bottom': `calc(${containerRatio().toFixed(3)} * 100%)`
    }"
    data-before-crop-src="{imgSrc}"
  >
    <img
      :id="imgProps?.id"
      :class="['crop-image-container__img', imgProps?.class]"
      :data-src="originImgSrc()"
      :src="`${imgProps?.src || originImgSrc()}`"
      :data-design-width="imgProps?.dataDesignWidth"
      :data-exp="imgProps?.dataExp"
    />
    <div class="crop-image-container__mask"></div>
  </div>
</template>

<script name="CropImageContainer" setup lang="ts">

interface CropImageContainerProps {
  /**  */
  imgSrc: string,
  /** 容器背景色 */
  containerBackground?: string,
  /** 图片比例 */
  fixedRatio?: string,
  /** image 标签属性 */
  imgProps?: any
}

const props = withDefaults(defineProps<CropImageContainerProps>(), {
  imgSrc: '',
  containerBackground: '#F6F6F6',
  fixedRatio: '',
  imgProps: () => ({}),
})

const isSquareSrc = props.imgSrc.includes('_square')
/** 商品中心原图 */
const originImgSrc = () => {
  return props.imgSrc.replace(/(_square)|(_squfix)/ig, '')
}
/** 容器比例 */
const containerRatio = () => {
  const fixedRatio = props.fixedRatio
  if (fixedRatio === '1-1') return 1 / 1
  if (fixedRatio === '3-4') return 4 / 3
  if (fixedRatio === '4-5') return 5 / 4
  if (fixedRatio === '5-6') return 6 / 5
  if (fixedRatio === '13-16') return 16 / 13
  if (isSquareSrc) return 1 / 1
  return 4 / 3
}

</script>

<style lang="less" scoped>
.crop-image-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;

  &__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: var(--crop-img-fit, contain);
  }

  &__mask {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.03);
  }
}
</style>
