<template>
  <div
    class="quickBrandFlash"
  >
    <div
      class="quickBrandFlash__title">
      <div class="quickBrandFlash__title-left">
        <img
          class="quickBrandFlash__title-leftIcon"
          src="https://img.ltwebstatic.com/images3_ccc/2024/09/12/b6/1726146665fd623d8e1eba0ee613473e61cb1e0e32.png" />
        <p class="quickBrandFlash__title-leftText">{{ flashBeltWraperInfo.title }}</p>
      </div>
      <div class="quickBrandFlash__title-right">
        <template v-if="timer">
          <div
            class="quickBrandFlash__title-rightTime">
            <span>{{ language.SHEIN_KEY_PWA_14950 }}&ensp;</span>
            <span
              v-if="secondInterval && cssRight"
              class="quickBrandFlash__title-rightTimeSecond quickBrandFlash__title-rightTimeSecond_right">{{ secondHundred }}</span>
            <span>{{ countdownTimeObj.H }}h&ensp;:&ensp;{{ countdownTimeObj.M }}m&ensp;:&ensp;{{ countdownTimeObj.S }}s&ensp;</span>
            <span
              v-if="secondInterval && !cssRight"
              class="quickBrandFlash__title-rightTimeSecond">{{ secondHundred }}</span>
          </div>
        </template>
        <span v-else-if="flashBeltWraperInfo.isFlashSaleCountDownAbtHide">
          {{ language.SHEIN_KEY_PWA_28684 }}
        </span>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script setup name="BrandFlash">
import { ref, computed, onMounted, inject } from 'vue'
import { CountDown } from '@shein/common-function'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { getSource } from '../../../../../../common/dataSource'

const { triggerNotice } = inject('analysisInstance')

const props = defineProps({
  flashBeltWraperInfo: {
    type: Object,
    default: () => ({}),
  },
})

const cssRight = computed(() => props.flashBeltWraperInfo.cssRight)
const language = computed(() => props.flashBeltWraperInfo.language)


const timer = ref(null)
const secondInterval = ref(null)
const secondHundred = ref(0)

const countdownTimeObj = computed(() => {
  const { H, M, S } = timer.value?.timeObj || {}
  return { H, M, S }
})
const initTimer = async () => {
  let { endTime } = props.flashBeltWraperInfo
  if (!endTime) return
  const appConfigs = useAppConfigs()
  const apis = getSource({ appConfigs })
  const data = await apis.getServicesTime()
  let serverTime = data.zzz
  const currentTime = Date.now() / 1000 >> 0 // 获取当前时间
  if (!serverTime) {
    serverTime = currentTime
  }
  if (endTime < serverTime) return
  let differDayNum = (endTime - serverTime) / (24 * 60 * 60)
  if (differDayNum > 3) return
  secondInterval.value = setInterval(() => {
    secondHundred.value--
    if (secondHundred.value < 0) secondHundred.value = 9
  }, 100)
  timer.value = new CountDown({
    endFunc: () => {
      location.reload()
    },
  })
  timer.value.start({
    dayMode: true,
    seconds: endTime - serverTime,
  })
}

onMounted(() => {
  initTimer()
  triggerNotice({
    id: 'expose_brand_sale_block.comp_quick-add-cart-standard',
    extraData: {
      location: 'popup',
    },
  })
})

</script>

<style lang="less">
.quickBrandFlash {
  width: 100%;
  position: relative;
  background: linear-gradient(180deg,#ffede3,#fff8f5);
  border-radius: .106666rem;
  border: .5px solid #fff3c2;
  padding: .213333rem;

  &__title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: .4267rem;
    padding-right: .0533rem;
    margin-bottom: .16rem;
  }

  &__title-left {
    display: flex;
    align-items: center;
  }

  &__title-leftIcon {
    width: 15px;
  }

  &__title-leftText {
    font-size: 13px;
    font-weight: 700;
    line-height: 13px;
    text-align: left;
    color: #fa6338;
  }

  &__title-right {
    display: flex;
    align-items: center;
    color: #FFF;
  }

  &__title-rightTime {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: right;
    color: #FA6338;
  }

  &__title-rightTimeSecond {
    display: flex;
    align-items: center;
    justify-content: center;
    width: .4267rem;
    height: .4267rem;
    border-radius: 2px;
    background: #FA6338;
    color: #FFF;
    margin-left: .0267rem;
  }

  &__title-rightTimeSecond_right {
    margin-right: .0533rem;
  }

  &__price {
    position: relative;
    margin-top: 6px;
  }
}
</style>
