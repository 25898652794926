/**
 * 从祖先元素获取信息
 * @param target
 * @returns
 */
export const getAttrFromContainer = (target: HTMLElement) => {
  if (!target) return
  // const box = $(target).closest('.j-da-event-box')[0]
  const box = target.closest('.j-da-event-box')
  const infoObj = {
    container: box,
    parentGoodsId: (box && box.getAttribute('data-parent-goods-id')) || '', // 父级/入口商品id
    goodsType: (box && box.getAttribute('data-goods-type')) || '', // 意图搜图增加一个商品是来自默认推荐还是裁剪之后的推荐
    title: (box && box.getAttribute('data-title')) || '',
    poskey: (box && box.getAttribute('data-poskey')) || '',
    code: (box && box.getAttribute('code')) || '',
    isFault: (box && box.getAttribute('data-is-fault-tolerant')) || 0,
    dataType: (box && box.getAttribute('data-dataType')) || '',
    style: (box && box.getAttribute('data-style')) || 'detail',
    traceId: (box && box.getAttribute('data-traceid')) || '',
    dimension: (box && box.getAttribute('data-dimension')) || '',
    activityFrom: (box && box.getAttribute('data-active-from')) || '',
    isModule: (box && box.getAttribute('data-module')) || false,
    pageNum: (box && box.getAttribute('data-page-num')) || 1,
    cateIndex: (box && box.getAttribute('data-cate-index')) || '',
    cateId: (box && box.getAttribute('data-cate-id')) || '',
    cateName: (box && box.getAttribute('data-cate-name')) || '',
    tabList: (box && box.getAttribute('data-tab-list')) || '',
    tab: (box && box.getAttribute('data-tab')) || '',
    // 普通列表才需要
    tagIds: (box && box.getAttribute('data-tag_ids')) || '',
    attrStr: (box && box.getAttribute('data-attr_str')) || '',
    cat: (box && box.getAttribute('data-cat')) || '',
    minPri: (box && box.getAttribute('data-min-pri')) || 0,
    maxPri: (box && box.getAttribute('data-max-pri')) || 0,
    request_ext: (box && box.getAttribute('data-request_ext')) || '',
    // ccc 推荐位用到
    _gaImpList: (box && box.getAttribute('data-imp-list')) || 0,
    ruleId: (box && box.getAttribute('data-rule-id')) || 0,
    pageId: (box && box.getAttribute('data-page-id')) || 0,
    floorId: (box && box.getAttribute('data-floor-id')) || 0,
    comId: (box && box.getAttribute('data-com-id')) || 0,
    cateNm: (box && box.getAttribute('data-cate-name')) || 0,
    componentType: (box && box.getAttribute('data-component-type')) || 0,
    refresh: (box && box.getAttribute('data-refresh')) || '', // 商品列表是不是刷新之后的列表
    //套装详情页需要
    extraPramas: (box && box.getAttribute('data-extra-pramas')) || '',
    // 付费会员页需要
    location: (box && box.getAttribute('data-location')) || '',
    reviewLocation: (box && box.getAttribute('data-review-location')) || '',
    primeTp: (box && box.getAttribute('data-prime_tp')) || '',

    srcModule: (box && box.getAttribute('data-src-module')) || '',
    srcIdentifier: (box && box.getAttribute('data-src-identifier')) || '',
    srcTabPageId: (box && box.getAttribute('data-src-tab-page-id')) || '',
    rankingFrom: (box && box.getAttribute('data-ranking-from')) || '',
    // recommendpopup组件用到
    similarFrom: (box && box.getAttribute('data-similar_from')) || '',
    // 闪购列表
    type: (box && box.getAttribute('data-type')) || '',
    time: (box && box.getAttribute('data-time')) || '',
    activity_id: (box && box.getAttribute('data-activity-id')) || '',
    // 商详
    isAddMore: (box && box.getAttribute('data-is-add-more')) || '',
    pageView: (box && box.getAttribute('data-page-view')) || '',
    feed_type: (box && box.getAttribute('data-feed_type')) || '',
  }
  return infoObj
}

// eslint-disable-next-line max-lines-per-function
export const getAttrFromTarget = (target: HTMLElement) => {
  let index = '0',
    spu = '',
    sku = '',
    catId = '',
    goodsId = '',
    price = '0',
    originPrice = '0',
    reducePrice = '0',
    operId = '1',
    recMark = '',
    soldoutMark = '0',
    soldOutSign = '0',
    extraMark = '',
    otherExtMark = '',
    otherDExtMark = '',
    extMarks = '',
    promotionInfo = '',
    exclusivePrice = '',
    lable = '',
    cccTspBadges = '',
    seriesOrBrand = '',
    extraPramas = '',
    sellingPointLabel = '',
    brand = '',
    brandCode = '',
    videoIcon = '',
    saleAttr = '',
    spuImg = '',
    mallCode = '',
    storeCode = '',
    priceCut = '',
    promotionId = [] as string[],
    typeId = [] as string[],
    quickShip = '',
    localShip = '',
    bestDeal = '',
    followLabel = '',
    promoLabel = '',
    userBehaviorLabel = '',
    userPreferencesLabel = '',
    userCommentKeywordsLabel = '',
    beltLabel = '',
    rankingList = '',
    decisionAttr = '',
    locateLabels = '',
    discountSpecial = '',
    discountTabs = '',
    historicalPrice = '',
    followPrice = '',
    visitorLabel = '',
    labelCarousels = '',
    findSimilarMode = '',
    estimatedPriceInfo = [] as string[],
    showLabelHot = '',
    labelLooping = '',
    serviceLabel = '',
    titleLabel = '',
    extraTag = '',
    salesLabel = '',
    saleCnt = '',
    showSellerLimitedLabel = '',
    hypernymGoods = '',
    isABPrice = '',
    oneClickPayAddBad = '',
    showNewuseronlyPriceLabel = '',
    trendWordId = '',
    trendProductSelectId = '',
    trendShopCode = '',
    addCartBtnType = '',
    lookType = '',
    lookName = '',
    recommendInfo = '',
    realLeftStock = '',
    maskLayer = '',
    realtimePosition = '',
    realtimeFromCateId = '',
    realtimeFromGoodsId = '',
    trendIsReviewBubble = ''

  if (target) {
    // v3商卡才有的标签 start
    const v3CardlabelLooping = target.getAttribute('data-label-looping')
    const v3CardlServiceLabel = target?.getAttribute('data-service-label')
    const v3CardTitleLabel = target?.getAttribute('data-title-label')

    // 需要额外上报的标签
    const v3ExtraTagProposition = target?.getAttribute('data-extra-tag-proposition')
    // v3商卡才有的标签 end

    showLabelHot = target.getAttribute('data-show-label-hot') || ''
    index = target.getAttribute('data-index') || target.getAttribute('index') || '0'
    spu = target.getAttribute('data-spu') || ''
    sku = target.getAttribute('data-sku') || ''
    catId = target.getAttribute('data-cat_id') || ''
    goodsId = target.getAttribute('data-goods_id') || target.getAttribute('data-id') || ''
    price = target.getAttribute('data-us-price') || '0'
    originPrice = target.getAttribute('data-us-origin-price') || '0'
    reducePrice = target.getAttribute('data-reduce-price') || '0'
    operId = target.getAttribute('data-operId') || '1'
    recMark = target.getAttribute('data-rec_mark') || ''
    extraMark = target.getAttribute('data-extra_mark') || ''
    otherExtMark = target.getAttribute('data-other_ext_mark') || ''
    otherDExtMark = target.getAttribute('data-other_d_ext_mark') || ''
    extMarks = target.getAttribute('data-ext_marks') || ''
    soldoutMark = target.getAttribute('data-soldout') || ''
    soldOutSign = target.getAttribute('data-sold-out') || ''
    promotionInfo = target.getAttribute('data-show-promot-info') || ''
    exclusivePrice = target.getAttribute('data-show-exclusive-price') || ''
    lable = target.getAttribute('data-lable') || ''
    seriesOrBrand = target.getAttribute('data-series-brand') || ''
    extraPramas = target.getAttribute('data-extra-pramas') || ''
    sellingPointLabel = target.getAttribute('data-selling-point') || ''
    cccTspBadges = target.getAttribute('data-badges') || ''
    brand = target.getAttribute('data-brand') || ''
    brandCode = target.getAttribute('data-brand-code') || ''
    videoIcon = target.getAttribute('data-video') || ''
    saleAttr = target.getAttribute('data-sale-attr') || ''
    spuImg = target.getAttribute('data-spu-img') || ''
    mallCode = target.getAttribute('data-mall_tag_code')?.split('_')?.[1] || ''
    storeCode = target.getAttribute('data-store_code') || ''
    priceCut = target.getAttribute('data-price-cut') || ''
    promotionId = target.getAttribute('data-promotion-id')?.split('`') || []
    typeId = target.getAttribute('data-type-id')?.split('`') || []
    quickShip = target.getAttribute('data-quick-ship') || ''
    localShip = target.getAttribute('data-local-ship') || ''
    bestDeal = target.getAttribute('data-best-deal') || ''
    followLabel = target.getAttribute('data-follow-label') || ''
    promoLabel = target.getAttribute('data-promo-label') || ''
    userBehaviorLabel = target.getAttribute('data-user-behavior-label') || ''
    userPreferencesLabel = target.getAttribute('data-user-preferences-label') || ''
    showNewuseronlyPriceLabel = target.getAttribute('data-show-newuseronly-price-label') || ''
    userCommentKeywordsLabel = target.getAttribute('data-user-comment-keywords-label') || ''
    beltLabel = target.getAttribute('data-belt-label') || ''
    rankingList = target.getAttribute('data-ranking-list') || ''
    estimatedPriceInfo = target.getAttribute('data-estimated-price')?.split('`') || []
    decisionAttr = target.getAttribute('data-decision-attr') || ''
    locateLabels = target.getAttribute('data-locate-labels') || ''
    discountSpecial = target.getAttribute('data-discount-special') || ''
    discountTabs = target.getAttribute('data-discount-tabs') || ''
    historicalPrice = target.getAttribute('data-historical-price') || ''
    followPrice = target.getAttribute('data-follow-price') || ''
    visitorLabel = target.getAttribute('data-visitor-label') || ''
    labelCarousels = target.getAttribute('data-label-carousel') || ''
    findSimilarMode = target.getAttribute('data-find-similar-mode') || ''
    labelLooping = v3CardlabelLooping || ''
    serviceLabel = v3CardlServiceLabel || ''
    titleLabel = v3CardTitleLabel || ''
    extraTag = v3ExtraTagProposition || ''
    salesLabel = target.getAttribute('data-sales-label') || ''
    saleCnt = target.getAttribute('data-sale-cnt') || ''
    showSellerLimitedLabel = target.getAttribute('data-show-seller-limited-label') || ''
    hypernymGoods = target.getAttribute('data-hypernym-goods') || ''
    isABPrice = target.getAttribute('data-is-ab-price') || ''
    oneClickPayAddBad = target.getAttribute('data-add-bag-one-click') || ''
    trendWordId = target.getAttribute('data-trend-word-id') || ''
    trendProductSelectId = target.getAttribute('data-trend-product-select-id') || ''
    trendShopCode = target.getAttribute('data-trend-shop-code') || ''
    addCartBtnType = target.getAttribute('data-add-cart-type') || ''
    lookType = target.getAttribute('data-look_type') || ''
    lookName = target.getAttribute('data-look_name') || ''

    // 点推商品用的
    recommendInfo = target.getAttribute('data-recommend-info') || ''
    realtimePosition = target.getAttribute('data-realtime-position') || ''
    realtimeFromCateId = target.getAttribute('data-realtime-from-cate-id') || ''
    realtimeFromGoodsId = target.getAttribute('data-realtime-from-goods-id') || ''
    // 趋势频道评论标签
    const isReviewBubble = target.getAttribute('data-is-review-bubble') || ''
    if (isReviewBubble) trendIsReviewBubble = isReviewBubble

    const isStore = target.getAttribute('data-store-index') || false

    if (isStore) index = target.getAttribute('data-store-index') || index
    realLeftStock = target.getAttribute('data-real-left-stock') || ''
    maskLayer = target.getAttribute('data-mask-layer') || ''
  }

  return {
    index,
    spu,
    sku,
    catId,
    goodsId,
    price,
    originPrice,
    reducePrice,
    operId,
    recMark,
    extraMark,
    otherExtMark,
    otherDExtMark,
    extMarks,
    soldoutMark: Number(soldoutMark),
    soldOutSign: Number(soldOutSign),
    promotionInfo,
    exclusivePrice,
    lable,
    cccTspBadges,
    seriesOrBrand,
    extraPramas,
    sellingPointLabel,
    brand,
    brandCode,
    videoIcon,
    saleAttr,
    spuImg,
    mallCode,
    storeCode,
    priceCut,
    promotionId,
    typeId,
    quickShip,
    localShip,
    bestDeal,
    followLabel,
    promoLabel,
    userBehaviorLabel,
    userPreferencesLabel,
    userCommentKeywordsLabel,
    showNewuseronlyPriceLabel,
    beltLabel,
    rankingList,
    decisionAttr,
    locateLabels,
    discountSpecial,
    discountTabs,
    historicalPrice,
    followPrice,
    visitorLabel,
    labelCarousels,
    findSimilarMode,
    estimatedPriceInfo,
    showLabelHot,
    labelLooping,
    serviceLabel,
    titleLabel,
    extraTag,
    salesLabel,
    saleCnt,
    showSellerLimitedLabel,
    hypernymGoods,
    isABPrice,
    oneClickPayAddBad,
    trendWordId,
    trendProductSelectId,
    trendShopCode,
    addCartBtnType,
    lookType,
    lookName,
    recommendInfo,
    realtimePosition,
    realtimeFromCateId,
    realtimeFromGoodsId,
    realLeftStock,
    maskLayer,
    trendIsReviewBubble,
  }
}

/**
 * 获取sa goodsList
 * wiki.pageId=904754267
 */
export const getSaGoodsList = ({
  goodsId = '',
  sku = '',
  spu = '',
  index = '0',
  pageNum = '1',
  request_ext = '',
  operId = '0',
  recMark = '',
  extraMark = '',
  otherExtMark = '',
  otherDExtMark = '',
  extMarks = '',
  price = '0',
  originPrice = '0',
  reducePrice = '0',
  soldoutMark = 0,
  soldOutSign = 0,
  historicalPrice = '',
  followPrice = '',
  visitorLabel = '',
  labelCarousels = '',
  locateLabels = '',
  discountSpecial = '',
  discountTabs = '',
  cccTspBadges = '',
  promotionInfo = '',
  exclusivePrice = '',
  lable = '',
  seriesOrBrand = '',
  sellingPointLabel = '',
  videoIcon = '',
  saleAttr = '',
  spuImg = '',
  mallCode = '',
  priceCut = '',
  promotionId = [],
  typeId = '',
  brand = '',
  brandCode = '',
  quickShip = '',
  localShip = '',
  bestDeal = '',
  followLabel = '',
  promoLabel = '',
  userBehaviorLabel = '',
  userPreferencesLabel = '',
  userCommentKeywordsLabel = '',
  showNewuseronlyPriceLabel = '',
  beltLabel = '',
  rankingList = '',
  decisionAttr = '',
  findSimilarMode = '',
  estimatedPriceInfo = '',
  showLabelHot = '',
  labelLooping = '',
  serviceLabel = '',
  titleLabel = '',
  extraTag = '',
  salesLabel = '',
  saleCnt = '',
  showSellerLimitedLabel = '',
  hypernymGoods = '',
  isABPrice = '',
  oneClickPayAddBad = '',
  trendWordId = '',
  trendProductSelectId = '',
  trendShopCode = '',
  addCartBtnType = '',
  recommendInfo = '',
  lookType = '',
  lookName = '',
  realLeftStock = '',
  maskLayer = '',
  realtimePosition = '',
  realtimeFromCateId = '',
  realtimeFromGoodsId = '',
  useFrom = '',
  trendIsReviewBubble = '',
} = {}) => {
  const prices = [`pri_${price}`, `pri_${originPrice}`]
  if (estimatedPriceInfo[1]) prices.push(`estimated_pri_${estimatedPriceInfo[1]}`)
  const list = [goodsId, sku, spu, Number(index) + 1, pageNum, operId, recMark, extraMark, prices.join('|')]
  // 其他标识 - S
  const otherMark: string[] = []
  if (reducePrice) otherMark.push(`reduced_${reducePrice}`)
  if (soldoutMark) otherMark.push('stock_soldout')
  if (!soldoutMark && soldOutSign) otherMark.push('show_sold_out') // 列表页的售罄标识另外定义
  if (promotionInfo) otherMark.push('show_promotioninfo')
  if (exclusivePrice) otherMark.push('show_s3vipprice')
  if (lable) otherMark.push(lable)
  if (seriesOrBrand) otherMark.push(seriesOrBrand)
  if (otherExtMark) otherMark.push(otherExtMark)
  if (sellingPointLabel) otherMark.push(sellingPointLabel)
  if (cccTspBadges) otherMark.push(cccTspBadges)
  if (videoIcon) otherMark.push(videoIcon)
  if (spuImg) otherMark.push(spuImg)
  if (priceCut) otherMark.push(`reduced_${priceCut}`)
  if (localShip) {
    otherMark.push('localship_1')
  } else if (quickShip) {
    otherMark.push(quickShip)
  }
  if (bestDeal) otherMark.push('show_label_Betention')
  if (promoLabel) otherMark.push('show_icon')
  if (userBehaviorLabel) otherMark.push(userBehaviorLabel)
  if (userPreferencesLabel) otherMark.push(userPreferencesLabel)
  if (userCommentKeywordsLabel) otherMark.push(userCommentKeywordsLabel)
  if (showNewuseronlyPriceLabel) otherMark.push(showNewuseronlyPriceLabel)
  if (beltLabel) otherMark.push(beltLabel)
  if (followLabel) otherMark.push('show_same_label')
  if (decisionAttr) otherMark.push(decisionAttr)
  if (locateLabels) otherMark.push(locateLabels)
  if (discountSpecial) otherMark.push(discountSpecial)
  if (discountTabs) otherMark.push(discountTabs)
  if (historicalPrice) otherMark.push(historicalPrice)
  if (followPrice) otherMark.push(followPrice)
  if (visitorLabel) otherMark.push(`Recent_Viewers_${visitorLabel}_viewed`)
  if (labelCarousels) otherMark.push(labelCarousels)
  if (rankingList) otherMark.push(rankingList)
  if (estimatedPriceInfo[0]) otherMark.push(`estimated_price_${estimatedPriceInfo[0]}`)
  if (isABPrice) otherMark.push('estimated_price_3')
  if (findSimilarMode) otherMark.push(findSimilarMode)
  if (showLabelHot) otherMark.push(showLabelHot)
  if (labelLooping) otherMark.push(labelLooping)
  if (serviceLabel) otherMark.push(serviceLabel)
  if (titleLabel) otherMark.push(titleLabel)
  if (extraTag) otherMark.push(extraTag)
  if (salesLabel) otherMark.push(salesLabel)
  if (saleCnt) otherMark.push(`sale_cnt_${saleCnt}`)
  if (showSellerLimitedLabel) otherMark.push(showSellerLimitedLabel)
  if (hypernymGoods) otherMark.push('search_query_upper')
  if (oneClickPayAddBad) otherMark.push(oneClickPayAddBad)
  if (trendWordId) otherMark.push(`trend_tag_${trendWordId}`)
  if (trendProductSelectId) otherMark.push(`product_select_id_${trendProductSelectId}`)
  if (trendShopCode) otherMark.push(`trend_shop_code_${trendShopCode}`)
  if (extMarks) otherMark.push(extMarks)
  if (addCartBtnType) otherMark.push(addCartBtnType)
  if (lookType) otherMark.push(lookType)
  if (lookName) otherMark.push(lookName)
  if (realLeftStock) otherMark.push(realLeftStock)
  if (maskLayer) otherMark.push(maskLayer)

  // 趋势频道评论标签
  if (useFrom === 'trend' && +trendIsReviewBubble) otherMark.push('is_review_bubble')

  // 其他标识 - E
  if (recommendInfo) list.push(recommendInfo) // 点推商品用
  if (realtimePosition) list.push(realtimePosition) // 点推商品用
  if (realtimeFromCateId) list.push(realtimeFromCateId) // 点推商品用
  if (realtimeFromGoodsId) list.push(realtimeFromGoodsId) // 点推商品用

  // 销售或促销属性 - S
  const salePromotion: string[] = []
  salePromotion.push(saleAttr || '') // 销售属性
  if (promotionId && typeId) {
    const is_brand = brand ? 1 : 0
    promotionId.forEach((pId, index) => {
      salePromotion.push(['sale', typeId[index], pId, is_brand, brandCode].join('_')) // 促销属性
    })
  }

  // 销售或促销属性 - E
  list.push(otherMark.join('|'), salePromotion.join('|'))

  list.push(`${mallCode ? 'mall_' + mallCode : '-'}`) // mall

  list.push([otherDExtMark, request_ext].filter(_ => _).join('|')) // FR-9925
  return list.join('`')
}

export const getAnalysisAbtInfo = (posKeys?: string) => {
  let abtInfo = {
    sa: '',
    ga: 0,
  }
  let gaAbt = 0 // ga没有实验默认是0

  let saAbt = ''

  if (posKeys) {
    // @ts-ignore
    abtInfo = window?._abt_server_provider?.getUserAbtResultForAnalysis({ posKeys: posKeys })
    // 自有的abt信息拼接
    saAbt = abtInfo.sa || ''
    // ga的abt信息拼接
    gaAbt = abtInfo.ga || 0
  }

  return {
    abtInfo,
    saAbt,
    gaAbt,
  }
}

export const getSaAnalysisParams = (target: HTMLElement) => {
  if (!target) return {}
  const { poskey, srcModule, srcIdentifier } = getAttrFromContainer(target) || {}
  const {
    index,
    spu,
    sku,
    goodsId,
    price,
    originPrice,
    operId,
    recMark,
    extraMark,
    otherExtMark,
    reducePrice,
    soldOutSign,
    soldoutMark,
    promotionInfo,
    exclusivePrice,
    seriesOrBrand,
    sellingPointLabel,
    cccTspBadges,
    mallCode,
    labelCarousels,
  } = getAttrFromTarget(target)
  // ccc推荐组件标示
  const { saAbt = '' } = getAnalysisAbtInfo(poskey)
  const goodsList = getSaGoodsList({
    goodsId,
    sku,
    spu,
    index,
    operId,
    recMark,
    extraMark,
    otherExtMark,
    price,
    originPrice,
    reducePrice,
    soldOutSign,
    soldoutMark,
    promotionInfo,
    exclusivePrice,
    seriesOrBrand,
    sellingPointLabel,
    cccTspBadges,
    mallCode,
    labelCarousels,
  })
  return {
    saAbt,
    goodsList,
    srcModule,
    srcIdentifier,
  }
}
