// @ts-nocheck
/** eslint-disiable */
import { getLocateLabelsMark } from './product-item'
import { getSaAnalysisParams } from '../utils'

export const getAddCollectParam = ({ extraData }) => {
    const { page_name = '', target, result = {}, postData = {}, from = 'other', review_location = '', mall_code = '', src_module: _srcModule = '', src_identifier: _srcIdentifier = '', src_tab_page_id: _srcTabPageId = '', ranking_from = '' } = extraData
    let param: any = {}
    // sa
    param.goods_id = postData.goods_id
    param.is_cancel = postData.action
    param.result = result.data && Number(result.data.code == 0) || 0
    param.activity_from = from === 'cart' ? 'goods_list' : from
    if(['gals_live', 'gals'].indexOf(from) == -1) param.review_location = review_location
    param.mall_code = mall_code
    param.quickship_tp = postData.quickShip
    param.loc = postData.loc
    // 部分场景会通过extraData传入这些属性
    param.src_module = _srcModule || ''
    param.src_identifier = _srcIdentifier || ''
    param.src_tab_page_id = _srcTabPageId || ''
    param.ranking_from = ranking_from || ''
    param = { ...param, ...(extraData.passParams || {}) }

    if (postData.feed_type) {
      param.feed_type = postData.feed_type
    }

    if (postData.isAttrFold) {
      param.is_attr_fold = postData.isAttrFold
    }

    if (target) {
      // 商品项目角标
      const locateLabels = getLocateLabelsMark(postData.locateLabels)
      const { saAbt = '', goodsList = '', tabList, trendTag } = getSaAnalysisParams(target, {
        mallCode: mall_code,
        ...(locateLabels ? { locateLabels } : {}),
        ...(postData.quickShip ? { quickShip: 'quickship_' + 1 } : {}),
      })
      if(!['page_collection', 'page_collection_boards' ].includes(page_name) && !['click_saved'].includes(sa?.param?.activity_name)) {
        param.abtest = saAbt
      }
      param.goods_list = goodsList

      param.tab_list = tabList

      param.trend_tag = trendTag
    }


    return param
}
