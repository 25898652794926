import type { DS_SubSalesAttr } from '../../../types'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

// TODO write your apis
export const api1: DS_SubSalesAttr.APIS_THIS['api1'] = async function (x, y, z) {
  const result = await this.$schttp<Array<number>>({
    url: '/api/*',
    params: { x, y, z },
  })
  return result.data
}

export const getSizeFeedbackCountry: DS_SubSalesAttr.APIS_THIS['getSizeFeedbackCountry'] = async function(params) {
  const result = await this.$schttp({
    url: '/product/get_feedback_size_info',
    method: 'get',
    params,
  })
  return result.data
}

export const updateBusineseCache: DS_SubSalesAttr.APIS_THIS['updateBusineseCache'] = async function(data) {
  const result = await this.$schttp({
    url: `${window.location.origin}${useAppConfigs().$envs.langPath}/api/common/busineseCache/update`,
    method: 'post',
    data,
  })
  return result.data
}
