<template>
  <div class="bs-main-sales-attr">
    <!-- 头部 -->
    <BMainSalesAttrHeader
      :data="headerData.data"
      :config="headerData.config"
      @onChange="onHandleLargeImageChange"
    />
    <!-- 色块 -->
    <component
      :is="colorComponents[colorMode]"
      :activeGoodsId="currentGoodsId"
      :data="colorListData"
      :config="colorListConfig"
      @onChange="onHandleColorChange"
    >
      <!-- 右上角角标 -->
      <template #UPPER_RIGHT_TAG="{tagInfo}">
        <BMainSalesAttrRightTag
          :tagInfo="tagInfo || {}"
        />
      </template>
    </component>
    <!-- 主销售属性描述 -->
    <BMainSalesAttrDesc
      :descData="mainSalesAttrDesc.descData"
      :language="mainSalesAttrDesc.language"
      :config="mainSalesAttrDesc.config"
    />
    <!-- FindMyShade -->
    <BMainSalesAttrShade
      v-if="dataSource?.mainSaleAttrInit?.isShowFindMyShadeEntry"
      :text="dataSource?.language?.SHEIN_KEY_PWA_18952"
      :data="dataSource?.mainSaleAttrInit?.findMyShadeParams"
    />
  </div>
</template>

<script name="BMainSalesAttr" setup lang="ts">
import { onMounted, ref, computed, defineEmits, provide, defineAsyncComponent, nextTick } from 'vue'
import { ColorMode, type DS_MainSalesAttr, type C_MainSalesAttr, type AS_MainSalesAttr } from '../../types'
import { useAnalysis } from '../../common/analysisSource'
import useHandleRightTag from '../hook/useHandleRightTag'
import BMainSalesAttrHeader from '../components/main-sales-attr-header.vue'
import BMainSalesAttrRightTag from '../components/main-sales-attr-right-tag.vue'

const BMainSalesAttrShade = defineAsyncComponent(() => import('../components/main-sales-attr-shade.vue'))
const BMainSalesAttrDesc = defineAsyncComponent(() => import('../components/main-sales-attr-desc.vue'))

import BSColorCircleImage from '../components/ColorMode/color-circle-image.vue'
import BSColorDefaultText from '../components/ColorMode/color-default-text.vue'
import BSColorSquareImage from '../components/ColorMode/color-square-image.vue'
import BSColorBigSquareImage from '../components/ColorMode/color-big-square-image.vue'

const colorComponents = {
  BSColorCircleImage,
	BSColorDefaultText,
	BSColorSquareImage,
	BSColorBigSquareImage,
}

const props = withDefaults(defineProps<{
  goodsId: string,
  /** 主销售属性详情数据 */
  dataSource?: DS_MainSalesAttr.MainSalesAttrData,
  /** 主销售属性配置 */
  config: C_MainSalesAttr.BMainSalesAttrConfig,
  /** 埋点数据 */
  analysisSource?: AS_MainSalesAttr.AnalysisSource,
  /** 源状态 */
  topState: {
    /** 首图比例 */
    fixedRatio: string,
  }
}>(), {})

const emits = defineEmits<{
  (event: 'onMainAttrChange', params: object): DS_MainSalesAttr.MainSaleAttributeInfo
  (event: 'onLargeImageChange', params: boolean): void
}>()

/** 埋点源 */
const analysisInstance = useAnalysis(props?.analysisSource)
const { triggerNotice } = analysisInstance
provide('analysisInstance', analysisInstance)

onMounted(async () => {
  const { getCustomizedTag } = props.config?.salesAttrConfig || {}
  const colorList = props.dataSource?.colorList || []

  /** 处理自定义标签 */
  if (getCustomizedTag) {
    customizedTags.value = await getCustomizedTag(colorList, showLargeImage.value)
  }
  nextTick(() => {
    /** 主销售属性曝光 */
    const { location, analysisConfig } = props.config || {}
    // 标签类型
    const color_list = colorListData.value?.map((item: DS_MainSalesAttr.MainSaleAttributeInfo) => {
      return {
        color: item.goods_id,
        icon_type: item?.tag?.funType || '',
      }
    })
    triggerNotice({
      id: 'expose_goods_detail_select_mainattr.comp_main-sales-attr',
      data: {
        location,
        color_list,
        if_have_mainattr_icon: 0,
        if_have_switch_image_entry: props.config.needLargeImageMode ? 1 : 0,
        image_tp: showLargeImage.value ? 'large' : 'small',
        is_attr_fold: analysisConfig.value?.isAttrFold || '0',
      },
    })
  })
})

/** 标题数据 */
const headerData = computed(() => {
  const { colorBlockText } = props.dataSource?.mainSaleAttrInit || {}
  const language = props.dataSource?.language || {}

  return {
    data: {
      colorBlockText,
      language,
    },
    config: {
      needLargeImageMode: props.config.needLargeImageMode,
      showLargeImage: showLargeImage.value,
    },
  }
})

/** 当前色块类型 */
let defaultColorMode = props.config.firstShowLargeImage
                    ? ColorMode.BSColorBigSquareImage
                    : props.config.mainSaleAttrShowMode
/** 色块样式 */
const colorMode = ref<any>(defaultColorMode)
/** 是否展示大图模式 */
const showLargeImage = ref(defaultColorMode === ColorMode.BSColorBigSquareImage)

/** 切换大/小图 */
const onHandleLargeImageChange = (val) => {
  showLargeImage.value = val
  emits('onLargeImageChange', val)
  if (val) {
    colorMode.value = ColorMode.BSColorBigSquareImage
  } else {
    colorMode.value = props.config.mainSaleAttrShowMode
  }
}

const customizedTags = ref<any>(false)
/** 色块列表数据 */
const colorListData = computed(() => {
  const colorList = props.dataSource?.colorList || []
  const { getCustomizedTag } = props.config?.salesAttrConfig || {}
  const { isShowThickeningAbt, isShowNewTags } = props.config || {}
  /** 混入自定义标签 */
  const newColorList = useHandleRightTag({
    defaultColorList: colorList as any,
    customizedTags: customizedTags.value,
    hasCustomizedTag: !!getCustomizedTag,
    showLargeImage: showLargeImage.value,
    isShowThickeningAbt: isShowThickeningAbt,
    isShowNewTags,
  }) || []
  return [...newColorList]
})

/** 色块列表配置 */
const colorListConfig = computed(() => {
  return {
    lazyImg: '//sheinm.ltwebstatic.com/pwa_dist/images/bg-logo-5ab25d7379.png',
    fixedRatio: props?.topState.fixedRatio,
  }
})

/** 主销售属性描述 */
const mainSalesAttrDesc = computed(() => {
  const { mainSaleAttrDescText = '', mainAttrPicInfo = {} } = props.dataSource?.mainSaleAttrInit || {}
  return {
    descData: {
      attrDesc: mainSaleAttrDescText,
      attrValue: mainAttrPicInfo?.attr_value,
      attrImage: mainAttrPicInfo?.attr_image,
    },
    language: {
      moreText: props.dataSource?.language?.SHEIN_KEY_PWA_18777 || '',
      lessText: props.dataSource?.language?.SHEIN_KEY_PWA_18779 || '',
      detailText: mainAttrPicInfo?.textDetail,
    },
    config: {
      goods_id: props.goodsId,
      location: props.config.location,
    },
  }
})

/** 切换skc */
const currentGoodsId = ref(props.dataSource?.currentAttr?.goods_id)
const onHandleColorChange = (color, index) => {
  emits('onMainAttrChange', color)
  currentGoodsId.value = color?.goods_id
  // 色块点击埋点
  const { location, analysisConfig } = props.config || {}
  triggerNotice({
    id: 'click_goods_detail_select_mainattr.comp_main-sales-attr',
    data: {
      promotion_type: '-',
      source: analysisConfig.value?.source,
      condition: color?.isSoldOutStatus || '0',
      color: color?.goods_id,
      location,
      image_tp: showLargeImage.value ? 'large' : 'small',
      review_location: analysisConfig.value?.reviewLocation || '',
      is_front: '0', // TODO: skc前置
      position: `${index + 1}`,
      is_attr_fold: analysisConfig.value?.isAttrFold || '0',
      color_list: [{
        color: color.goods_id,
        icon_type: color.tag?.funType || '',
      }],
    },
  })
}

</script>

<style lang="less" scoped>
@vw: 375/100vw;

.hot-tag {
  top: -6 / @vw;
  right: -12 / @vw;

  /deep/ img {
    width: 25 / @vw;
    height: 10 / @vw;
  }
}

.large-hot-tag {
  top: -2 / @vw;

  /deep/ img {
    width: 25 / @vw;
    height: 10 / @vw;
  }
}

.eco-tag {
  top: -6 / @vw;
  right: -4 / @vw;

  /deep/ img {
    width: 17 / @vw;
    height: 10 / @vw;
  }
}

.large-eco-tag {
  top: -2 / @vw;

  /deep/ img {
    width: 17 / @vw;
    height: 10 / @vw;
  }
}

.new-tag {
  top: -6 / @vw;
  right: -12 / @vw;

  /deep/ img {
    width: 27 / @vw;
    height: 10 / @vw;
  }
}

.large-new-tag {
  top: -2 / @vw;

  /deep/ img {
    width: 27 / @vw;
    height: 10 / @vw;
  }
}

.thickening-tag, .large-thickening-tag {
  /deep/ img {
    width: 21 / @vw;
    height: 10 / @vw;
  }

  &.thickening-tag {
    top: -6 / @vw;
    right: -8 / @vw;
  }

  &.large-thickening-tag {
    top: -2 / @vw;
  }
}

.low-price-tag {
  top: -4 / @vw;
  right: -6 / @vw;

  /deep/ img {
    width: 18 / @vw;
    height: 10 / @vw;
  }
}

.large-low-price-tag {
  top: -2 / @vw;

  /deep/ img {
    width: 18 / @vw;
    height: 10 / @vw;
  }
}

</style>
