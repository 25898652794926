import { computed } from 'vue'
import {
  salePriceWordSize,
  ESTIMATED_STYLE_TYPES,
  DISCOUNT_PERCENT_STYLE_MAP,
} from './utils'
import { useModules } from '../modules/useModules'
import { useEstimatedInfo, useCommonInfo } from './common'


// http://localhost:5174/quick-add-cart-standard?lang=en&mir=ltr

const getPriceMainPriceInfo = (arg) => { // 主价格
  const {
    price,
    isPaidUser,
    sheinClubPromotionInfo,
    isHitComplianceMode,
    complianceModeDe,
    isNormalFlash,
    isBrandFlash,
    fsAbt,
    estimatedInfo,
    isShowPrice,
    suggestedSalePriceInfo,
    exclusiveInfo,
    lowestPriceTips,
  } = arg || {}
  const isSheinappwordsizeNew = fsAbt?.sheinappwordsize?.p?.sheinappwordsize === 'newprice'

  const COLOR_MAP = {
    normalBlack: '#222',
    discountOrange: '#fa6338',
    vipBrown: '#873c00', // isPaidUser + priceInfo.showTypeId
    brandFlashWhite: '#FFF',
    s3Brown: '#806208',
  }
  // 大小价

  // showPrice.amountWithSymbol 存在 就展示 showPrice

  const { NEW, NEW_AND_NO_THRESHOLD_FLOOR } = ESTIMATED_STYLE_TYPES
  const getMainPriceValue = () => {
    if (estimatedInfo?.value && [NEW, NEW_AND_NO_THRESHOLD_FLOOR].includes(estimatedInfo?.styleType)) return estimatedInfo.value.amountWithSymbol
    if (isShowPrice) {
      return price?.showPrice?.amountWithSymbol
    }
    return price?.salePrice?.amountWithSymbol
  }
  const value = getMainPriceValue()

  const getMainPriceColor = () => {
    const { normalBlack, discountOrange, vipBrown, s3Brown } = COLOR_MAP

    if (!!exclusiveInfo && isShowPrice) return s3Brown
    if (isPaidUser && !!sheinClubPromotionInfo) return vipBrown
    if (estimatedInfo && [NEW, NEW_AND_NO_THRESHOLD_FLOOR].includes(estimatedInfo?.styleType)) return discountOrange

    if (isHitComplianceMode) {
      if (complianceModeDe && suggestedSalePriceInfo?.de?.show) {
        const { discountPercent, retailPriceDiscountPercent } = suggestedSalePriceInfo?.de || {}
        if (discountPercent || retailPriceDiscountPercent) return discountOrange
      } else {
        if (suggestedSalePriceInfo.isInversion) return normalBlack
        if (
          suggestedSalePriceInfo.show &&
          (!!suggestedSalePriceInfo?.pl?.show || !!suggestedSalePriceInfo?.fr?.show)
        ) return discountOrange
      }
      return normalBlack
    }

    if (lowestPriceTips?.mainPriceDiscountStyle) return discountOrange

    if (!price?.retailPrice?.amountWithSymbol) return normalBlack
    if (value !== price?.retailPrice?.amountWithSymbol) return discountOrange
    return normalBlack
  }
  const color = getMainPriceColor()
  const getValueHtml = () => {
    if (estimatedInfo?.value && estimatedInfo?.styleType == ESTIMATED_STYLE_TYPES.OLD && fsAbt?.middleast?.p?.middleast == 'new') {
      return `<span style="font-size: 12px;">${value}</span>`
    }
    if (!isSheinappwordsizeNew && [NEW, NEW_AND_NO_THRESHOLD_FLOOR].includes(estimatedInfo?.styleType)) {
      return `<span style="font-size: 20px;">${value}</span>`
    }
    if (isSheinappwordsizeNew) {
      return salePriceWordSize({ priceStr: value, gate: true })
    }
    return `<span style="font-size: 18px;">${value}</span>`
  }
  const valueHtml = getValueHtml()

  const result = {
    value,
    valueHtml,
    color,
    isSheinappwordsizeNew,
  }

  return result
}

const getPriceDiscountInfo = (arg) => {
  const {
    price,
    isFlash,
    isBrandFlash,
    isSpecialFlash,
    isHitComplianceMode,
    estimatedInfo,
    isShowPrice,
    lang,
    cssRight
  } = arg
  const { retailPrice, salePrice, showPrice, unitDiscount, discountValue } = price || {}
  const mainPrice = isShowPrice ? showPrice : salePrice

  const show = (() => {
    if (isHitComplianceMode) return false
    if (!retailPrice || retailPrice?.amount == mainPrice?.amount) return false
    if (estimatedInfo) {
      if (estimatedInfo.styleType === ESTIMATED_STYLE_TYPES.NO_THRESHOLD_FLOOR) return true
      if (estimatedInfo.styleType === ESTIMATED_STYLE_TYPES.OLD) return true
      return false
    }
    return true
  })()

  const discountPercent = (() => {
    if (estimatedInfo && estimatedInfo.styleType === ESTIMATED_STYLE_TYPES.OLD) return ''
    let result = unitDiscount > 0 ? unitDiscount : ''
    if (isShowPrice) result = discountValue
    if (result) {
      const reductionSymbol = lang != 'il' ? '-' : ''
      if (cssRight) {
        result = `%${result}${reductionSymbol}`
      } else {
        result = `${reductionSymbol}${result}%`
      }
    }
    return result
  })()

  const { normal, normalFlash, specialFlash, brandFlash } = DISCOUNT_PERCENT_STYLE_MAP
  let discountPercentStyle: any = normal
  const retailPriceStyle: any = {}
  if (isFlash) {
    if (isSpecialFlash) {
      discountPercentStyle = normal
    } else if (isBrandFlash) {
      discountPercentStyle = normal
    } else {
      discountPercentStyle = normal
    }
    retailPriceStyle.fontSize = '12px'
  }

  const result = {
    show,
    retailPrice: retailPrice?.amountWithSymbol,
    retailPriceStyle,
    discountPercent,
    discountPercentStyle,
  }
  return result
}

const getPriceSuggestedSalePriceInfo = (arg) => {
  const {
    price,
    language,
    featureConfig,
    estimatedInfo,
    isShowPrice,
    isInversion,
    lang,
  } = arg
  const { suggestedSalePriceInfo, unitDiscount, discountValue } = price || {}
  const { suggestedUnitDiscount, suggestedVipUnitDiscount } = suggestedSalePriceInfo || {}
  const value = suggestedSalePriceInfo?.suggestedSalePrice?.amountWithSymbol
  const { complianceMode, complianceTipsMode, complianceModeDe } = featureConfig?.needComplianceModel || {}
  const isHitComplianceMode = complianceMode || complianceTipsMode || complianceModeDe

  let discount = unitDiscount
  let showPriceDiscount = discountValue
  if (complianceModeDe) {
    discount = suggestedUnitDiscount
    showPriceDiscount = suggestedVipUnitDiscount
  }
  const finalDiscount = isShowPrice ? showPriceDiscount : discount
  let discountPercent = finalDiscount > 0 ? finalDiscount : ''

  if (discountPercent) {
    discountPercent = discountPercent + '%'
    if (lang != 'il') discountPercent = '-' + discountPercent
  }

  if (estimatedInfo && (complianceMode || complianceTipsMode)) discountPercent = '' // fr pl 有到手价不展示折扣百分比

  let retailPrice = ''
  let retailPriceDiscountPercent = ''
  if (complianceModeDe) {
    retailPrice = price?.retailPrice?.amountWithSymbol,
      retailPriceDiscountPercent = isShowPrice ? discountValue : unitDiscount
    if (Number(retailPriceDiscountPercent) == 0 || !retailPriceDiscountPercent) retailPriceDiscountPercent = ''
  }
  if (retailPriceDiscountPercent) {
    retailPriceDiscountPercent = retailPriceDiscountPercent + '%'
    if (lang != 'il') retailPriceDiscountPercent = '-' + retailPriceDiscountPercent
  }

  return {
    show: isHitComplianceMode && value,
    isInversion: isInversion == 1,
    fr: {
      show: complianceMode && !complianceTipsMode,
      value,
      discountPercent,
    },
    pl: {
      show: complianceTipsMode,
      value,
      discountPercent,
      tip: language?.SHEIN_KEY_PWA_30762,
    },
    de: {
      show: complianceModeDe,
      value,
      valueTip: language?.SHEIN_KEY_PWA_31307,
      discountPercent,

      retailPriceShow: !!retailPrice,
      retailPrice,
      retailTip: language?.SHEIN_KEY_PWA_31306,
      retailPriceDiscountPercent,
    },
  }
}

const getPriceEstimatedNewEndTime = (estimatedInfo) => {
  const { NEW, NEW_AND_NO_THRESHOLD_FLOOR } = ESTIMATED_STYLE_TYPES || {}
  if (![NEW, NEW_AND_NO_THRESHOLD_FLOOR].includes(estimatedInfo?.styleType)) return null
  return estimatedInfo?.endTime
}

const getPriceFlashBeltWraperInfo = (arg) => {
  const {
    isFlash,
    isNormalFlash,
    isSpecialFlash,
    isBrandFlash,
    fsAbt,
    flashType,
    language,
    goodsId,
    flashProm,
    langPath,
    cssRight,
  } = arg
  let endTime = null
  const isFlashSaleCountDownAbtHide = fsAbt?.FlashSaleCountDown?.p?.FlashSaleCountDown === 'Hide'
  if (isNormalFlash || isBrandFlash) {
    if (!isFlashSaleCountDownAbtHide) endTime = flashProm?.endTimestamp
  }
  const titleFlashTypeMap = {
    '1': language?.SHEIN_KEY_PWA_14949,
    '2': language?.SHEIN_KEY_PWA_21931,
    '3': language?.SHEIN_KEY_PWA_21932,
    '6': language?.SHEIN_KEY_PWA_29102,
  }
  let title = titleFlashTypeMap[flashType]
  if (!title) title = flashProm?.flashSaleTip || language?.SHEIN_KEY_PWA_26575 || language?.SHEIN_KEY_PWA_14949

  const brandFlashLinkClickAble = (fsAbt?.brandskip?.p?.brandskip || 'Cannotjump') === 'Jumpable'

  return {
    flashType,
    isFlash,
    isNormalFlash,
    isSpecialFlash,
    isBrandFlash,

    title,
    endTime,
    language,

    brandFlashLinkClickAble,
    isFlashSaleCountDownAbtHide,

    langPath,
    goodsId,
    flashProm,
    cssRight,

  }
}

const getPriceRecommendRetailPrice = (arg: any) => {
  const { fsAbt, complianceTipsMode, priceInfo, price, isFlash, isBrandFlash, skuInfo, is_show_price, suggested_sale_price_info, estimated_info } = arg || {}

  const { detailrrpprice } = fsAbt || {}

  if (detailrrpprice?.p?.detail_rrp_price != 'show') return null
  if (!complianceTipsMode) return null

  const { showPrice } = priceInfo
  const { salePrice, suggestedSalePriceInfo } = price || {}
  const { recommendRetailPrice, recommendRetailPriceLanguage, recommendRetailPriceDiscount } = suggestedSalePriceInfo || {}
  if (!recommendRetailPrice) return null
  const currentPriceAmount = is_show_price ? showPrice.amount : salePrice.amount

  if (parseFloat(recommendRetailPrice?.amount) <= parseFloat(currentPriceAmount)) return null
  if (parseFloat(recommendRetailPrice?.amount) <= parseFloat(suggestedSalePriceInfo?.suggestedSalePrice?.amount)) return null

  const value = `${recommendRetailPriceLanguage} ${recommendRetailPrice.amountWithSymbol}`

  const discountPercentTemp = `-${recommendRetailPriceDiscount}%`
  const discountPercent = suggested_sale_price_info.show ?
    discountPercentTemp :
    ''

  let color = '#959595'
  let discountPercentBorderColor = '#959595'
  let width = ''
  if (isFlash) {
    color = '#666'
    discountPercentBorderColor = '#666'
    if (isBrandFlash) {
      color = '#FFF'
      discountPercentBorderColor = '#FFF'
    }
  }
  if (estimated_info) width = '8rem'

  return {
    value,
    usdAmountWithSymbol: recommendRetailPrice.usdAmountWithSymbol,
    discountPercent,
    style: {
      color,
      discountPercentBorderColor,
      width,
    },
    skuCode: skuInfo?.sku_code,
  }
}


export default (arg: any) => {

  const {
    language,
    priceInfo,
    gbCommonInfo,
    fsAbt,
  } = useModules()

  const {
    featureConfig,
    skuInfo,
    isSoldOut,
    currentStock,
    lowestPriceTips,
  } = arg

  const {
    goodsId,
    flashPromotion,
    flashType,
    isFlash,
    isNormalFlash,
    isSpecialFlash,
    isBrandFlash,
    isPaidUser,
    sheinClubPromotionInfo,
    exclusiveInfo,
    isShowPrice,
    price,
    complianceTipsMode,
    complianceModeDe,
    isHitComplianceMode,
    isInversion,
    cssRight,
  } = useCommonInfo({
    featureConfig,
    isSoldOut,
    currentStock,
    skuInfo,
  })
  const { skcEstimatedInfo, estimatedInfo } = useEstimatedInfo({ isSoldOut, currentStock, featureConfig, skuInfo })
  const suggestedSalePriceInfo = computed(() => getPriceSuggestedSalePriceInfo({
    price: price.value,
    language: language?.value,
    estimatedInfo: estimatedInfo.value,
    isShowPrice: isShowPrice.value,
    isInversion: isInversion.value,
    featureConfig: featureConfig.value,
    lang: gbCommonInfo?.value?.lang,
  }))
  const mainPriceInfo = computed(() => getPriceMainPriceInfo({
    priceInfo: priceInfo?.value,
    price: price.value,
    isPaidUser: isPaidUser.value,
    sheinClubPromotionInfo: sheinClubPromotionInfo.value,
    isHitComplianceMode: isHitComplianceMode.value,
    complianceModeDe: complianceModeDe.value,
    isNormalFlash: isNormalFlash.value,
    isBrandFlash: isBrandFlash.value,
    fsAbt: fsAbt?.value,
    estimatedInfo: estimatedInfo.value,
    isShowPrice: isShowPrice.value,
    suggestedSalePriceInfo: suggestedSalePriceInfo.value,
    exclusiveInfo: exclusiveInfo.value,
    lowestPriceTips: lowestPriceTips?.value,
  }))
  const discountInfo = computed(() => getPriceDiscountInfo({
    price: price.value,
    isFlash: isFlash.value,
    isBrandFlash: isBrandFlash.value,
    isSpecialFlash: isSpecialFlash.value,
    isHitComplianceMode: isHitComplianceMode.value,
    estimatedInfo: estimatedInfo.value,
    isShowPrice: isShowPrice.value,
    lang: gbCommonInfo?.value?.lang,
    cssRight: cssRight.value,
  }))
  const estimatedNewEndTime = computed(() => getPriceEstimatedNewEndTime(estimatedInfo.value))
  const flashBeltWraperInfo = computed(() => getPriceFlashBeltWraperInfo({
    isFlash: isFlash.value,
    isNormalFlash: isNormalFlash.value,
    isSpecialFlash: isSpecialFlash.value,
    isBrandFlash: isBrandFlash.value,
    fsAbt: fsAbt?.value,
    flashType: flashType.value,
    language: language?.value,
    goodsId: goodsId.value,
    flashProm: flashPromotion.value,
    langPath: gbCommonInfo?.value?.langPath,
    cssRight: cssRight.value,
  }))

  const recommendRetailPrice = computed(() => getPriceRecommendRetailPrice({
    fsAbt: fsAbt?.value,
    complianceTipsMode: complianceTipsMode.value,
    priceInfo: priceInfo?.value,
    price: price.value,
    isFlash: isFlash.value,
    isBrandFlash: isBrandFlash.value,
    skuInfo: skuInfo.value,
    is_show_price: isShowPrice.value,
    suggested_sale_price_info: suggestedSalePriceInfo.value,
    estimated_info: estimatedInfo.value,
  }))

  const clickEstimatedTag = () => {}

  return {
    priceCommonInfos: {},
    isShowPrice,
    isInversion,
    mainPriceInfo,
    discountInfo,
    suggestedSalePriceInfo,
    estimatedInfo,
    estimatedNewEndTime,
    exclusiveInfo,
    flashBeltWraperInfo,
    clickEstimatedTag,
    recommendRetailPrice,
  }
}
