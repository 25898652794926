<script lang="jsx">
import { Icon } from '@shein-aidc/icon-vue2'
/**
 * @props
 *  @param { Number } label 当前 label
 *  @param { Number } index 当前 label 索引
 *  @param { Number } attrId 当前主属性 ID
 *  @param { String } attrName 当前主属性名称
 */
export default {
  name: 'SizeSelectLabel',
  functional: true,
  props: {
    isLast: {
      type: Boolean,
      default: false,
    },
    label: {
      type: Object,
      default: () => ({}),
    },
    attrId: {
      type: String,
      default: '',
    },
    attrName: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
    sizeInfoIndex: {
      type: Object,
      default: () => ({}),
    },
    isOnSale: {
      type: Boolean,
      default: true,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    sizeConstant: {
      type: Object,
      default: () => ({}),
    },
    handleCalcSize: {
      type: Function,
      default: () => {},
    },
    handleChooseCheck: {
      type: Function,
      default: () => {},
    },
    handleSizeClick: {
      type: Function,
      default: () => {},
    },
  },
  render(h, { props }) {
    const {
      isLast,
      label,
      // index,
      attrId,
      attrName,
      sizeInfoIndex,
      isOnSale,
      sizeConstant,
      handleSizeClick,
      handleCalcSize,
      handleChooseCheck,
    } = props || {}
    const { cssRight = false } = sizeConstant || {}
    const {
      attr_std_value,
      attr_value_name,
      attr_value_id,
    } = label || {}

    const chooseCheckStatus = handleChooseCheck({
      attr_id: attrId,
      attr_name: attrName,
      attr_value_id,
    })
    // 用户是否选中
    const isActiveOption = sizeInfoIndex[attrId]?.attr_value_id == attr_value_id
    // 是否售罄
    const isSoldOut = !isOnSale || chooseCheckStatus === 0
    // 是否展示有货选中状态
    const isActive = isActiveOption && isOnSale && chooseCheckStatus > 0

    // 是否展示售罄选中状态
    const isSizeSoldOut = isActiveOption && isSoldOut

    // 无数据,禁用
    const disable = chooseCheckStatus === undefined


    const ariaItem = attr_std_value
      ? cssRight
        ? attr_value_name + ' / ' + attr_std_value
        : attr_std_value
      : attr_value_name

    const { lowStockTip, mainSize, subSize, isWeakSubDefaultSize } = handleCalcSize(label, {
      isCalcLowTip: !isSoldOut && isLast,
      attrParentInfo: {
        attrId,
        attrName,
      },
    }) || {}

    const renderSubSize = () => {
      if (!subSize || !mainSize) return null
      return isWeakSubDefaultSize ? <span style="color: #959595; font-size: 12px;">{` ${subSize}`}</span> : ` ${subSize}`
    }

    const renderLowTip = () => {
      if (!lowStockTip) return null
      return <div class="low-stock-tips">
        { lowStockTip === 'icon' ?
          <Icon
            name="sui_icon_hourglass_12px_2"
            size="12px"
            class="low-stock-tips-hourglass"
            color="#C44A01"
          /> :
          lowStockTip
        }
      </div>
    }

    return (
      <li
        class={[
          'goods-size__sizes-item',
          { 'size-active': isActive },
          { 'size-sold-out': isSizeSoldOut },
          { 'sold-out': isSoldOut },
          { 'size-disabled': disable },
          'twoline-text-box',
        ]}
        data-attr_value={attr_value_name}
        data-attr_value_id={attr_value_id}
        type="text"
        aria-item={ariaItem}
        v-on:click={() => {
          handleSizeClick?.({ attr_id: attrId, attr_name: attrName }, label, true)
        }}
      >
        <p class={[
          'goods-size__sizes-item-text',
          'goods-size__sizes-item-text--two',
        ]}
        >
          { mainSize }
          { renderSubSize() }
          { renderLowTip() }
        </p>
      </li>
    )
  },
}
</script>
