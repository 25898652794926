<script lang="tsx" name="DefaultAddBtn">
export default {
    functional: true,
    props: {
        customSubmitBtnStyle: {
            type: Object,
            default: () => ({})
        },
        submitText: {
            type: String,
            required: false,
        },
        underPriceStyle: {
            type: Boolean,
            required: false
        },
        isFlashSaleBtn: {
            type: Boolean,
            required: false
        },
        mainBtnFullWidth: {
            type: Boolean,
            required: false
        },
        visibleFavoriteButton: {
            type: Boolean,
            required: false
        },
        onlyOneClickPayBtn: {
            type: Boolean,
            required: false
        },
        showOneClickPayBtn: {
            type: Boolean,
            required: false
        }
    },
    render (h, { props, listeners }) {
        const { mainBtnFullWidth, customSubmitBtnStyle, underPriceStyle, submitText, showOneClickPayBtn, visibleFavoriteButton, onlyOneClickPayBtn, isFlashSaleBtn } = props;
        return (
            <div class={{
                'add-button-box': true,
                'half-width-btn': showOneClickPayBtn && !onlyOneClickPayBtn,
                'with-save-btn': visibleFavoriteButton,
            }}>
                <button 
                    class={{
                    'mshe-btn-black': showOneClickPayBtn !== true,
                    'mshe-btn-sec': showOneClickPayBtn,
                    'fullWidth': mainBtnFullWidth,
                    'c-quick-add__flashsale-btn': isFlashSaleBtn,
                    'c-quick-add__under-price': underPriceStyle,
                    }} 
                    role="button" 
                    aria-label={submitText}
                    style={customSubmitBtnStyle}
                    onClick={(e) => { 
                        e.preventDefault()
                        if (listeners.onClick) {
                            (listeners.onClick as Function)()
                        }

                    }}>
                    { submitText }
                </button>
        </div>
        )
    }
};
</script>
