import type { AS_MainSalesAttr } from '../../../types'

const standard: AS_MainSalesAttr.AnalysisFuncs = {
  /** 色块点击埋点 */
  'click_goods_detail_select_mainattr.comp_main-sales-attr': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  /** 色块曝光埋点 */
  'expose_goods_detail_select_mainattr.comp_main-sales-attr': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  /** findMyShade 点击埋点 */
  'click_find_my_shade.comp_main-sales-attr': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  /** findMyShade 曝光埋点 */
  'expose_find_my_shade.comp_main-sales-attr': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  /** 色块描述曝光埋点 */
  'expose_mainattr_description.comp_main-sales-attr': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  /** 色块描述折叠状态切换点击埋点 */
  'click_mainattr_description.comp_main-sales-attr': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  /** 色块描述属性数据曝光埋点 */
  'expose_main_attpic.comp_main-sales-attr': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  /** 色块描述属性数据点击埋点 */
  'click_main_attpic.comp_main-sales-attr': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  /** 切换大图选色模式icon点击 */
  'click_mainattr_switch_image.comp_main-sales-attr': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
}

export default standard
