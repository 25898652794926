<template>
  <div
    v-if="descData?.attrImage || descData?.attrDesc"
    class="bs-main-sales-attr__desc"
  >
    <!-- 属性描述 -->
    <template v-if="descData?.attrDesc">
      <div class="bs-main-sales-attr__desc-wrapper">
        <p
          ref="contentRef"
          v-expose="{
            id: 'expose_mainattr_description.comp_main-sales-attr',
            data: { fold_tp: isFold && showMore ? 'unfold': '0' }
          }"
          :class="{
            'bs-main-sales-attr__desc-context': true,
            'clamp3': isFold && showMore
          }"
          v-html="descData?.attrDesc"
        ></p>
        <!-- 展开按钮 -->
        <div
          v-if="isFold && showMore"
          v-tap="{
            id: 'click_mainattr_description.comp_main-sales-attr',
            data: { fold_tp: 'unfold' }
          }"
          class="bs-main-sales-attr__more-btn"
          @click="onHandleChangeShowMore"
        >
          ...<span> {{ language?.moreText }}</span>
        </div>
      </div>
      <!-- 折叠按钮 -->
      <div
        v-if="isFold && !showMore"
        v-tap="{
          id: 'click_mainattr_description.comp_main-sales-attr',
          data: { fold_tp: 'fold' }
        }"
        class="bs-main-sales-attr__less-btn"
        @click="onHandleChangeShowMore"
      >
        {{ language?.lessText }}
      </div>
    </template>
    <!-- 色块数据 -->
    <div
      v-if="descData?.attrImage"
      v-tap="{
        id: 'click_main_attpic.comp_main-sales-attr',
        data: { location: config?.location, goods_id: config.goods_id }
      }"
      v-expose="{
        id: 'expose_main_attpic.comp_main-sales-attr',
        data: { main_attr: '1', location: config?.location, goods_id: config.goods_id }
      }"
      class="bs-main-sales-attr__attr-wrapper"
      @click="onHandleChangeShowBigImage"
    >
      <p>
        <span>{{ language?.detailText }}</span>
        <span>{{ descData?.attrValue }}</span>
      </p>
      <img :src="descData?.attrImage" />
    </div>
    <!-- 大图弹窗 -->
    <ImageBox
      append-to-body
      :visible="showBigImage">
      <div
        class="bs-main-sales-attr__image-box"
        @click="onHandleChangeShowBigImage"
      >
        <div class="bs-main-sales-attr__close-btn">
          <Icon
            name="sui_icon_close_18px"
            size="24px"
            color="#000"
          />
        </div>
        <img :src="descData?.attrImage" />
        <p class="bs-main-sales-attr__bottom-text">
          {{ descData?.attrValue }}
        </p>
      </div>
    </ImageBox>
  </div>
</template>

<script name="BMainSalesAttrDesc" setup lang="ts">
import { ref, inject, watch, nextTick } from 'vue'
//@ts-ignore
import { ImageBox } from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'
import type { AS_MainSalesAttr } from '../../types'

const analysisInstance = inject<AS_MainSalesAttr.AnalysisInstance>('analysisInstance')
const { vTap, vExpose } = analysisInstance || {}

interface MainSalesAttrDescProps {
  /** 描述数据类 */
  descData: any,
  /** 描述多语言 */
  language: any,
  /** 配置数据 */
  config: any
}

const props = withDefaults(defineProps<MainSalesAttrDescProps>(), {})

const contentRef = ref<any>()

/** 切换折叠状态 */
const isFold = ref(false)
const showMore = ref(false)
const onHandleChangeShowMore = () => {
  showMore.value = !showMore.value
}

watch(() => props.descData, () => {
  nextTick(() => {
    const domHeight = contentRef.value?.offsetHeight || 0
    if (domHeight > 48) {
      isFold.value = true
      showMore.value = true
    }
  })
}, { immediate: true })

/** 切换大图展示状态 */
const showBigImage = ref(false)
const onHandleChangeShowBigImage = () => {
  showBigImage.value = !showBigImage.value
}

</script>

<style lang="less" scoped>
@vw: 375/100vw;

.clamp3 {
  max-height: 48px;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.bs-main-sales-attr {
  &__desc {
    padding: 8 / @vw 12 / @vw;
    margin-bottom: 12 / @vw;
    background: #fafafa;
  }

  &__desc-wrapper {
    position: relative;
    font-size: 12px;
    line-height: 16px;
  }

  &__desc-context {
    color: #767676;
    overflow-wrap: break-word;
  }

  &__more-btn {
    display: inline-block;
    padding-left: .426667rem;
    position: absolute;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, rgba(250, 250, 250, 0) 0%, rgb(250, 250, 250) 30%, rgb(250, 250, 250) 100%);
    color: #222;

    span {
      color: #2d68a8;
    }
  }

  &__less-btn {
    font-size: 12px;
    line-height: 16px;
    color: #2d68a8;
  }

  &__attr-wrapper {
    padding: 4 / @vw 0;

    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: left;
    }

    span {
      &:first-child {
        color: #666;
      }
    }

    img {
      max-width: 100%;
      max-height: 72 / @vw;
      margin-top: 8 / @vw;
    }
  }

  &__image-box {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__close-btn {
    position: absolute;
    top: 6 / @vw;
    left: 8 / @vw;
    padding: 4 / @vw;
    border-radius: 32 / @vw;
    background: rgba(255, 255, 255, 0.6);
  }

  &__bottom-text {
    position: absolute;
    bottom: 98 / @vw;
    max-width: 327 / @vw;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
