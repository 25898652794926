import { template } from '@shein/common-function'
import { computed } from 'vue'
import { useModules } from '../modules/useModules'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

const appConfigs = useAppConfigs()

const getPriceLowestPriceTips = (arg) => {
  const {
    goodsId,
    goodsSn,
    productInfo,
    priceInfo,
    skuInfo,
    mallCode,
    complianceModeDe,
    isSoldOut,
    fsAbt,
    beltInfo,
    showBestDealLabel,
  } = arg

  // 保留款 + 最低价 + 税
  const analyzeData = {
    location: 'popup',
    goods_list: `${goodsId}\`${goodsSn}\`${productInfo?.productRelationID}\`1\`1\`1\`\`\`pri_${priceInfo?.salePrice?.usdAmount}|pri_${priceInfo?.retailPrice?.usdAmount}\`${skuInfo?.value?.isSupportQuickShip == 1 ? 'quickship_1' : ''}\`\`mall_${mallCode}\``,
    pricestatus: 1,
  }

  /* 税信息 */
  const { vatInclusive } = priceInfo?.vatInfo || {}
  const { Taxshowtype } = fsAbt || {}

  let taxIncludedText = ''
  if (Taxshowtype?.p?.Taxshowtype && Taxshowtype?.p?.Taxshowtype !== 'A' && !complianceModeDe) {
    taxIncludedText = vatInclusive
    analyzeData.pricestatus = 1
  }
  /* 税信息 */


  /* 保留款优先 */
  const bestDealText = beltInfo?.sameLabelBelt?.lowestPriceTips || ''
  if (!!bestDealText && !isSoldOut && showBestDealLabel) {
    analyzeData.pricestatus = 2
    return {
      text: bestDealText,
      mainPriceDiscountStyle: true,
      analyzeData,
      taxIncludedText,
    }
  }
  /* 保留款优先 */


  /* 最低价 */
  const lowestPriceTips = priceInfo?.lowestPrice?.tips || ''

  if (!lowestPriceTips && !taxIncludedText) return null

  return {
    text: lowestPriceTips,
    mainPriceDiscountStyle: !!lowestPriceTips,
    analyzeData,
    taxIncludedText,
  }
}

const getAppExclusiveDiscount = (arg) => {
  const {
    detailPromotionFloor,
    language,
    gbCommonInfo,
  } = arg
  const skcPromotionInfo = detailPromotionFloor?.detailPromotionInfo || []
  const promoInfo = skcPromotionInfo?.find?.(item => item.typeId == 8) || null
  if (!promoInfo) return null
  return {
    data: promoInfo,
    language,
    REPORT_URL: gbCommonInfo?.REPORT_URL,
  }
}

const getSheinClubPromotionFloorInfo = (arg) => {
  const {
    userInfo,
    priceInfo,
    detailPromotionFloor,
    skuInfo,
    goodsId,
    fsAbt,
    gbCommonInfo,
    isLogin,
  } = arg
  const { isPaidMember, isCanTrial: _isCanTrial, memberStatus } = userInfo || {}
  const isPaidUser = isPaidMember == 1
  const isCanTrial = _isCanTrial == 1

  const skcPromotionInfo = detailPromotionFloor?.detailPromotionInfo || []
  const skuPromotionInfo = skuInfo?.detailPromotionInfo || []
  const promotionInfo = skuInfo?.sku_code ? skuPromotionInfo : skcPromotionInfo
  const sheinClubPromotionInfo = promotionInfo.find?.(item => item.typeId == 29) || null
  const skcSheinClubPromotionInfo = skcPromotionInfo.find?.(item => item.typeId == 29) || null
  const unPaidUserShowSheinClub = fsAbt?.sheinclubprice?.p == 'exposure_prime_price'

  const otherAggregateMemberResult = (() => {
      const otherAggregateMemberResult = skuInfo?.sku_code ? skuInfo.otherAggregateMemberResult : detailPromotionFloor.otherAggregateMemberResult
      if (otherAggregateMemberResult && Object.keys(otherAggregateMemberResult || {}).length) {
        return otherAggregateMemberResult
      }
      return null
  })()

  let isOtherAggregateMemberResult = false
  const aggregateMemberResult = (() => {
    const sheinClubPromotionRes = (() => {
      if (!sheinClubPromotionInfo) return null
      if (!isPaidUser && !unPaidUserShowSheinClub) return null
      return sheinClubPromotionInfo?.aggregateMemberResult || null
    })()
    if (sheinClubPromotionRes) return sheinClubPromotionRes
    if (otherAggregateMemberResult) {
      isOtherAggregateMemberResult = true
      return otherAggregateMemberResult
    }
    return null
  })()

  if (!aggregateMemberResult) return null
  if (isOtherAggregateMemberResult && !aggregateMemberResult.promoShipPaidMemberShowTipsObject) return null

  const {
    paidMemberOneHeadUrl,
    paidMemberTwoHeadUrl,
    paidMemberHeadUrl,
    paidMemberShowTipsObject,
    freeTrialPriceTipsObject,
    promoShipPaidMemberShowTipsObject,
  } = aggregateMemberResult || {}
  const SHEINclubshowtype = fsAbt?.SHEINclubshowtype?.p?.SHEINclubshowtype || 'Old'
  const isNewSheinClubShowType = ['New1', 'New2'].includes(SHEINclubshowtype)

  const imgSrc = {
    'Old': paidMemberHeadUrl,
    'New1': paidMemberOneHeadUrl,
    'New2': paidMemberTwoHeadUrl,
  }[SHEINclubshowtype]

  const imgStyle = {
    'Old': { width: '2.08rem' },
    'New1': { width: '2.2133rem' },
    'New2': { width: '2.08rem' },
  }[SHEINclubshowtype]

  const titleImg = { imgSrc, imgStyle }

  const showGradientImg = !isNewSheinClubShowType

  let containerStyle = {
    'padding': '0 .1067rem',
    'background': 'linear-gradient(0deg, rgba(255, 226, 211, 0.5), rgba(255, 226, 211, 0.5)), #FFFFFF',
    'border-radius': '.0267rem',
    'height': '.5867rem',
  }
  if (SHEINclubshowtype == 'New1') {
    containerStyle = {
      'padding': '0 .21333rem 0 .08rem',
      'background': 'linear-gradient(0deg,rgba(255,226,211,.7),rgba(255,226,211,.7)),#fff',
      'border-radius': '.05333rem',
      'height': '.8rem',
    }
  }
  if (SHEINclubshowtype == 'New2') {
    containerStyle = {
      'padding': '.13333rem .21333rem',
      'background': 'linear-gradient(0deg,rgba(255,226,211,.9),rgba(255,226,211,.9)),#fff',
      'border-radius': '.05333rem',
      'height': '.8rem',
    }
  }

  const tempObj = (() => {
    if (isOtherAggregateMemberResult) return promoShipPaidMemberShowTipsObject
    if (!isPaidUser && isCanTrial && fsAbt?.clubtype?.p?.clubtype == 'new' && ![1, 2].includes(Number(priceInfo?.estimatedPriceInfo?.paidNumberPriceType))) return freeTrialPriceTipsObject
    return paidMemberShowTipsObject
  })()

  const { paidNumberKey, value, labelType } = tempObj || {}
  let text = template(`<span style="color: #C44A01;font-weight: 700;">${value || ''}</span>`, paidNumberKey)
  if (!isNewSheinClubShowType) {
    text = template(value, paidNumberKey)
  }
  const textStyle = {
    'margin-left': isNewSheinClubShowType ? '.2133rem' : '.0533rem',
    'width': '6.4rem',
  }
  if (text.includes('</&') && text.includes('&/>')) {
    text = text.replaceAll('</&', '<span style="color: #C44A01;font-weight: 700;">')
    text = text.replaceAll('&/>', '</span>')
  }
  let prime_trial_type
  if (!isPaidUser && isCanTrial && isLogin) fsAbt?.clubtype?.p?.clubtype == 'new' ? prime_trial_type = '1' : prime_trial_type = '0'
  const { primeLevel, totalSaving } = skcSheinClubPromotionInfo?.aggregateMemberResult || {}

  const analysisData = {
    prime_level: primeLevel || '0',
    total_saving: totalSaving || '0.00',
    location: 'popup', // 加车注意 popup
    from: 1,
    goods_id: goodsId,
    ...(prime_trial_type ? { prime_trial_type } : {}),
    labeltype: labelType || '',
  }

  const langPath = gbCommonInfo?.langPath
  const jumpUrl = (() => {
    if (memberStatus == 1) return `${langPath}/user/prime?productsource=quickview`
    if (isPaidUser) return `${langPath}${sheinClubPromotionInfo.scInfo?.scUrl}?productsource=quickview`
    if (isCanTrial && fsAbt?.clubtype?.p?.clubtype == 'new') return `${langPath}/user/primefreetrial?productsource=quickview`
    return `${langPath}/user/prime?productsource=quickview`
  })()

  return {
    titleImg,
    text,
    textStyle,
    containerStyle,
    showGradientImg,

    // 埋点
    analysisData,

    // 跳转用
    jumpUrl,
    cssRight: gbCommonInfo.cssRight,

  }
}


export default (arg: any) => {
  const {
    language,
    priceInfo,
    beltInfo,
    fsAbt,
    productInfo,
    detailPromotionFloor,
    userInfo,
    gbCommonInfo,
  } = useModules()

  const {
    featureConfig,
    mallCode,
    skuInfo,
    isSoldOut,
    currentStock,
  } = arg

  const goodsId = computed(() => productInfo?.value?.goods_id)
  const goodsSn = computed(() => productInfo?.value?.goods_sn)

  const lowestPriceTips = computed(() => getPriceLowestPriceTips({
    goodsId: goodsId?.value,
    goodsSn: goodsSn?.value,
    priceInfo: priceInfo?.value,
    complianceModeDe: featureConfig?.value?.needComplianceModel?.complianceModeDe?.value,
    isSoldOut: isSoldOut?.value || currentStock?.value == 0,
    fsAbt: fsAbt?.value,
    beltInfo: beltInfo?.value,
    productInfo: productInfo?.value,
    skuInfo: skuInfo?.value,
    mallCode: mallCode.value,
    showBestDealLabel: featureConfig?.value?.showBestDealLabel,
  }))

  const appExclusiveDiscount = computed(() => getAppExclusiveDiscount({
    detailPromotionFloor: detailPromotionFloor?.value,
    language: language?.value,
    gbCommonInfo: gbCommonInfo?.value,
  }))

  const sheinClubPromotionFloorInfo = computed(() => getSheinClubPromotionFloorInfo({
    userInfo: userInfo?.value,
    priceInfo: priceInfo?.value,
    detailPromotionFloor: detailPromotionFloor?.value,
    fsAbt: fsAbt?.value,
    skuInfo: skuInfo?.value,
    goodsId: goodsId?.value,
    gbCommonInfo: gbCommonInfo?.value,
    isLogin: appConfigs.$getters?.isLogin,
  }))

  return {
    lowestPriceTips,
    appExclusiveDiscount,
    sheinClubPromotionFloorInfo,
  }
}
