import { DS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile-vue2'

export const validOneSkc = (data: DS_QuickAddCartStandard.GoodsDetailStaticData) => {
  // 主销售属性只有一个
  return data.saleAttr?.mainSaleAttribute?.info?.length === 1
}

export const validOneSku = (data: DS_QuickAddCartStandard.GoodsDetailStaticData) => {
  // 次级销售属性只有一个，且sku_list只有一个
  return data.saleAttr?.multiLevelSaleAttribute?.skc_sale_attr?.length === 1 && data.saleAttr?.multiLevelSaleAttribute?.sku_list?.length === 1
}

/**
 * 判断是否是定制化商品
 */
export const validCustomization = (data: DS_QuickAddCartStandard.GoodsDetailStaticData) => {
  return data.productInfo?.customization_attributes?.customization_flag === 1
}


/**
 * 一键加车插件（直接加车）
 * @param addCartRule 加车规则，返回true表示可以直接加车，返回false表示不可以直接加车
 * @param directlyAddCartCallback 直接加车回调
 */
export const DirectlyAddCartPlugin = (
  addCartRule?: (data: DS_QuickAddCartStandard.GoodsDetailStaticData) => boolean,
  directlyAddCartCallback?: (res: DS_QuickAddCartStandard.BffApiResponse<DS_QuickAddCartStandard.GoodsAddCartResponse>) => void,
): DS_QuickAddCartStandard.QuickAddCartPlugin => {
  return {
    name: 'DirectlyAddCart',
    beforeOpen: async context => {
      // 加车规则不存在或者不是函数，直接返回false，继续后续流程
      if (!addCartRule || typeof addCartRule !== 'function') return false

      const { params, apis, onMonitoringTrigger, loadCartSuccess } = context
      try {
        onMonitoringTrigger?.({ type: 'directly-add-start' })
        params.isUserSelectedMallCode = 1
        const staticData = await apis.getGoodDetailStaticData(params)
        if (staticData?.info && addCartRule(staticData.info)) {
          const addCartParams: DS_QuickAddCartStandard.PostAddToCartParams = {
            goods_id: params.goods_id,
            quantity: '1',
            freeShippingAbtParams: 'type=B',
            mall_code: staticData.info.mallInfo?.selectedMallCode,
          }
          // todo 加车后面用统一的方法，做回调处理
          onMonitoringTrigger?.({ type: 'add-to-cart-request-start' })
          const data = await apis.postAddToCart(addCartParams)
          const isSuccess = data.code === '0'
          onMonitoringTrigger?.({ type: 'add-to-cart-request-end', status: isSuccess ? 'success' : 'fail' })
          onMonitoringTrigger?.({ type: 'directly-add-end' })
          if (isSuccess) {
            // 加车成功弹窗
            loadCartSuccess?.()
          }
          directlyAddCartCallback?.(data)
          return isSuccess
        } else {
          onMonitoringTrigger?.({ type: 'directly-add-end' })
          return false
        }
      } catch (error) {
        onMonitoringTrigger?.({ type: 'directly-add-error', status: 'fail', msg: error })
        console.log('DirectlyAddCart Plugin fail:', error)
        return false
      }
    },
  }
}
