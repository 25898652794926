<template>
  <div class="bs-color-big-square-image__wrapper">
    <ul
      v-for="(colorList, indexList) in colorListBox"
      :key="indexList"
      class="bs-color-big-square-image__list-wrapper"
    >
      <li
        v-for="(color, index) in colorList"
        :key="color.goods_id"
        :class="{
          'bs-color-big-square-image__item': true,
          'bs-color-big-square-image__item-overflow': data?.length > 6,
          'active': activeGoodsId === color.goods_id,
        }"
        @click="useOnColorChange(emits, color, index)"
      >
        <CropImageContainer
          :fixedRatio="config?.fixedRatio"
          :imgSrc="transformImg({img: color.goods_image || ''})"
          :imgProps="imgProps"
        />
        <span>{{ color?.attr_value || '' }}</span>
        <!-- 售罄蒙层 -->
        <div
          v-if="color?.isSoldOutStatus === '1'"
          class="bs-color-big-square-image__sold-out">
        </div>
        <!-- 右上角角标 -->
        <slot
          v-if="!!color?.tag"
          name="UPPER_RIGHT_TAG"
          :tagInfo="color.tag"
        >
        </slot>
      </li>
    </ul>
  </div>
</template>

<script name="BSColorBigSquareImage" setup lang="ts">
import { computed, defineEmits } from 'vue'
import { transformImg } from '@shein/common-function'
import type { DS_MainSalesAttr } from '../../../types/index'
import CropImageContainer from '../crop-Image-container.vue'
import useOnColorChange from '../../hook/useOnColorChange'

interface ColorModeConfig {
  /** 缺省图 */
  lazyImg: string,
  /** 图片尺寸 */
  fixedRatio: string,
}

interface ColorModeProps {
  /** 色块数据 */
  data: DS_MainSalesAttr.MainSaleAttributeInfo []
  /** 色块配置 */
  config?: ColorModeConfig,
  /** 当前选中的商品ID */
  activeGoodsId: string,
}

const props = withDefaults(defineProps<ColorModeProps>(), {
  data: () => ([]),
  activeGoodsId: '',
})

const emits = defineEmits<{
  (event: 'onChange', params: object): void
}>()

const imgProps = {
  src: props.config?.lazyImg,
  dataDesignWidth: 0,
  class: 'lazyload',
}

/** 色块分组 */
const colorListBox = computed(() => {
  const colorList: Array<DS_MainSalesAttr.MainSaleAttributeInfo> = props.data
  const colorLen = colorList?.length || 0
  if(!colorLen) return []

  // 小于等于3展示一行
  if (colorLen < 4) return [props.data]
  // 大于3，小于7展示两行不能滑动
  if (colorLen > 3 && colorLen < 7) {
    return [colorList.slice(0, 3), colorList.slice(3, colorLen)]
  }
  // 大于等于7展示两行，上下排序
  const firstLine: Array<DS_MainSalesAttr.MainSaleAttributeInfo> = []
  const secondLine: Array<DS_MainSalesAttr.MainSaleAttributeInfo> = []
  for (let index = 0; index < colorLen; index++) {
    const item: DS_MainSalesAttr.MainSaleAttributeInfo = colorList[index]
    if (index % 2 === 0) {
      firstLine.push(item)
    } else {
      secondLine.push(item)
    }
  }
  return [firstLine, secondLine]
})

</script>

<style lang="less" scoped>
@vw: 375/100vw;

.bs-color-big-square-image {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 4 /@vw;
    margin-bottom: 8 /@vw;
    overflow-x: scroll;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__list-wrapper {
    display: flex;
    margin-top: 8 /@vw;
  }

  &__item {
    position: relative;
    width: 114 / @vw;
    margin-left: 3 /@vw;
    padding: 4 / @vw;
    border: 1px solid transparent;
    text-align: center;

    &:first-child {
      margin-left: 0;
    }

    span {
      display: inline-block;
      width: 100%;
      padding: 2 / @vw;
      font-size: 12px;
      color: #767676;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.active {
      border: 1px solid #222;

      span {
        color: #222;
      }
    }
  }

  &__item-overflow {
    width: 120 / @vw;
    margin-left: 8 /@vw;
  }

  &__sold-out {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.6);
  }
}
</style>
