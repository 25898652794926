<script lang="jsx">
import { Icon } from '@shein-aidc/icon-vue2'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
export default {
  name: 'SizeGroupLabel',
  functional: true,
  props: {
    sizeGroupItem: {
      type: Object,
      default: () => ({}),
    },
    goodsId: {
      type: String,
      default: '',
    },
    from: {
      type: String,
      default: '',
    },
    handlerOpenAdd: {
      type: Function,
      default: () => {},
    },
  },
  render(h, { props }) {
    const { sizeGroupItem } = props
    const cssRight = useAppConfigs().$envs?.cssRight
    const getSizegroupAnalysis = ({ type = 'click' }) => {
      let goods_size_list = '' // 拼接规则 goods_id`size_group`theme_type
      const { theme_type = '', displayDesc, goodsId } = sizeGroupItem
      goods_size_list = `${goodsId}\`${displayDesc}\`${theme_type || ''}`
      const data = {
        location: props.from === 'popup' ? 'popup' : 'page',
        source_goods_id: props.goodsId,
        size_group_style: 'size_attr',
        goods_size_list,
      }
      return {
        id: type === 'click' ? 'click_size_group.comp_sub-sales-attr' : 'expose_size_group.comp_sub-sales-attr',
        prefix: 'comp_sub-sales-attr',
        data,
      }
    }
    const textContent = () =>
      (
        <span
          v-expose={getSizegroupAnalysis({ type: 'expose' })}
          v-tap={getSizegroupAnalysis({ type: 'click' })}
          class="goods-size__sizes-item-text"
        >
          { sizeGroupItem.displayDesc }
        </span>
      )
    return (
      <li
        class="goods-size__sizes-item size-group__sizes-item"
        role="text"
        vOn:click={() => props.handlerOpenAdd({ goods_id: sizeGroupItem.goodsId, ...(sizeGroupItem || {}) }, {
          hideSizeGroupeSize: true,
          activityFrom: 'sizegroup',
        })}
      >
        {textContent()}
        <Icon
            style={{
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            name="sui_icon_more_right_12px_2"
            size="12px"
            is-rotate={cssRight}
          />
      </li>
    )
  },
}
</script>

<style lang="less" scoped>
.size-group {
  &__sizes-item{
    display: flex;
    max-width: 46%;
    .goods-size__sizes-item-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
