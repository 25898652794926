<template>
  <div class="quickAddFlashBeltWraper">
    <component :is="curComp" :flash-belt-wraper-info="flashBeltWraperInfo">
      <slot></slot>
    </component>
  </div>
</template>

<script setup name="FlashBeltWraper">
/**
 * @file
 * 闪购 包Price组件
*/
import { defineAsyncComponent, computed } from 'vue'

const NormalFlash = defineAsyncComponent(() => import('./components/NormalFlash.vue'))
const SpecialFlash = defineAsyncComponent(() => import('./components/SpecialFlash.vue'))
const BrandFlash = defineAsyncComponent(() => import('./components/BrandFlash.vue'))

const props = defineProps({
  flashBeltWraperInfo: {
    type: Object,
    default: () => ({})
  }
})

const curComp = computed(() => {
  let { isFlash, isNormalFlash, isSpecialFlash, isBrandFlash } = props.flashBeltWraperInfo || {}
  if (!isFlash) return null
  if (isBrandFlash) return BrandFlash
  if (isNormalFlash) return NormalFlash
  if (isSpecialFlash) return SpecialFlash
  return null
})

</script>

<style lang="less">
.quickAddFlashBeltWraper {
  width: 100%;
  display: flex;
  position: relative;
}
</style>
