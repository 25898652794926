<template>
  <div
    class="quickAddNormalFlash"
  >
    <div class="quickAddNormalFlash__title">
      <div
        class="quickAddNormalFlash__title_hotspot"
      ></div>
      <div>
        <Icon
          name="sui_icon_flashsale_12px"
          size="15px" />
        <span class="quickAddNormalFlash__title-text">{{ flashBeltWraperInfo.title }}</span>
      </div>
      <div class="quickAddNormalFlash__title-right">
        <template v-if="timer">
          <span style="margin-right: 4px;">{{ language.SHEIN_KEY_PWA_14950 }}&ensp;</span>
          <span
            v-if="secondInterval && cssRight"
            class="quickAddNormalFlash__title-rightTimeObvious">{{ secondHundred }}</span>
          <span v-html="countdownStr"></span>
          <span
            v-if="secondInterval && !cssRight"
            class="quickAddNormalFlash__title-rightTimeObvious">{{ secondHundred }}</span>
        </template>
      </div>
    </div>
    <slot></slot>
  </div>
</template>
<script setup name="NormalFlash">
import { Icon } from '@shein-aidc/icon-vue2'
import { ref, computed, onMounted} from 'vue'
import { CountDown } from '@shein/common-function'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { getSource } from '../../../../../../common/dataSource'

const props = defineProps({
  flashBeltWraperInfo: {
    type: Object,
    default: () => ({}),
  },
})

const cssRight = computed(() => props.flashBeltWraperInfo.cssRight)
const language = computed(() => props.flashBeltWraperInfo.language)


const timer = ref(null)
const secondInterval = ref(null)
const secondHundred = ref(0)

const countdownStr = computed(() => {
  const { D, H, M, S } = timer.value?.timeObj || {}
  const day = Number(D) > 0 ? `<em>${D}</em>D` : ''
  const hmsStr = `<em>${H}h</em>&ensp;:&ensp;<em>${M}m</em>&ensp;:&ensp;<em>${S}s</em>`
  return day + hmsStr
})
const initTimer = async () => {
  if (props.flashBeltWraperInfo.isFlashSaleCountDownAbtHide) return
  let { endTime, flashType } = props.flashBeltWraperInfo
  if (!endTime) return

  const appConfigs = useAppConfigs()
  const apis = getSource({ appConfigs })
  const data = await apis.getServicesTime()

  let serverTime = data?.zzz
  const currentTime = Date.now() / 1000 >> 0 // 获取当前时间
  if (!serverTime) {
    serverTime = currentTime
  }
  if (endTime <= serverTime) return
  let differDayNum = (endTime - serverTime) / (24 * 60 * 60)
  if (flashType == 4) {
    if (differDayNum > 3) return
  } else {
    if (differDayNum > 1) return
  }
  secondInterval.value = setInterval(() => {
    secondHundred.value--
    if (secondHundred.value < 0) secondHundred.value = 9
  }, 100)
  timer.value = new CountDown({
    endFunc: () => {
      location.reload()
    },
  })
  timer.value.start({
    dayMode: true,
    seconds: endTime - serverTime,
  })
}

onMounted(() => {
  initTimer()
})

</script>

<style lang="less">
.quickAddNormalFlash {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: linear-gradient(180deg, #fff5c7, #fff8e0 48.27%, #fffcee);
  position: relative;
  border-radius: .106666rem;
  border: .5px solid #fff3c2;
  padding: .213333rem;

  &__title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: .4rem;
    margin-bottom: .16rem;
  }

  &__title_hotspot {
    position: absolute;
    width: 100%;
    height: 20px;
  }

  &__title-text {
    font-size: 13px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0px;
  }

  &__title-right {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: right;
    color: #222;
  }

  &__title-rightTimeObvious {
    display: inline-block;
    text-align: center;
    padding: 1px 3px;
    height: 15px;
    border-radius: 2px;
    background: rgba(250,207,25,.6);
    color: #222;
    font-weight: 700;
    margin: 0 4px;
    min-width: 14px;
  }

  &__embellish-img {
    position: absolute;
    right: .3733rem;
    top: 0;
    width: 2.4533rem;
    height: 100%;
    overflow: hidden;

    >img {
      width: 100%;
      transform: translateY(-0.16rem)
    }
  }
}
</style>
