import { Toast as $toast, ToastOptions } from '@shein/sui-mobile'

interface QueuedToast extends ToastOptions {
  onClose?: () => void;
}

class ToastManager {
  private queue: QueuedToast[] = []
  private isShowing: boolean = false

  public show(toastOptions: ToastOptions | string): void {
    let options = toastOptions
    if (typeof toastOptions === 'string') {
        options = {
          htmlString: `<div class="bsc-quick-add-cart-standard__toast">${toastOptions}</div>`,
        }
    }
    const queuedToast: QueuedToast = {
      ...options,
      onClose: () => {
        if (typeof toastOptions.onClose === 'function') {
          toastOptions.onClose()
        }
        this.onToastClosed()
      },
    }

    this.queue.push(queuedToast)
    this.processQueue()
  }

  private processQueue(): void {
    if (!this.isShowing && this.queue.length > 0) {
      const nextToast = this.queue.shift()
      if (nextToast) {
        this.isShowing = true
        $toast(nextToast)
      }
    }
  }

  private onToastClosed(): void {
    this.isShowing = false
    this.processQueue()
  }
}

const toastManager = new ToastManager()

export const showToast = (toastOptions: ToastOptions | string): void => {
  toastManager.show(toastOptions)
}
