<template>
  <div
    v-if="showContent"
    class="goodsd-sizes__quickship"
  >
    <span
      v-if="!deliveryFloorQuickshipTitle"
      :class="['title', { grey: status != 1 }]"
    >
      {{ language.SHEIN_KEY_PWA_22276 }}
    </span>
    <span
      v-if="showTextDom"
      v-expose="{
        id: 'expose_quickship.comp_sub-sales-attr',
        prefix: 'comp_sub-sales-attr',
        data: exposeData,
      }"
      v-html="renderTextDom"
    >
    </span>
  </div>
</template>

<script setup>
import { defineProps, computed, watch } from 'vue'
import { template } from '@shein/common-function'
import analysisInstance from '../utils/useAnalysisSdk.js'
const { vExpose } = analysisInstance || {}

const props = defineProps({
  showQuickShip: {
    type: Boolean,
    default: false,
  },
  hiddenPartialQuickShip: {
    type: Boolean,
    default: false,
  },
  from: {
    type: String,
    default: '',
  },
  deliveryFloorQuickshipTitle: {
    type: String,
    default: '',
  },
  skcSaleAttr: {
    type: Array,
    default: () => [],
  },
  skuList: {
    type: Array,
    default: () => [],
  },
  skuInfo: {
    type: Object,
    default: () => ({}),
  },
  language: {
    type: Object,
    default: () => ({}),
  },
  handleCalcSize: {
    type: Function,
    default: () => () => {},
  },
  handleUpdateQuickShip: {
    type: Function,
    default: () => () => {},
  },
})
const statusMap = {
  ONE_SKU: 1,
  SELECT_SKU_SUPPORT: 1,
  SELECT_SKU_SUPPORT_NOT: 3,
  NOT_SELECT_SKU_ALL_SUPPORT: 1,
  NOT_SELECT_SKU_ONE_SALE: 1,
  NOT_SELECT_SKU_SMALL: 1,
  NOT_SELECT_SKU_MULTIPLE: 1,
}
const languageMap = computed(() => ({
  ONE_SKU: props.language.SHEIN_KEY_PWA_22316,
  SELECT_SKU_SUPPORT: props.language.SHEIN_KEY_PWA_22314,
  SELECT_SKU_SUPPORT_NOT: props.language.SHEIN_KEY_PWA_22315,
  NOT_SELECT_SKU_ALL_SUPPORT: props.language.SHEIN_KEY_PWA_22311,
  NOT_SELECT_SKU_ONE_SALE: props.language.SHEIN_KEY_PWA_22539,
  NOT_SELECT_SKU_SMALL: props.language.SHEIN_KEY_PWA_22312,
  NOT_SELECT_SKU_MULTIPLE: props.language.SHEIN_KEY_PWA_22313,
}))
const newLanguageMap = computed(() => ({
  ONE_SKU: props.language.SHEIN_KEY_PWA_30897,
  SELECT_SKU_SUPPORT: props.language.SHEIN_KEY_PWA_30889,
  SELECT_SKU_SUPPORT_NOT: props.language.SHEIN_KEY_PWA_30890,
  NOT_SELECT_SKU_ALL_SUPPORT: props.language.SHEIN_KEY_PWA_30884,
  NOT_SELECT_SKU_ONE_SALE: props.language.SHEIN_KEY_PWA_30885,
  NOT_SELECT_SKU_SMALL: props.language.SHEIN_KEY_PWA_30887,
  NOT_SELECT_SKU_MULTIPLE: props.language.SHEIN_KEY_PWA_30888,
}))
const quickShipSkuList = computed(() => props.skuList?.filter(sku => sku.isSupportQuickShip === '1'))
const quickShipType = computed(() => {
  const quickShipSkuLen = quickShipSkuList.value?.length || 0
  if (!quickShipSkuLen) return ''
  if (props.skuList.length === 1) {
    // 仅有一个sku
    return 'ONE_SKU'
  }
  const { sku_code, isSupportQuickShip } = props.skuInfo || {}
  if (sku_code) {
    // 选中sku
    return isSupportQuickShip === '1' ? 'SELECT_SKU_SUPPORT' : 'SELECT_SKU_SUPPORT_NOT'
  }
  if (props.skuList.length === quickShipSkuLen) {
    // 全部支持quickShip
    return 'NOT_SELECT_SKU_ALL_SUPPORT'
  }
  if (props.skcSaleAttr?.length === 1 && quickShipSkuLen <= 3) {
    // 单级销售属性情况下：如果支持的SKU数量 <= 3个
    return quickShipSkuLen === 1 ? 'NOT_SELECT_SKU_ONE_SALE' : 'NOT_SELECT_SKU_SMALL'
  }
  return 'NOT_SELECT_SKU_MULTIPLE'
})
// TODO: deliveryFloorQuickshipTitle
// TODO: 为了同步给物流使用
const status = computed(() => statusMap[quickShipType.value] || 0)
const renderTextDom = computed(() => {
  let zeroValue = ''
  if (['SELECT_SKU_SUPPORT', 'SELECT_SKU_SUPPORT_NOT'].includes(quickShipType.value)) {
    // 选中sku的
    const attrValueList = props.skuInfo?.sku_sale_attr?.map?.(item => props.handleCalcSize?.(item)?.renderText || '') || []
    zeroValue = `<b>${attrValueList.filter(item => !!item).join(' / ')}</b>`
  } else if (['NOT_SELECT_SKU_ALL_SUPPORT', 'NOT_SELECT_SKU_MULTIPLE'].includes(quickShipType.value)){
    const attrNames = props.skcSaleAttr?.map?.(item => item?.attr_name || '') || []
    zeroValue = attrNames.filter(item => !!item).join(' / ')
  } else if(['NOT_SELECT_SKU_ONE_SALE', 'NOT_SELECT_SKU_SMALL'].includes(quickShipType.value)){
    const attrValueList = quickShipSkuList.value?.map?.(item => {
      const attrValueId = item.sku_sale_attr[0].attr_value_id
      const { attr_value_list } = props.skcSaleAttr?.[0] || {}
      const attrValueItem = attr_value_list.find(_ => _.attr_value_id === attrValueId)
      return props.handleCalcSize?.(attrValueItem)?.renderText || ''
    }) || []
    zeroValue = attrValueList.filter(item => !!item).join(', ')
  }
  if (props.deliveryFloorQuickshipTitle) {
    const langText = newLanguageMap.value?.[quickShipType.value] || ''
    const quickshipTextDom = `<span class="text-green">${props.deliveryFloorQuickshipTitle}</span>`
    return zeroValue ? template(zeroValue, quickshipTextDom, langText) : template(quickshipTextDom, langText)
  }
  const langText = languageMap.value?.[quickShipType.value] || ''
  return template(zeroValue, langText)
})
const showTextDom = computed(() => {
  if (props.hiddenPartialQuickShip && ['NOT_SELECT_SKU_MULTIPLE', 'NOT_SELECT_SKU_ALL_SUPPORT'].includes(quickShipType.value)) {
    return false
  }
  return true
})
const showContent = computed(() => {
  return props.showQuickShip && renderTextDom.value
})
const exposeData = computed(() => {
  return {
    location: props.from === 'detailPage' ? 'detail' : 'popup',
    status: status.value || 0,
  }
})
watch(status, (val) => {
  props.handleUpdateQuickShip(val)
}, { immediate: true })
</script>

<style lang="less">
.goodsd-sizes {
  &__quickship {
    color: #666;
    font-size: 12px;
    line-height: 4.8vw;
    overflow: hidden;
    margin-bottom: 1.6vw;
    b {
      font-weight: bold;
      color: #222222;
    }
    .title {
      float: left;
      font-size: 10px;
      background: #F5FCFB;
      color: #198055;
      padding: 0 1.06667vw;
      margin-right: 1.06667vw;
    }
    .grey {
      color: #959595;
      background: #F6F6F6;
    }
    .text-green {
      color: #198055;
    }
  }
}
</style>
