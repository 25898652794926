import './default.css'

type ComponentProps = {
  price: string
}


export function DefaultPrice(props: ComponentProps) {
  return <div class={'bs-quick-add-cart-price-plugin'}>
    <span>{props.price}</span>
  </div>
}

DefaultPrice.props = {
  price: {
    type: String,
    required: true,
  },
}
