
<template>
  <div
    v-if="link"
    class="customeow-container"
    style="height: 100%; width: 100%"
  >
    <iframe
      ref="iframeRef"
      :src="link"
      scrolling="no"
      frameborder="0"
      style="width: 100%; height: 100%; border: none"
    ></iframe>
  </div>
</template>

<script>
import Logger from '../../../../utils/logger'
/* eslint-disable */
const TERM_CONFIRM_KEY = 'CUSTOMEOW_TERMS_CONFIRM'
const hasTermsConfirm = () => {
  return Boolean(localStorage.getItem(TERM_CONFIRM_KEY))
}
const setTermsConfirm = () => {
  localStorage.setItem(TERM_CONFIRM_KEY, true)
}

export default {
  name: 'CustomeowBox',
  props: {
    link: {
      type: String,
      required: true
    }
  },
  data() {
    return {}
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage, false)
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage, false)
  },
  methods: {
    showTermsModal() {
      this.iframePostMessage({
        type: 'customeow:terms:show',
      })
    },
    iframePostMessage(message) {
      this.$refs?.iframeRef?.contentWindow?.postMessage?.(message, '*')
    },
    receiveMessage(event) {
      
      const { type, data } = event?.data || {}

      Logger.info(`CustomeowBox:Message:${type}`, data);
      if (!type) return


      const EVENT_MAP = {
        'customeow:mount': () => {
          this.$emit('mount')

          if (hasTermsConfirm()) return
          this.showTermsModal()
        },
        'customeow:close': () => {
          this.$emit('close')
        },
        'customeow:confirm': () => {
          /**
           * {
           * "customInfoId": "25239782049185792022",
           *   "preview": { // 预览部分 ⽤在购物⻋预览或者订单预览中
           *   "effects": ["", ""], // 预览图（产品形态与⽤⼾定制信息结合的图）可选
           *   "images": ["", ""], // 图⽚类型的定制信息 可选
           *   "texts": [""] // ⽂字类型的定制信息 可选
           *   }
           * }
           */
          this.$emit('confirm', data ? JSON.stringify(data) : '')
        },
        'customeow:error': () => {
          // 第三方服务器异常orsku不存在
          this.$emit('error')
        },
        'customeow:terms:confirm': () => {
          // 第三方授权弹窗确认
          setTermsConfirm()
        },
      }

      if (!EVENT_MAP[type]) return

      EVENT_MAP[type]()
    },
  },
}
</script>
