export class LRUCache<T> {
    private cache: Map<string, { value: T, timestamp: number }> = new Map()
    private maxCacheSize: number
    private ttl: number

    constructor(maxCacheSize: number, ttl: number) {
      this.maxCacheSize = maxCacheSize
      this.ttl = ttl
    }

    get(key: string): T | undefined {
      const item = this.cache.get(key)
      if (item) {
        // 检查缓存项是否过期（以存储时间为基准）
        if (Date.now() - item.timestamp < this.ttl) {
          return item.value
        } else {
          this.cache.delete(key)
        }
      }
      return undefined
    }

    set(key: string, value: T): void {
      if (this.cache.size >= this.maxCacheSize) {
        // 删除最旧的缓存项
        const oldestKey = this.findOldestKey()
        this.cache.delete(oldestKey)
      }

      this.cache.set(key, { value, timestamp: Date.now() })
    }

    has(key: string): boolean {
      return this.cache.has(key) && this.get(key) !== undefined
    }

    private findOldestKey(): string {
      let oldestKey = ''
      let oldestTimestamp = Infinity
      for (const [key, item] of this.cache) {
        if (item.timestamp < oldestTimestamp) {
          oldestKey = key
          oldestTimestamp = item.timestamp
        }
      }
      return oldestKey
    }

    /**
     * 根据相似的 key 删除缓存项
     * @description 只允许用goods id删除缓存项
     * @param key
     */
    deleteBySimilarKey(key?: string): void {
      if(key) {
        for (const similarKey of this.cache.keys()) {
          if (similarKey.includes(key)) {
            this.cache.delete(similarKey)
          }
        }
      }
    }

    clear(): void {
        this.cache.clear()
    }
  }
