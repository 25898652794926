<template>
  <div>
    <div class="productPriceTips">
      <LowestPriceTips
        v-if="lowestPriceTips"
        :lowest-price-tips="lowestPriceTips" />
      <AfterPayInfo
        v-if="afterPayInfo"
        :after-pay-info="afterPayInfo"
        :show-list="showList"
        @toogleList="toogleList" />
      <AppExclusiveDiscount
        v-if="appExclusiveDiscount"
        :app-exclusive-discount="appExclusiveDiscount" />
      <SheinClubPromotionFloor
        v-if="sheinClubPromotionFloorInfo"
        :shein-club-promotion-floor-info="sheinClubPromotionFloorInfo"
        @closeQuickAddCart="emits('closeQuickAddCart')"
      />
    </div>
  </div>
</template>

<script setup name="PriceTips">
/**
 * @file 主价格和促销楼层之间的价格提示信息
*/
import { ref, toRefs } from 'vue'
import usePriceTips from '../../hook/usePriceTips'

import AfterPayInfo from './components/AfterPayInfo.vue'
import AppExclusiveDiscount from './components/AppExclusiveDiscount.vue'
import LowestPriceTips from './components/LowestPriceTips.vue'
import SheinClubPromotionFloor from './components/SheinClubPromotionFloor.vue'

const emits = defineEmits(['closeQuickAddCart'])

const props = defineProps({
  mallCode: {
    type: String,
    default: '',
  },
  featureConfig: {
    type: Object,
    default: () => ({}),
  },
  skuInfo: {
    type: Object,
    default: () => ({}),
  },
  isSoldOut: {
    type: Boolean,
    default: false,
  },
  currentStock: {
    type: Number,
    default: 1,
  },
})
const {
  mallCode,
  featureConfig,
  skuInfo,
  isSoldOut,
  currentStock,
} = toRefs(props)
const {
  lowestPriceTips,
  afterPayInfo,
  appExclusiveDiscount,
  sheinClubPromotionFloorInfo,
} = usePriceTips({
  mallCode,
  featureConfig,
  skuInfo,
  isSoldOut,
  currentStock,
})

const showList = ref(false)

const toogleList = () => {
  showList.value = !showList.value
}

</script>

<style lang="less">
.productPriceTips {
  margin-bottom: .2133rem;
}
</style>
