/* eslint-disable max-lines-per-function */
import type { IProps, IToggleWishFetchFinishParams } from './types.ts'
import type { AS_QuickAddCartStandard, DS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile-vue2'
import { useModules } from '../../modules/useModules.ts'
import { getQueryString } from '@shein/common-function'
import { callHook } from './utils.ts'
import { inject } from 'vue'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import {
    getAddCartFormData,
    getPopupFrom,
    getIsFromActivity,
    getSyncClickGaEventCategory } from './utils.ts'
/**
* 加车埋点
* TODO: 需要重新整理，目前是直接复制过来的
*/
export const useReportSa = (props: IProps) => {
    const { priceInfo, storeInfo, fsAbt } = useModules()
    const appConfigs = useAppConfigs()

    const analysisInstance = inject<AS_QuickAddCartStandard.AnalysisInstance>('analysisInstance')

    function reportClickAddCollect (data: IToggleWishFetchFinishParams) {
      const featureConfig = props.featureConfig
      const analysisConfig = props.analysisConfig
      const productInfo = props.productInfo

      if (!analysisConfig || !productInfo || !featureConfig) {
          return
      }
      let activity_from = ''
      const popupFrom = getPopupFrom(analysisConfig!)
      switch (popupFrom) {
          case 'theOptions':
              activity_from = 'other_options'
              break
          case 'getTheLook':
              activity_from = 'get_the_look'
              break
      }
      const syncClickGaEventCategory = getSyncClickGaEventCategory(popupFrom)
      const extraData = {
          code: analysisConfig.code || 'other',
          // 转换成旧的node接口的格式
          result: { data: { ...data, code: data.result === 1 ? 0 : -1 } },
          page_name: appConfigs.$getters.SaPageInfo.page_name,
          mall_code: props.mallCode,
          passParams: analysisConfig?.sa?.passParams || {},
          postData: {
              quickShip: props.quickShip,
              action: data.isNotSelected ? 0: 1, // 选中发1, 取消发0
              goods_id: productInfo?.goods_id,
              sku: productInfo?.goods_sn,

              detail: productInfo,
              // @ts-ignore
              isShowFollowBelt: featureConfig.isShowFollowBelt,
              recommendType: syncClickGaEventCategory === '推荐列表' ? popupFrom.indexOf('auto_rcmd_goods_list') > -1 ? 2 : 1 : 0,
              locateLabels: analysisConfig?.sa?.mobileVerticalView?.locateLabels,
              feed_type: analysisConfig?.sa?.feed_type || '',
              isAttrFold: analysisConfig?.isAttrFold,
          },
          ranking_from: analysisConfig?.sa?.ranking_from || '',
          // page_name: analysisConfig.page_name || '',
          target: analysisConfig.sourceTarget || null,
          from: activity_from || analysisConfig.sa && (analysisConfig.sa.activity_from || analysisConfig.sa.from) || popupFrom,
          daEventId: syncClickGaEventCategory === '推荐列表' ? '2-4-1' : null,
          review_location: analysisConfig?.review_location || '', // 评论页的收藏
      }
      const param = callHook(props.callbacks, 'onFavoriteReportAnalysisBefore', {
          extraData,
      })
      const eventData = {
        id: 'click_add_collect.comp_quick-add-cart-standard',
        data: {
          page_name: appConfigs.$getters.SaPageInfo.page_name,
          ...(param || extraData || {}),
        },
      } as { id: AS_QuickAddCartStandard.Events, data: any }
      analysisInstance && analysisInstance.triggerNotice(eventData)
    }

    function reportSaLoadingCart () {
        analysisInstance?.triggerNotice({
            id: 'expose_loadingcart.comp_quick-add-cart-standard',
        })
    }

    function reportLowstockLabel (skuInventory: DS_QuickAddCartStandard.Sku | undefined) {
      // 进行低库存提示的曝光上报
      analysisInstance && analysisInstance.triggerNotice({
        id: 'expose_lowstock_label.comp_quick-add-cart-standard',
        data: {
            goods_level: 'sku', // 在这个场景不存在 skc 的情况
            label_type: skuInventory?.lowStockType,
            location: 'addtocarttoast',
        },
      })
    }
    function reportSaAddCartEvent (params: {
        result: any
        faultReason?: string
        variant?: string
        gaList?: string
        btnType?: string
    }) {
        const { result, faultReason, variant, gaList, btnType } = params
        const skuInfo = props.skuInfo
        const salePrice = skuInfo?.price ? skuInfo.price.retailPrice?.amountWithSymbol : priceInfo?.value?.salePrice?.amount
        const analysisConfig = props.analysisConfig!
        const isFromActivity = getIsFromActivity(analysisConfig!)
        const popupFrom = getPopupFrom(analysisConfig!)
        let from = popupFrom
        if (analysisConfig.from == 'theOptions' || isFromActivity) {
            from = analysisConfig?.sa?.from
        }
        const productInfo = props.productInfo
        if (!productInfo || !analysisConfig) return
        // let traceid = '' // TODO
        //   const traceid = gbExposeTraceid('getProduct', {
        //     goods_id: props.productInfo.goodsId
        //   })

        const eventCategory = getSyncClickGaEventCategory(popupFrom)
        const { actScenes = '' } = analysisConfig || ''
        const actOptions = isFromActivity
            ? {
              actOptions: {
                actScenes,
              },
            }
            : {}
        const formData = getAddCartFormData({
            skuInfo: props.skuInfo,
            productInfo: props.productInfo!,
            analysisConfig: props.analysisConfig,
            featureConfig: props.featureConfig,
            fsAbt: fsAbt ? fsAbt.value: undefined,
        })
        const threshold = props.estimatedInfo?.dataType


        const extraData = {
            page_name: appConfigs.$getters.SaPageInfo.page_name,
            target: analysisConfig.sourceTarget || null,
            code: analysisConfig.code || 'other',
            promotion_id: analysisConfig.promotion_id || '',
            promotion_state: analysisConfig.promotion_state || '',
            // traceid: ['wishlist'].includes(from) ? '' : traceid, TODO 是否可以删除
            review_location: analysisConfig.review_location || '',
            ranking_from: analysisConfig.sa?.ranking_from || '',
            coupon_type: analysisConfig.sa?.coupon_type || '', // 购物车页券筛选
            passParams: analysisConfig.sa?.passParams || {},
            postData: {
              attrs: props.skuInfo?.sku_sale_attr,
              goodsListFromTarget: analysisConfig.getGoodsListFromTarget?.(),
              is_customize: productInfo.__isCustomization ? 1 : 0,
              sku: productInfo.goods_sn,
              //@ts-ignore
              goods_id: productInfo.goods_id!,
              quickShip: props.quickShip,
              spu: productInfo.productRelationID,
              catId: productInfo.cat_id,
              price: skuInfo?.salePrice?.usdAmount || salePrice,
              originPrice: priceInfo?.value?.retailPrice?.usdAmount || '',
              discount: priceInfo?.value?.unitDiscount,
              recommendType:
                  eventCategory === '推荐列表'
                    ? from.indexOf('auto_rcmd_goods_list') > -1
                      ? 2
                      : 1
                    : 0,
            //   index: this.index,
              amount: salePrice,
              variant,
              businessModel: storeInfo?.value?.business_model || '',
              storeCode:storeInfo?.value?.store_code,
              mallCode: formData.mall_code,
              is_default_mall: productInfo.selectedMallCode == formData.mall_code ? 1 : 0,
              location: analysisConfig.sa?.location || 'popup',
              feed_type: analysisConfig.sa?.feed_type || '',
              image_tp: props.largeImageMode ? 'large' : 'small',
              style: analysisConfig.sa?.style || 'popup',
              locateLabels: analysisConfig.sa?.mobileVerticalView?.locateLabels,
              ...formData,
              ...(analysisConfig?.postData || {}),
              ...(threshold ? { threshold } : {}),
            },
            gaList,
            eventCategory,
            result,
            from,
            faultReason,
            ...actOptions,
            button_type: btnType,
            isAttrFold: analysisConfig.isAttrFold || '0',
            hypernymProductSum: analysisConfig.hypernymProductSum || null,
          }
          // 从预搜页找相似跳转过来的情况须多加几个参数
          const isFromPreSearchSimilarItems = !!getQueryString({ key: 'preSearchSimilarItems' })
          const srcTabPageId = getQueryString({ key: 'src_tab_page_id' }) || ''
          if (isFromPreSearchSimilarItems && srcTabPageId) {
            Object.assign(
              extraData, {
                src_module: 'presearch_similar',
                src_identifier: '-',
                src_tab_page_id: srcTabPageId,
              },
            )
          }
        const eventData = {
          id: 'click_add_bag.comp_quick-add-cart-standard',
          data: extraData,
        }
        analysisInstance?.triggerNotice(eventData)
    }

    return {
      reportClickAddCollect,
      reportLowstockLabel,
      reportSaLoadingCart,
      reportSaAddCartEvent,
    }
}
