<script lang="jsx">
import { Button } from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'
export default {
  name: 'SizeFeedBackSelectPanel',
  functional: true,
  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    status: {
      type: String,
      default: '',
    },
    locationSelectShow: {
      type: Boolean,
      default: false,
    },
    // hasLocalSizeOptions: {
    //   type: Boolean,
    //   default: false,
    // },
    feedBackSizeList: {
      type: Array,
      default: () => [],
    },
    selectedSize: {
      type: Object,
      default: () => ({}),
    },
    country: {
      type: String,
      default: '',
    },
  },
  render(h, { props, listeners }) {
    const {
      language,
      status,
      locationSelectShow,
      // hasLocalSizeOptions,
      feedBackSizeList = [],
      selectedSize = {},
      country,
    } = props

    // TODO: hasLocalSizeOptions 感觉是bug, 先删掉了，后面再看
    const locationSelectText = !country
      ? language.SHEIN_KEY_PWA_23344
      : `${country} ${language.SHEIN_KEY_PWA_15701}`

    // 本地选择
    const locationSelect = () =>
      locationSelectShow ? (
        <div
          class="region-select-box__local"
          v-on:click={listeners['onOpenLocationBtn']}
        >
          <span>
            {locationSelectText}
            <Icon name="sui_icon_more_down_12px_2" size="12px" />
          </span>
        </div>
      ) : null

    // 本地化选择面板
    const localSizeSelectPanel = () =>
      status === 'ready' ? (
        <div>
          <div class="tips-band">{language.SHEIN_KEY_PWA_21089}</div>
          <div class="region-select-box">
            <p>{language.SHEIN_KEY_PWA_15701}</p>
            {locationSelect()}
          </div>
        </div>
      ) : null

    // 尺码选择面板
    const sizeSelectItem = () =>
      feedBackSizeList?.map((size) => (
        <li class={[{ active: size.key == selectedSize.key }]}>
          <div
            class="ellipsis"
            v-on:click={() => listeners['selectSize'](size)}
          >
            {size.text}
          </div>
        </li>
      ))

    const sizeSelectPanel = () => (
      <div class="size-select-box">
        <ul class="size-select-list">{sizeSelectItem()}</ul>
      </div>
    )

    // submit
    const submitContent = () => (
      <div class="submit-box">
        <Button
          type={['primary', 'H40PX']}
          disabled={!selectedSize.key}
          width={'100%'}
          v-on:click={listeners['handleSubmit']}
        >
          {language.SHEIN_KEY_PWA_21198}
        </Button>
      </div>
    )

    return (
      <div>
        {localSizeSelectPanel()}
        {sizeSelectPanel()}
        {submitContent()}
      </div>
    )
  },
}
</script>
