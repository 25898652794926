import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import transfromAndCutImg from '../../common/transfromAndCutImg'

/** 图片转换裁切 */
const useBaseCutImg = (imgUrl, designWidth, exp) => {
  const appConfigs = useAppConfigs()
  const { RESOURCE_SDK } = appConfigs.$envs || {}

	const {
    deviceData = '',
    isSupportWeb = '',
    isSupprotCut = false,
    sceneMap,
  } = RESOURCE_SDK || {}

	const cutData = {
    deviceData,
    isSupportWebp: Boolean(isSupportWeb),
    isSupprotCut,
    imgUrl,
    designWidth: Number(designWidth),
    sceneMap,
    exp,
  }

	return transfromAndCutImg(cutData)
}

export default useBaseCutImg
