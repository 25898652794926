
import type { DS_QuickAddCartStandard } from '../../../../types/index'
import { getQueryString} from '@shein/common-function'
import logger from '../../utils/logger.ts'
import { nextTick } from 'vue'
import { usePlugins } from '../../plugins/index.ts'



function clickFn(event) {
    event.preventDefault() // 阻止默认行为
    event.stopPropagation() // 阻止事件冒泡
    return false // 返回 false
}

function getContainerElement () {
  return document.querySelector('.bsc-quick-add-cart-standard-drawer') || document
}

export function callHook<K extends keyof DS_QuickAddCartStandard.CallBacks>(callbacks: DS_QuickAddCartStandard.CallBacks | undefined , hook: K, ...args: [...Parameters<NonNullable<DS_QuickAddCartStandard.CallBacks[K]>>]) {
  usePlugins().callbacks(hook, ...args)
  if (callbacks && typeof callbacks[hook] === 'function') {
     logger.info('callbacks[hook]', hook, args)
     try {
      return callbacks[hook](...(args as [any]))
     } catch(e) {
      console.error(e)
     }
  }
}
export const removeLockPage = () => {
  if (typeof window !== 'undefined' || typeof document !== 'undefined') {
    getContainerElement().removeEventListener?.('click', clickFn, true)
  }
}



export const lockPage = () => {
    if (typeof window !== 'undefined' || typeof document !== 'undefined') {
      removeLockPage()
      getContainerElement().addEventListener?.('click', clickFn, true)
      nextTick(() => {
          removeLockPage()
      })
    }
}

const getAttrAndTag = function () {
  const search = location.search.trim().replace(/^(\?|#|&)/, '')
  if (!search) {
    return ''
  }
  let str = ''
  let tagStr = ''
  let attrStr = ''

  search.split('&').forEach(function (value) {
    const key = value.split('=')[0]
    const val = value.split('=')[1]
    if (key === 'tag_ids') tagStr = val
    if (key === 'attr_ids') attrStr = val
  })

  if (tagStr) {
    str += '&tag_ids=' + tagStr
  }
  if (attrStr) {
    str += '&attr_str=' + attrStr
  }
  return str
}

export const getIsFromActivity = (analysisConfig: DS_QuickAddCartStandard.AnalysisConfig) => {
  return analysisConfig?.from === 'activityQuickAdd'
}


export const getVariantAttr = (skuInfo: any) => {
  const { sku_sale_attr } = skuInfo
  const target = (sku_sale_attr || []).find(item => item?.attr_id == '87')
  if (target) {
    return target.attr_value_name
  }
  return ''
}


// 获取埋点的ga列表参数
// * 如果不传会通过code去拿gaList，值：PageGroup/推荐位list
export const getReportGaList = (analysisConfig: DS_QuickAddCartStandard.AnalysisConfig, catId: string) => {
  if (typeof window === 'undefined' || !analysisConfig) {
    return ''
  }
  const PageGroup = window.PageGroup || ''
  const isFromActivity = getIsFromActivity(analysisConfig)
  const attrStr = getAttrAndTag()
  const _da_list = isFromActivity
        ? analysisConfig.ga?.name
        : PageGroup + attrStr
  let quickAddGaList = ''
  const configGaList = analysisConfig?.ga?.list || ''
  let noNeedGaList = false

  const from = getPopupFrom(analysisConfig)
  if (
    [
      'similar_items',
      'get_the_look',
      'get_the_look_syte',
      'often_bought_with',
      'featured_reviews_detail',
    ].includes(from) ||
      from.indexOf('auto_rcmd_goods_list') > -1
      ) {
        noNeedGaList = true
      }

      if (analysisConfig.from == 'theOptions') {
        quickAddGaList = '商品详情页-推荐列表-OtherOptions'
      } else if (analysisConfig.from == 'cart') {
        quickAddGaList = configGaList
      } else if (configGaList) {
        quickAddGaList =
          configGaList +
          (analysisConfig.tab
            ? analysisConfig.tab
            : catId)
      } else {
        quickAddGaList = _da_list as string
      }
      return noNeedGaList ? null : configGaList || quickAddGaList
}

export const getPopupFrom = (analysisConfig: DS_QuickAddCartStandard.AnalysisConfig) => {
  return (
    analysisConfig?.sa?.activity_from ||
    analysisConfig?.sa?.from ||
    analysisConfig?.from ||
    ''
  )
}

export const getSyncClickGaEventCategory = (popupFrom: string) => {
  const type = [
    'similar_items',
    'you_may_also_like',
    'get_the_look',
    'get_the_look_syte',
    'often_bought_with',
    'fill_it_with_no_empty',
    'auto_rcmd_goods_list',
    'theOptions',
    'cart',
  ].find((i) => popupFrom.indexOf(i) > -1)
    ? 'recommend'
    : popupFrom
  const gaEventCategoryList = {
    recently_viewed: '最近浏览',
    wishlist: '收藏夹',
    recommend: '推荐列表',
    more_size: '商品详情页',
    birthday_gift_free: '',
    birthday_gift_pay: '',
    exclusive_discount: '会员权益页',
    birthday_gift_list_free: '',
    birthday_gift_list_pay: '生日礼商品列表页',
    collection_boards: '收藏分组商品页',
    live_details: '直播详情页',
    cartAddItem: '凑单页',
    featured_reviews_detail: '买家秀大图',
    main: '商品详情页',
  }
  const gaEventCategory =
    gaEventCategoryList[type] !== undefined ? gaEventCategoryList[type] : '列表页'
  return gaEventCategory
}



// 该逻辑迁移自中间层
// export const getAutoUseCouponparam = (fsAbt?: DS_QuickAddCartStandard.FsAbt) => {
  // if (fsAbt) {
  //   return fsAbt?.Cartshowcoupon?.param?.cart_show_coupon_switch == 'on' ? '1' : '0'
  // }
  // return '0'
// }

/**
* 加车数据处理
* //TODO skuInfo 补充类型
*/
export const getAddCartFormData = (params: {
  skuInfo: any;
  productInfo: DS_QuickAddCartStandard.ProductInfo
  analysisConfig?: DS_QuickAddCartStandard.AnalysisConfig
  featureConfig?: DS_QuickAddCartStandard.FeatureConfig
  fsAbt?: DS_QuickAddCartStandard.FsAbt
  quantity?: number,
  addSource?: string
}) => {
    const {
      skuInfo,
      productInfo,
      analysisConfig,
      featureConfig,
      quantity,
      addSource,
    } = params

    const skuCode = skuInfo.sku_code
    const { goods_id, selectedMallCode } = productInfo
    const formData: DS_QuickAddCartStandard.PostAddToCartParams = {
        goods_id,
        quantity: `${quantity || 1}`,
        mall_code: selectedMallCode || '',
        promotion_id: analysisConfig?.promotion_type || '0',
        promotion_type: analysisConfig?.promotion_type || '0',
        // freeShippingAbtParams: 'type=B',
        sku_code: skuCode,
        // auto_use_coupon: getAutoUseCouponparam(fsAbt),
      }
      if (analysisConfig?.promotion_id) {
        formData.promotion_id = analysisConfig.promotion_id
      }

      if (analysisConfig?.promotion_type) {
        formData.promotion_type = analysisConfig.promotion_type
      }
      //   加车新增归因参数add_source
      if(goods_id === featureConfig?.mainPageGoodsId){
        // 主商品唤起的快速加车弹窗，从url中获取add_source，url中的add_source参数是跳转场景中detailUrlExtendParams的add_source
        const addSource = getQueryString({ key: 'add_source' }) || ''
        logger.log('addSource 主商品', addSource)
        if(addSource) formData.addSource = `detail_${addSource}`
      }else {
        // 非主商品唤起的快速加车弹窗，从配置项sourceIdentifier中获取add_source
        logger.log('addSource 非主商品', addSource)
        if(addSource){
          formData.addSource = addSource
        }

      }

      return formData
}
