<template>
  <div class="quickViewQuantity">
    <span class="qty-text">{{ title }}: </span>
    <div class="calc">
      <div
        :class="{ disabled: quantity <= 1, iconBox: true }"
        @click="handleReduce"
      >
        <Icon
          name="sui_icon_min_16px"
          size="18px"
        />
      </div>
      <input
        type="text"
        :value="quantity"
        :disabled="addCartUpperLimit === 0"
        readonly
      />
      <div
        :class="{ disabled: addCartUpperLimit <= quantity, iconBox: true }"
        @click="handleAdd"
      >
        <Icon
          name="sui_icon_add_16px"
          size="18px"
        />
      </div>
    </div>
    <div class="quickViewQuantity__tips">
      <p
        v-if="buyMultipleText"
        class="quickViewQuantity__buyMultipleText"
        :class="{ 'quickViewQuantity__tips-gap': !!stockTip }"
      >
        <Icon
          name="sui_icon_activity_orange_12px"
          size="12px"
          style="margin-right: 2px"
        />
        {{ buyMultipleText }}
      </p>
      <div
        v-if="stockTip"
        v-expose="stockTipExpose"
        class="quickViewQuantity__tips-stock"
        :class="[currentStock === 0 ? 'grey' : 'orange']"
      >
        <Icon
          name="sui_icon_hourglass_12px_2"
          size="12px"
          style="margin-right: 2px"
        />
        {{ stockTip }}
      </div>
    </div>
  </div>
</template>

<script name="QuickCartQuantity" setup lang="ts">
import { computed, watch, defineProps, inject } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { AS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile-vue2'
import { useModules } from '../../modules/useModules'

const props = defineProps({
  quantity: {
    type: Number,
    default: 0,
  },
  currentStock: {
    type: Number,
    default: 0,
  },
  buyMultipleText: {
    type: String,
    default: '',
  },
  willSoldOutTipsInfo: {
    type: Object,
    default: () => ({}),
  },
  setQuantity: {
    type: Function,
    default: () => {},
  },
})
const { detailPromotionFloor, language } = useModules()
const { vExpose, triggerNotice } = inject<AS_QuickAddCartStandard.AnalysisInstance>('analysisInstance') || {}
const title = computed(() => language?.value?.SHEIN_KEY_PWA_15664 || 'Qty')
const detailPromotionInfo = computed(() => detailPromotionFloor?.value?.detailPromotionInfo || [])
const flashSaleLimit = computed(() => {
  const flashSaleData = detailPromotionInfo.value?.find?.((item) => item.typeId == 10)
  if (!flashSaleData || typeof window === 'undefined') return 100000
  const currentTime = Date.now() / 1000
  const {
    singleLimitType,
    buyLimit,
    soldNum,
    endTimestamp,
    singleNum,
  } = flashSaleData
  const hasLimit = (singleLimitType == 1 || singleLimitType == 3)
  if (hasLimit && Number(buyLimit) > Number(soldNum) && Number(currentTime) < Number(endTimestamp)) return Number(singleNum)
  return 100000
})
const underPriceLimit = computed(() => {
  const underPriceInfo = detailPromotionInfo.value?.find?.((item) => item.typeId == 30)
  const limitNum = Number(underPriceInfo?.singleNum)
  if (isNaN(limitNum) || !limitNum) {
    return 100000
  }
  return limitNum
})
const addCartUpperLimit = computed(() => Math.min(flashSaleLimit.value, underPriceLimit.value, props.currentStock))
const stockTip = computed(() => {
  // if (props.currentStock <= 0) {
  //   return language?.value?.SHEIN_KEY_PWA_14987 || 'sold out'
  // }
  return props.willSoldOutTipsInfo?.tip || ''
})
const stockTipExpose = computed(() => {
  if (props.currentStock <= 0) return {}
  return {
    id: 'expose_lowstock_label.comp_quick-add-cart-standard',
    prefix: 'comp_quick-add-cart-standard',
    data: {
      goods_level: props.willSoldOutTipsInfo?.scene,
      label_type: props.willSoldOutTipsInfo?.lowStockType == 2 ? 1 : 2,
      location: 'qty',
    },
  }
})

const handleReduce = () => {
  triggerNotice?.({
    id: 'click_qty.comp_quick-add-cart-standard',
  })
  if (props.quantity <= 1) return
  props.setQuantity?.(props.quantity - 1)
}
const handleAdd = () => {
  triggerNotice?.({
    id: 'click_qty.comp_quick-add-cart-standard',
  })
  if (props.quantity >= addCartUpperLimit.value) return
  props.setQuantity?.(props.quantity + 1)
}

watch(addCartUpperLimit, () => {
  if (props.quantity > addCartUpperLimit.value) {
    const count = addCartUpperLimit.value > 0 ? addCartUpperLimit.value : 1
    props.setQuantity?.(count)
  }else if(props.quantity < 1) {
    props.setQuantity?.(1)
  }
})
</script>

<style lang="less" scoped>
.quickViewQuantity {
  display: flex;
  align-items: center;
  margin-bottom: 3.2vw;
  margin-top: 2.4vw;

  .qty-text {
    font-size: 12px;
  }

  .calc{
    display: flex;
    margin-left: 3.2vw;
    margin-right: 2.133vw;
    // TODO: ar站点翻转
    // .margin-l(.32rem);
    // .margin-r(.2133rem);
    .iconBox{
      width: 6.4vw;
      height: 6.4vw;
      background:rgba(246,246,246,1);
      color: #222;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      &.disabled{
        opacity: .3;
      }
    }
    input{
      width: 9.6vw;
      height: 6.4vw;
      text-align: center;
      font-weight: bold;
      border: none;
      outline: none;
      font-size: 14px;
      &[disabled] {
          color: #ccc;
      }
    }
  }
  &__tips-stock {
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  .orange{
    color: #C44A01;
  }
  .grey{
    color: #ccc;
  }

  &__tips {
    display: flex;
    flex-direction: column;
  }
  &__tips-gap {
    margin-bottom: 1.067vw;
  }
  &__buyMultipleText {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: #C44A01;
    display: flex;
    align-items: center;
  }
}
</style>
