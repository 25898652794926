import { DS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile-vue2'

/**
 * 阿波罗逻辑处理插件
 */
export const apolloConfigPlugin = (appConfigs): DS_QuickAddCartStandard.QuickAddCartPlugin => {
	/** 获取阿波罗配置 */
	const getApolloConfig = async () => {
		const { langPath = '' } = appConfigs.$envs || {}
		const { data } = await appConfigs.$schttp({
			method: 'POST',
      url: `${window.location.origin}${langPath}/api/config/apolloConfig/query`,
      data: {
        apolloKeys:
          [
            'shein_suggested_sale_price',
          ]
          .join(','),
      },
		})
		return data?.info || {}
	}

	/** 合规价配置处理 */
	const getComplianceModeConfig = (config: any) => {
		const { siteUID = '' } = appConfigs.$envs || {}
		const complianceMode = ['normal', 'special'].includes(config?.[siteUID])
		const complianceTipsMode = config?.[siteUID] === 'special'
		const complianceModeDe = config?.[siteUID] === 'special_de'

		const isHitComplianceMode = complianceMode || complianceModeDe // 是否命中了任意合规模式

		return {
			complianceMode,
			complianceTipsMode,
			complianceModeDe,
			isHitComplianceMode,
		}
	}

  return {
    name: 'apolloConfigPlugin',
		beforeOpen: async context => {
			try {
				const config = await getApolloConfig()
				if (config?.shein_suggested_sale_price) {
					const complianceModeConfig = getComplianceModeConfig(config?.shein_suggested_sale_price || {})
					// 注入配置
					context.op.featureConfig.needComplianceModel = complianceModeConfig
				}
			} catch (error) {
				console.log('🚀 ~ apolloConfigPlugin ~ error:', error)
			}
      return false
    },
  }
}
