import Vue from 'vue'
import { Toast } from '@shein/sui-mobile'
import CustomeowDrawerVue from './index.vue'

let CustomeowDrawerInstance: Vue | null = null

const customerMade = async (customerInfo: {
  link: string;
  errorTip?: string;
}) => {
  return new Promise((resolve) => {
    // 挂在实例
    const dom = document.createElement('div')
    document.body.appendChild(dom)

    const close = () => {
      CustomeowDrawerInstance?.$destroy?.()
      CustomeowDrawerInstance = null
      resolve(undefined)
    }

    CustomeowDrawerInstance = new Vue({
      el: dom,
      render(h) {
        return h(CustomeowDrawerVue, {
          props: {
            show: true,
            ...customerInfo,
            onClose: close,
            onConfirm: (data) => {
              CustomeowDrawerInstance?.$destroy?.()
              CustomeowDrawerInstance = null
              resolve(data)
            },
            onError: (e) => {
              console.error(e)
              close()
              Toast({
                content: customerInfo?.errorTip,
                duration: 3000,
              })
            },
          },
        })
      },
    })

    CustomeowDrawerInstance.$mount()
  })
}

export default customerMade
