import * as standard from './sources/standard'
import { organizeDataSource } from '@shein-aidc/utils-data-source-toolkit'
import type { DS_SubSalesAttr } from '../../types'

export function getSource({ dataSource = {}, appConfigs }: DS_SubSalesAttr.SourceParams): DS_SubSalesAttr.APIS {
  return organizeDataSource<DS_SubSalesAttr.APIS, DS_SubSalesAttr.DataSource>(
    {
      standard,
    },
    dataSource,
    appConfigs,
  )
}

