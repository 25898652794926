<template>
  <div class="gallery">
    <Loading
      class="gallery__loading"
      :show="loading"
      type="curpage"
      :cur-page-scroll-fix="false"
      :container-cover-style="{
        position: 'absolute',
        top: 0,
      }"
    />
    <swiper-container
      ref="swiperEl"
      init="false"
      class="gallery__swiper-container"
    >
      <swiper-slide
        v-for="(item, swiperIndex) in imgs"
        :key="swiperIndex"
        role="slider"
        :aria-label="language.SHEIN_KEY_PWA_15408"
        :aria-valuenow="swiperIndex"
        :aria-valuemin="0"
        :aria-valuemax="imgs.length"
        tabindex="0"
        class="gallery__swiper-slide"
        :class="swiperProportionClass"
        @click.stop="handleClick(swiperIndex)"
      >
        <!-- 临时方案兼容低版本不支持webp系统 -->
        <CropImageContainer
          :fixed-ratio="fixedRatio"
          :img-src="isSwitchColor && loading ? lazyImage : transformImg({img: item.origin_image?.replace('.webp', '.jpg') || ''})"
          :img-props="{
            loadImg: lazyImage
          }"
        />
      </swiper-slide>
    </swiper-container>
  </div>
</template>

  <script lang="ts" setup>
  import { type Libs, useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
  import { ref, toRefs, computed, watch, nextTick, onUnmounted } from 'vue'
  import CropImageContainer from './crop-Image-container.vue'
  import { Loading } from '@shein/sui-mobile'
  import { transformImg } from '@shein/common-function'
  // 使用核心版本注册，不包含额外的模块
  import { register } from 'swiper/element'
  import type { Swiper } from 'swiper'
  import { BGalleryProps } from './types'
  // 只在客户端环境中注册 swiper
  typeof window !== 'undefined' && register()

  const props = defineProps(BGalleryProps)
  const { openImgModal,language, lazyImage } = props
  const { loading, fixedRatio, middleSizeMode, imgs, isSwitchColor } = toRefs(props)
  const swiperInstance = ref<Swiper[] | null>(null)
  const swiperEl = ref<HTMLElement | null>(null)
  const appConfigs = useAppConfigs().$envs as Libs.Envs
  const swiperProportionClass = computed(() => {
    const value = fixedRatio.value
    const result = {
      's-swiper-slide_11': value === '1-1',
      's-swiper-slide_45': value === '4-5',
      's-swiper-slide_1316': value === '13-16',
      'middle-size': middleSizeMode.value,
    }
    return result
  })

  watch(
    () => imgs.value,
    newVal => {
      if (newVal?.length) {
        nextTick(() => {
          initSwiper()
        })
      }
    },
    {
      immediate: true,
    },
  )
  watch(
    () => middleSizeMode.value,
    () => {
      nextTick(() => {
        (swiperInstance.value as unknown as Swiper)?.update()
        getStart()
      })
    },
  )

  const getStart = (index = 0 as number, duration = 0 as number) => {
    if (Array.isArray(swiperInstance.value) && swiperInstance.value.length) {
      swiperInstance.value.forEach(i => i.slideTo(index, duration, false))
    } else if (swiperInstance.value) {
      (swiperInstance.value as unknown as Swiper).slideTo(index, duration, false)
    }
  }

  const handleClick = (index: number) => {
    openImgModal(index)
  }

  // const sizeCheck = (img: string) => {
  //     if (img.indexOf('_thumbnail_') > -1) return img
  //     return img.replace('.jpg', '_thumbnail_405x552.jpg')
  // }

  const initSwiper = () => {
    if (swiperInstance.value) return
    if (!swiperEl.value) return

    const swiperParams = {
      slidesPerView: 'auto',
      initialSlide: 0,
      observer: true,
      observeParents: true,
      spaceBetween: 8,
      dir: appConfigs?.cssRight ? 'rtl' : 'ltr',
      a11y: {
        paginationBulletMessage: 'Go to slide {{index}}',
      },
    }
    Object.assign(swiperEl.value, swiperParams)
    try {
      (swiperEl.value as any).initialize()
      swiperInstance.value = (swiperEl.value as any).swiper
    } catch (error) {
      console.error('swiper init error:', error)
    }
  }

  onUnmounted(() => {
    swiperInstance.value = null
  })

  defineExpose({
    getStart,
  })
  </script>
  <style lang="less">
  .gallery {
    position: relative;
    margin-right: -12px;
    &__swiper-container {
      overflow: hidden;
    }

    .s-swiper-slide_11 {
      width: 218px;
    }
    .s-swiper-slide_45 {
      width: 174px;
    }
    .s-swiper-slide_1316 {
      width: 177px;
    }

    &__swiper-slide {
      width: 164px;
      &.middle-size {
        width: 60px;
        height: 80px;
      }

      > img {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__loading {
    .S-loading__cur-page-mask {
      background-color: transparent;
    }
  }
  </style>
