<template>
  <Drawer
    :no-header="true"
    type="full"
    :visible="show"
    append-to-body
    class="customeow-drawer"
  >
    <CustomeowBox
      v-bind="$attrs"
      :link="link"
      :env="env"
      @mount="handleMount"
      @error="handleError"
      @confirm="handleConfirm"
      @close="handleClose"
    />
  </Drawer>
</template>
<script>
/* eslint-disable */
import CustomeowBox from './customeowBox/index.vue'
import { Drawer } from '@shein/sui-mobile';

export default {
  name: 'CustomeowDrawer',
  components: {
    CustomeowBox,
    Drawer
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      required: true,
    },
    onClose: {
      type: Function,
      default: () => {}
    },
    onConfirm: {
      type: Function,
      default: () => {}
    },
    onError: {
      type: Function,
      default: () => {}
    },
  },
  data() {
    return {
      env: 'prod',
    }
  },
  created() {
    this.checkError()
  },
  methods: {
    handleMount() {
      this.isMount = true
      clearTimeout(this.checkErrorTimer)
    },
    checkError() {
      this.checkErrorTimer = setTimeout(() => {
        if (!this.isMount) {
          this.$emit('error')
        }
      }, 30000)
    },
    handleError() {
      clearTimeout(this.checkErrorTimer)
      this.onError()
    },
    handleConfirm(data) {
      this.onConfirm(data)
    },
    handleClose() {
      this.onClose()
    },
  }
}
</script>
<style lang="less" scoped>
.customeow-drawer {
  /deep/ .S-drawer__body {
    height: 100%;
  }
}
</style>
