import type { ExtractPropTypes, PropType } from 'vue'
import type { DS_QuickAddCartStandard } from '../../../../types'

export const BGalleryProps = {
  index: {
    type: Number as PropType<number>,
    default: 0,
  },
  adaLevel: {
    type: Number as PropType<number>,
    default: 0,
  },
  fixedRatio: {
    type: String as PropType<string>,
    default: '4-5',
  },
  lazyImage: {
    type: String as PropType<string>,
    default: '',
  },
  loading: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  isSwitchColor: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  middleSizeMode: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  language: {
    type: Object as PropType<Record<string, any>>,
    default: () => ({}),
  },
  imgs: {
    type: Array as PropType<DS_QuickAddCartStandard.GoodsImages[]>,
    default: () => [],
  },
  openImgModal: {
    type: Function as PropType<(index: number) => void>,
    default: () => ({}),
  },
}

export type TBGalleryProps = ExtractPropTypes<typeof BGalleryProps>
