<template>
  <div
    class="productEstimatedNoThresholdNew"
    @click.stop="handleClick"
  >
    <div class="productEstimatedNoThresholdNew__left">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.4995 1.46656L15.4387 8.64496L2.8121 12.1541L1.12457 4.84151L13.4995 1.46656Z"
          fill="url(#paint0_linear_81_16466)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.26392 10.4494L1.13892 12.0812V15.512H16.3264V4.82446H1.13892V8.81764L2.26392 10.4494Z"
          fill="url(#paint1_linear_81_16466)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.86401 13.0748L10.0429 7.07446H11.8642L7.68532 13.0748H5.86401ZM6.86434 9.0747C7.41663 9.0747 7.86434 8.62699 7.86434 8.0747C7.86434 7.52242 7.41663 7.0747 6.86434 7.0747C6.31206 7.0747 5.86434 7.52242 5.86434 8.0747C5.86434 8.62699 6.31206 9.0747 6.86434 9.0747ZM10.8644 13.0748C11.4167 13.0748 11.8644 12.627 11.8644 12.0748C11.8644 11.5225 11.4167 11.0748 10.8644 11.0748C10.3122 11.0748 9.86444 11.5225 9.86444 12.0748C9.86444 12.627 10.3122 13.0748 10.8644 13.0748Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_81_16466"
            x1="3"
            y1="10"
            x2="2.36447"
            y2="4.75896"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FF8A69" />
            <stop
              offset="1"
              stop-color="#FA6338"
            />
          </linearGradient>
          <linearGradient
            id="paint1_linear_81_16466"
            x1="6.49244"
            y1="4.98166"
            x2="8.9215"
            y2="11.3712"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FF8A69" />
            <stop
              offset="1"
              stop-color="#FA6338"
            />
          </linearGradient>
        </defs>
      </svg>
      <p
        :class="{
          'productEstimatedNoThresholdNew__left-text': true,
          'productEstimatedNoThresholdNew__left-text_ar': cssRight
        }"
        v-html="textHtml"
      ></p>
    </div>
    <div
      v-if="isShowAddMore"
      class="productEstimatedNoThresholdNew__right"
    >
      <p>{{ language.SHEIN_KEY_PWA_15091 }}</p>
      <Icon
        name="sui_icon_more_right_12px"
        size="12px"
        :is-rotate="cssRight"
      />
    </div>
  </div>
</template>


<script setup name="EstimatedNoThresholdNew">
/**
 *
 * 必须以 EstiamtedTag 为容器引入！需要容器埋点
 *
*/
import { ref, onMounted, computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { template, CountDown, debounce } from '@shein/common-function'

const props = defineProps({
  estimatedInfo: {
    type: Object,
    default: () => ({}),
  },
})

const timer = ref(null)
const initCountDown = () => {
  const endTime = props.estimatedInfo.noThresholdFloor.endTime
  if(!endTime) return
  timer.value = new CountDown()
  const beginTime = endTime * 1000 - new Date().getTime()
  if (beginTime <= 0) {
    beginTime == 0
  }
  timer.value?.start({
    seconds: beginTime / 1000,
    endFunc: debounce({
      func: () => {
        location.reload()
      },
    }),
  })
}

onMounted(() => {
  initCountDown()
})

const noThresholdFloor = computed(() => props.estimatedInfo.noThresholdFloor)
const isShowAddMore = computed(() => noThresholdFloor.value.isShowAddMore)
const language = computed(() => props.estimatedInfo.otherInfo.language)
const cssRight = computed(() => props.estimatedInfo.otherInfo.GB_cssRight)
const textHtml = computed(() => {
  const isEstimatedNothreShowTypeNew1 = noThresholdFloor.value.EstimatedNothreShowType == 'New1'
  let new1Lan = language.value.SHEIN_KEY_PWA_27341 || 'this item only {0} if add-ons {1} more'
  let new2Lan = language.value.SHEIN_KEY_PWA_27342 || 'add-ons {1} more to enjoy {2} off'
  if (cssRight.value) {
    new1Lan = new1Lan.replace(/\s/g, '&nbsp;')
    new2Lan = new2Lan.replace(/\s/g, '&nbsp;')
  }
  const strWithSpan = (str) => `<span>${str}</span>`
  let value = noThresholdFloor.value.value || {}
  let text = isEstimatedNothreShowTypeNew1 ?
    template(strWithSpan(value.amountWithSymbol), strWithSpan(noThresholdFloor.value?.needPrice?.amountWithSymbol), new1Lan) :
    template('', strWithSpan(noThresholdFloor.value?.needPrice?.amountWithSymbol), strWithSpan(noThresholdFloor.value.discountPercent), new2Lan)
  if (noThresholdFloor.value?.endTime && timer?.value?.timeObj) {
    const { H, M, S } = timer?.value?.timeObj || {}
    text += (',' + strWithSpan(`&nbsp;${H}:${M}:${S}`))
  }
  return text
})

const emits = defineEmits(['clickEstimatedTag'])
const handleClick = () => {
  if (isShowAddMore.value) emits('clickEstimatedTag')
}

</script>

<style lang="less">
.productEstimatedNoThresholdNew {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(269.95deg, #FFF6F3 0.04%, #FFE6DD 99.96%);
  min-height: .8rem;
  padding: 6px 12px;

  &__left {
    display: flex;
    align-items: center;
    margin-right: .32rem;

    &>svg {
      display: block;
      min-width: 18px;
      margin-right: .2133rem;
    }
  }

  &__left-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: #000;
    text-align: left;

    &>span {
      color: #FA6338;
      font-weight: 600;
    }
  }

  &__left-text_ar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__right {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    color: #000;
  }
}
</style>
