<template>
  <div
    v-if="measureContent?.length"
    class="bsc-size-measure-guide">
    <div class="bsc-size-measure-guide__header">
      <span>{{ title }}</span>
      <Icon
        name="sui_icon_more_down_16px_2"
        width="4.267vw"
        height="4.267vw"
        color="#959595"
        class="bsc-size-measure-guide__icon"
        :class="{
          reverse: isCollapse
        }"
        @click="handleCollapseChange(!isCollapse)" />
    </div>
    <!-- Measure Body -->
    <div
      v-show="isCollapse"
      class="bsc-size-measure-guide__body">
      <div
        v-if="Array.isArray(measureContent)"
        class="bsc-size-measure-guide__content">
        <div
          v-for="(item) in measureContent"
          :key="item.name"
          class="bsc-size-measure-guide__desc">
          <h6><span>{{ item.sort }}</span>. {{ item.name }}</h6>
          <p>{{ item.description }}</p>
        </div>
      </div>
      <div class="bsc-size-measure-guide__image">
        <img :src="measureImage?.replace(/^http:/, '')" />
      </div>
    </div>
  </div>
</template>


<script setup name="BSizeMeasureGuide" lang="ts">
import { defineProps, ref, watch } from 'vue'
import { DS_SizeGuide } from '@shein-aidc/bs-size-guide-mobile-vue2'
import { DS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile-vue2'
import { Icon } from '@shein-aidc/icon-vue2'
import { computed } from 'vue'
const props = defineProps<{
  language: DS_SizeGuide.LANGUAGE
  sizeInfoDesc?: DS_QuickAddCartStandard.SizeInfoDesc
  sizeTemplate?: DS_QuickAddCartStandard.SizeTemplate
  /**
   * 尺码展示优先级
   */
  sizePriorityAbt?: string
  sizeGuidePlanA?: boolean,
  tab: string
  value?: boolean
}>()
const emit = defineEmits<{
  (event: 'input', value: boolean): void
}>()
const isCollapse = ref(props.value || false)
const title = computed(() => props.tab === 'Product' ? props.language?.SHEIN_KEY_PWA_18087 : props.language?.SHEIN_KEY_PWA_17564)
const measureContent = computed(() => {
  if (props.tab === 'Product') {
    return props.sizeTemplate?.description_multi
  } else if (props.tab === 'Base') {
    return props.sizeInfoDesc?.basicAttribute?.attribute_info?.map((item) => {
      return {
        name: item.name,
        description: item.desc,
        sort: item.sort,
      }
    })
  }
  return []
})

const measureImage = computed(() => {
  if (props.tab === 'Product') {
    return props.sizeTemplate?.image_url
  } else if (props.tab === 'Base') {
    return props.sizeInfoDesc?.basicAttribute?.image_url
  }
  return ''
})

watch(
  () => props.value,
  val => {
    isCollapse.value = val !== undefined ? val: false
  },
)

const handleCollapseChange = (value: boolean) => {
  isCollapse.value = value
  emit('input', value)
}
</script>


<style lang="less">
.bsc-size-measure-guide {
  margin-bottom: 0.22rem;
  background: #fff;

  &__header {
    border-top: 1px solid #e5e5e5;
    height: 11.6vw;
    line-height: 11.6vw;
    font-size: 3.2vw;
    font-weight: 400;
    color: #222222;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__icon {
    transition: 0.3s all ease;
    &.reverse {
      transform: rotateZ(180deg);
    }
  }

  &__body {
    display: flex;
    padding-bottom: 3.2vw;
  }

  &__content {
    width: 53.33333vw;
    margin-right: 3.2vw;
    padding-bottom: 3.2vw;
    transition: 0.3s all ease;

    .bsc-size-measure-guide__desc {
      margin-bottom: 0.32rem;
      font-size: 12px;

      >h6 {
        font-weight: 700;
        margin-bottom: 0.16rem;
        text-transform: capitalize;
        font-size: 12px;
      }

      >p {
        word-wrap: break-word;
        font-size: 12px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__image {
    flex: 1;

    img {
      width: 100%;
    }
  }

}
</style>
