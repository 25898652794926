import { ref, nextTick } from 'vue'
import { DialogComponent, DialogOptions } from '../components/Dialog/types'

/**
 * dialog驱动hook
 * @param component
 */
export function useDialog() {

    const dialogComponent = ref<DialogComponent | undefined>(undefined)
    const dialogRef = ref<any>(null)



    function loadDialog(component: DialogComponent) {
        dialogComponent.value = component
        return {
            open: (options: DialogOptions) => {
                nextTick(() => {
                    dialogRef.value?.open(options)
                })
            },
            close: () => {
                nextTick(() => {
                    dialogRef.value?.close()
                })
            },
        }
    }
    return {
        dialogComponent,
        loadDialog,
        dialogRef,
    }
}
