import { computed } from 'vue'
import { useModules } from '../modules/useModules'

/**
 * 主销售属性数据模型
 */
export const useGoodsImgs = () => {
	// 基础数据
	const { productInfo, fsAbt, staticSaleAttr } = useModules()

	/** 商品主图处理 */
	const goodsImgs = computed(() => {
		const { detailSpuPic = {} } = fsAbt?.value || {}
    // 商品图片排序 spu图-skc图-sku图-细节图
    const { allColorDetailImages, currentSkcImgInfo, goods_id, cccDetailsTemplate } = productInfo?.value || {}
    const { skcImages = [], skuImages = {}, spuImages = [], detailImages = [] } = currentSkcImgInfo || {}
    const skuList = staticSaleAttr?.value?.multiLevelSaleAttribute?.sku_list || []

    const imageArr = [] as { origin_image: string, is_spu_image?: boolean, is_skc_image?: boolean, is_sku_image?: boolean, is_relate_skc_image?: boolean, sku_code?: string }[]
    // 是否展示spu 图片
    const showSpuPic = detailSpuPic?.param?.spuPic === 'show'

    // spu 图片处理
    if (showSpuPic && !!spuImages?.length) {
      spuImages?.forEach(spu => {
        spu && imageArr.push({
          origin_image: spu,
          is_spu_image: true,
        })
      })
    }

    // skc 图片处理
    if (skcImages?.length) {
      skcImages?.forEach(skc => {
        skc && imageArr.push({
          origin_image: skc,
          is_skc_image: true,
        })
      })
    }

    // spu 图片数量 > 1时候 取spu 下面所有skc
    if (showSpuPic && spuImages?.length > 1) {
      Object.keys(allColorDetailImages).forEach(skcKey => {
        const skcImageArr = allColorDetailImages?.[skcKey] || null
        const skcImage = skcImageArr?.[0]?.origin_image || null
        if (skcImage && skcKey !== goods_id ) {
          imageArr.push({
            origin_image: skcImage,
            is_relate_skc_image: true,
            is_skc_image: true,
          })
        }
      })
    }

    // sku 图片处理 以 sku_list 顺序为准排序
    if (skuImages && !!skuList?.length) {
      skuList?.forEach(item => {
        const skuImage = skuImages[item.sku_code] || null
        skuImage && imageArr.push({
          origin_image: skuImage,
          sku_code: item.sku_code,
          is_sku_image: true,
        })
      })
    }

    // 是否展示细节图
    const isShowDetail = cccDetailsTemplate?.content?.[0]?.detailImg === '1'
    if (isShowDetail && !!detailImages?.length) {
      detailImages?.forEach(detail => {
        const image_url = detail?.image_url || null
        image_url && imageArr.push({
          origin_image: image_url,
        })
      })
    }

    return imageArr
	})

	return {
		goodsImgs,
	}
}
