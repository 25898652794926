<template>
  <div
    class="RecommendRetailPrice"
    :style="{
      'color': color,
      'width': width
    }"
  >
    <p class="RecommendRetailPrice__value">
      {{ recommendRetailPrice.value }}
    </p>
    <div
      v-if="recommendRetailPrice.discountPercent"
      class="RecommendRetailPrice__discountPercent"
      :style="{'border-color': discountPercentBorderColor}"
    >
      {{ recommendRetailPrice.discountPercent }}
    </div>
  </div>
</template>

<script setup name="RecommendRetailPrice">
import { computed, watch, inject } from 'vue'
const { triggerNotice } = inject('analysisInstance')

const props = defineProps({
  recommendRetailPrice: {
    type: Object,
    default: () => ({}),
  },
  isFloorType: {
    type: Boolean,
    default: false,
  },
  isQuickAdd: {
    type: Boolean,
    default: false,
  },
})

const color = computed(() => props.recommendRetailPrice?.style?.color || '')
const width = computed(() => props.recommendRetailPrice?.style?.width || '')
const discountPercentBorderColor = computed(() => props.recommendRetailPrice.style?.discountPercentBorderColor || '')

watch(() => props.recommendRetailPrice.skuCode, (val) => {
  if (!val) return
  if (props.isFloorType) return
  triggerNotice({
    id: 'expose_brand_sale_block.comp_quick-add-cart-standard',
    extraData: {
      recommend_retail_price: props.recommendRetailPrice.usdAmountWithSymbol,
      location: props.isQuickAdd ? 'popup' : 'page',
    },
  })
}, {
  immediate: true,
})

</script>

<style lang="less">
.RecommendRetailPrice {

  display: flex;
  align-items: center;

  height: .48rem;

  white-space: nowrap;
  font-size: 10px;
  font-weight: 400;
  line-height: 11.93px;
  color: #959595;
  text-align: left;

  &__discountPercent {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 .0533rem;
    height: .3733rem;
    border: .0134rem solid #959595;
    margin-left: .0533rem;
    border-radius: .0533rem;
  }
}
</style>
