import { DS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile-vue2'
import { ESTIMATED_STYLE_TYPES } from './utils'

export function useMainPrice(params: {
  skcPriceInfo?: DS_QuickAddCartStandard.ProductPriceInfo
  skuPriceInfo?: DS_QuickAddCartStandard.SkuPriceInfo
  estimatedInfo: any
  isShowPrice: boolean
}) {
  const { skcPriceInfo, skuPriceInfo, estimatedInfo, isShowPrice } = params
  if (estimatedInfo?.value &&[ESTIMATED_STYLE_TYPES.NEW, ESTIMATED_STYLE_TYPES.NEW_AND_NO_THRESHOLD_FLOOR].includes(estimatedInfo?.value.styleType)) {
    return estimatedInfo.value.amountWithSymbol as string
  }
  if (isShowPrice) {
    return skcPriceInfo?.showPrice?.amountWithSymbol
  }
  return skuPriceInfo ? skuPriceInfo.salePrice?.amountWithSymbol : skcPriceInfo?.salePrice?.amountWithSymbol
}
