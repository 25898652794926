<template>
  <div
    v-if="tipsshow"
    class="goods-size__undersize-deviation-tips"
    aria-hidden="true"
    @click="openSizeGuideFromSizeTips"
  >
    <template v-if="showTip">
      {{ tipText }}
      <!-- TODO: dir="ltr" -->
      <span>{{ heelHeightRangeTips }}</span>
    </template>
    <Icon
      v-if="tipType === 'fit'"
      v-expose="getShowSizeTipsTextAnalysis({ id: 'expose_size_guide.comp_sub-sales-attr', data: { from: from === 'popup' ? 8 : 7 } })"
      name="sui_icon_more_right_16px_2"
      size="16px"
    />
    <span
      v-if="tipType === 'tts'"
      v-expose="getShowSizeTipsTextAnalysis({ id: 'expose_size_type.comp_sub-sales-attr', isStrong: true })"
      class="goods-size__undersize-deviation-tipsstrong"
      :class="{
        'new-line': showTip,
      }"
      @click.stop="openReviews"
    >
      <span class="tipsstrong-span"><Icon
        class="like-icon"
        name="sui_icon_like_12px"
        size="12px" />{{ trueToSizePercent }}</span>
      {{ showTrueToSizeStrongText }}
      <Icon
        v-if="from !== 'popup'"
        name="sui_icon_more_right_12px_2"
        size="12px" />
    </span>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import { template } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue2'

import analysisInstance from '../utils/useAnalysisSdk.js'
const { vExpose, triggerNotice } = analysisInstance || {}

const props = defineProps({
  language: {
    type: Object,
    default: () => ({}),
  },
  sizeTipsInfo: {
    type: Object,
    default: () => ({}),
  },
  from: {
    type: String,
    default: '',
  },
  handleOpenSizeGuideFn: {
    type: Function,
    default: () => (() => {}),
  },
})
const tipsshow = computed(() => props.sizeTipsInfo?.tipsshow || false)
const heelHeightRangeTips = computed(() => props.sizeTipsInfo?.heelHeightRangeTips || '')
const tipType = computed(() => props.sizeTipsInfo?.tipType || '')
const tipText = computed(() => props.sizeTipsInfo?.tipText || '')
const trueToSizePercent = computed(() => props.sizeTipsInfo?.trueToSizePercent || '')
const showTrueToSizeStrongText = computed(() => template('', props.language?.SHEIN_KEY_PWA_27419 || ''))
const showTip = computed(() => tipText.value || heelHeightRangeTips.value)
const openSizeGuideFromSizeTips = () => {
  if (tipType.value !== 'fit') return
  props.handleOpenSizeGuideFn?.(undefined, props.from === 'popup' ? 'cart_tips' : 'detail_tips', props.from === 'popup' ? 8 : 7)
}
const openReviews = () => {
  if (props.from === 'popup') return
  location.hash += 'comment-all'
  triggerNotice({
    id: 'click_size_type.comp_sub-sales-attr',
    extraData: {
      type: 3,
    },
  })
}
const getShowSizeTipsTextAnalysis = ({ id, isStrong }) => {
  const type = isStrong ? 3 : props.sizeTipsInfo?.isLargeSizeTips ? 1 : 2
  return {
    id,
    prefix: 'comp_sub-sales-attr',
    data: {
      type,
    },
  }
}
</script>
