<script lang="jsx">
import { Button } from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'
export default {
  name: 'SizeFeedBackSuccessPanel',
  functional: true,
  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    selectedSize: {
      type: Object,
      default: () => ({}),
    },
  },
  render(h, { listeners, props }) {
    const { language, selectedSize } = props
    // TODO: similarRecommendText 像是不传了
    // const { similarRecommendText = '', selectedSize = {} } = props.params
    const similarContent = () =>
      selectedSize.type == 0 ? (
        <div>
          {/* <p>{similarRecommendText}</p> */}
          <Button
            type={['default', 'H36PX']}
            v-on:click={listeners['handleShowSimilar']}
          >
            {language.SHEIN_KEY_PWA_21191}
          </Button>
        </div>
      ) : null

    return (
      <div class="success-box">
        <Icon
          name="sui_icon_success_75px"
          size="75px"
          style={{
            color: '#198055',
            marginBottom: '8.53333vw',
          }}
        />
        <h2>{language.SHEIN_KEY_PWA_21092}</h2>
        <h3>{language.SHEIN_KEY_PWA_21093}</h3>
        { similarContent() }
      </div>
    )
  },
}
</script>
