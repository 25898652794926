import type { ExtractPropTypes, PropType } from 'vue'
import type { DS_QuickAddCartStandard } from '../../../../types'

export const BQuickAtmosphereFlowProps = {
  // 是否自动播放
  autoPlay: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  fsAbt: {
    type: Object as PropType<Record<string, any>>,
    default: () => ({}),
  },
  location: {
    type: String as PropType<string>,
    default: '',
  },
  productInfo: {
    type: Object as PropType<DS_QuickAddCartStandard.ProductInfo>,
    default: () => ({}),
  },
  hotNews: {
    type: Array as PropType<Pick<DS_QuickAddCartStandard.ProductLabel, 'hotNews'>>,
    default: () => ([]),
  },
  willSoldOutTipsInfo: {
    type: Object as PropType<Record<string, any>>,
    default: () => ({}),
  },
}

export type TBQuickAtmosphereFlowProps = ExtractPropTypes<typeof BQuickAtmosphereFlowProps>
