export const queryString = {
    extract: function (str) {
      return str.split('?')[1] || ''
    },
    parse: function (str) {
      // Create an object with no prototype
      // https://github.com/sindresorhus/query-string/issues/47
      const ret = Object.create(null)

      if (typeof str !== 'string') {
        return ret
      }

      str = str.trim().replace(/^(\?|#|&)/, '')

      if (!str) {
        return ret
      }

      str.split('&').forEach(function (param) {
        const parts = param.replace(/\+/g, ' ').split('=')
        // Firefox (pre 40) decodes `%3D` to `=`
        // https://github.com/sindresorhus/query-string/pull/37
        let key = parts.shift()
        let val = parts.length > 0 ? parts.join('=') : undefined

        key = decodeURIComponent(key)

        // missing `=` should be `null`:
        // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
        val = val === undefined ? null : decodeURIComponent(val)

        if (ret[key] === undefined) {
          ret[key] = val
        } else if (Array.isArray(ret[key])) {
          ret[key].push(val)
        } else {
          ret[key] = [ret[key], val]
        }
      })
      return ret
    },
    stringify: function (obj) {
      return obj
        ? Object.keys(obj)
          .sort()
          .map(function (key) {
            const val = obj[key]

            if (val === undefined) {
              return ''
            }

            if (val === null) {
              return key
            }

            if (Array.isArray(val)) {
              const result = []

              val.slice().forEach(function (val2) {
                if (val2 === undefined) {
                  return
                }

                if (val2 === null) {
                  result.push(key)
                } else {
                  result.push(key + '=' + val2)
                }
              })

              return result.join('&')
            }

            return key + '=' + val
          })
          .filter(function (x) {
            return x.length > 0
          })
          .join('&')
        : ''
    },
  }
