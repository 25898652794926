<template>
  <div
    v-tap="{
      id: 'click_find_my_shade.comp_main-sales-attr'
    }"
    v-expose="{
      id: 'expose_find_my_shade.comp_main-sales-attr'
    }"
    class="bs-main-sales-attr__shade"
    @click="onHandleClickFindMyShade"
  >
    {{ text }}
    <Icon
      name="sui_icon_more_right_16px"
      size="16px"
      color="#959595"
    />
  </div>
</template>

<script name="BMainSalesAttrShade" setup lang="ts">
import { Icon } from '@shein-aidc/icon-vue2'
import { inject } from 'vue'
import { type DS_MainSalesAttr, AS_MainSalesAttr } from '../../types'

const analysisInstance = inject<AS_MainSalesAttr.AnalysisInstance>('analysisInstance')
const { vTap, vExpose } = analysisInstance || {}

interface MainSalesAttrShadeProps {
  text: string
}

withDefaults(defineProps<MainSalesAttrShadeProps>(), {
  text: '',
})

/** 打开 findMyShade 弹窗 */
const onHandleClickFindMyShade = () => {
// TODO
}

</script>

<style lang="less" scoped>
@vw: 375/100vw;

.bs-main-sales-attr {
  &__shade {
    display: flex;
    align-items: center;
    border-radius: 2 / @vw;
    padding: 8 / @vw 12 / @vw;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(---sui_color_gray_dark3, #767676);
    background: var(---sui_color_gray_weak2, #F6F6F6);
    margin-bottom: 8 / @vw;

    & > span {
        margin-left: auto;
    }
  }
}
</style>
