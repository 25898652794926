<template>
  <div class="productEstimatedTagNewCountdown">
    <Icon
      name="sui_icon_time_12px"
      size="12px"
      color="#FA6338"
      class="productEstimatedTagNewCountdown__icon"
    />
    <p>{{ hms.H }}</p>
    <p class="productEstimatedTagNewCountdown__colon">:</p>
    <p>{{ hms.M }}</p>
    <p class="productEstimatedTagNewCountdown__colon">:</p>
    <p>{{ hms.S }}</p>
  </div>
</template>

<script setup name="EstimatedTagNewCountdown">
/**
 * 到手价标签新样式右侧倒计时
*/
import { onMounted, ref, computed } from 'vue'
import { CountDown, debounce } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue2'

const props = defineProps({
  endTime: {
    type: String,
    default: '',
  },
})

const timer = ref(null)

const hms = computed(() => {
  let { H, M, S } = timer.value?.timeObj || {}
  return { H, M, S }
})

const initTimer = () => {
  timer.value = new CountDown()
  let beginTime = props.endTime * 1000 - new Date().getTime()
  if (beginTime <= 0) {
    beginTime == 0
  }
  // 初始化倒计时
  timer.value?.start({
    seconds: beginTime / 1000,
    endFunc: debounce({
      func: () => {
        location.reload()
      },
    }),
  })
}

onMounted(() => {
  initTimer()
})

</script>

<style lang="less">
.productEstimatedTagNewCountdown {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  color: #FA6338;
  font-size: 11px;
  white-space: nowrap;
  &__icon {
    margin-right: 3px;
  }
  &__colon {
    margin-right: 1px;
  }
}

</style>
