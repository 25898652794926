// logger.ts

const isDebug = (typeof window !== 'undefined' && window.localStorage.getItem('__quickAddCart__') === '1') || process.env.NODE_ENV === 'development'
const COLOR_RESET = '\x1b[0m'
const COLOR_CYAN = '\x1b[36m'
const PREFIX = `${COLOR_CYAN}[QuickAddCart]${COLOR_RESET}`
class Logger {
    static log(...args: any[]) {
      if (isDebug) {
        console.log(PREFIX, ...args)
      }
    }

    static warn(...args: any[]) {
      if (isDebug) {
        console.warn(PREFIX, ...args)
      }
    }

    static error(...args: any[]) {
      if (isDebug) {
        console.error(PREFIX, ...args)
      }
    }

    static info(...args: any[]) {
      if (isDebug) {
        console.info(PREFIX, ...args)
      }
    }

    static debug(...args: any[]) {
      if (isDebug) {
        console.debug(PREFIX, ...args)
      }
    }
  }


export default Logger
