export function getImageRatio(imgSrc = ''): Promise<string> {
  return new Promise(resolve => {
    const image = new Image()
    imgSrc = imgSrc.replace(/(_square)|(_squfix)/ig, '')
    if(imgSrc) {
        image.src = imgSrc
        image.onload = e => {
          const width = (e.target as any)?.width
          const height = (e.target as any)?.height
          const ratioString = closestRatio(width / height)
          resolve(ratioString)
        }
        image.onerror = () => {
            resolve(closestRatio(0))
        }
    }else {
        resolve(closestRatio(0))
    }
  })
}

/**
 * 根据真实图片的比率映射比例字符串
 * 注意这里是 宽/高 的比率
 * */
export function closestRatio(imgRatio = 0) {
  const imgRatioMaps = [
    { key: 3 / 4, value: '3-4' },
    { key: 4 / 5, value: '4-5' },
    { key: 13 / 16, value: '13-16' },
    { key: 1 / 1, value: '1-1' },
  ]

  const closestObj = imgRatioMaps.reduce(function (prev, curr) {
    return Math.abs(curr.key - imgRatio) < Math.abs(prev.key - imgRatio) ? curr : prev
  })

  return closestObj.value
}
