<template>
  <!-- 最外层包一个 block 解决低版本safari高度坍塌 -->
  <div>
    <div
      class="quickAddName"
      tabindex="0"
      role="text"
      :aria-label="goodsName">
      <p class="quickAddName__name-left">
        {{ goodsName }}
      </p>
      <div
        v-if="needClickToDetail"
        class="quickAddName__jump"
        tabindex="0"
        role="link"
        :aria-label="language?.SHEIN_KEY_PWA_15423"
        @click="handleClick"
      >
        {{ language?.SHEIN_KEY_PWA_15423 }}
        <span class="quickAddName__more">
          <Icon
            name="sui_icon_more_right_12px"
            size="10px"
            :is-rotate="gbCommonInfo?.cssRight"
            class="quickAddName__more-icon" />
        </span>
      </div>
    </div>
  </div>
</template>

<script setup name="GoodsName" lang="ts">
import { AS_QuickAddCartStandard, DS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile-vue2'
import { Icon } from '@shein-aidc/icon-vue2'
import { replaceHrefSpecChar, stringifyQueryString } from '@shein/common-function'
import { inject, defineProps, computed, PropType } from 'vue'
import { LANGUAGE_KEY } from '../hook/useQuickAddCart'
import { Libs } from '@shein-aidc/bs-sdk-libs-manager'

const props = defineProps({
  needClickToDetail: {
    type: Boolean,
    default: () => true,
  },
  detailUrlExtendParam: {
    type: Object as PropType<DS_QuickAddCartStandard.DetailUrlExtendParam>,
    default: () => ({}),
  },
  analysisConfig: {
    type: Object as PropType<DS_QuickAddCartStandard.AnalysisConfig>,
    default: () => ({}),
  },
  mallCode: {
    type: String,
    default: '',
  },
  language: {
    type: Object as PropType<Record<LANGUAGE_KEY , string | undefined>>,
    default: () => ({}),
  },
  productInfo: {
    type: Object as PropType<DS_QuickAddCartStandard.ProductInfo>,
    default: () => ({}),
  },
  priceInfo: {
    type: Object,
    default: () => ({}),
  },
  saleAttr: {
    type: Object as PropType<DS_QuickAddCartStandard.SaleAttr>,
    default: () => ({}),
  },
  gbCommonInfo: {
    type: Object as PropType<Libs.Envs>,
    default: () => ({}),
  },
  skuInfo: {
    type: Object,
    default: () => ({}),
  },
  fromOneClickPay: {
    type: Boolean,
    default: false,
  },
  fixedRatio: {
    type: String,
    default: '',
  },
  billNo: {
    type: String,
    default: '',
  },
})

const emits = defineEmits(['click-details'])
const analysisInstance = inject<AS_QuickAddCartStandard.AnalysisInstance>('analysisInstance')

const goodsName = computed(() => props.productInfo.goods_name)

const getGoodsUrl = (name = '', id) => {
  name = name == '-' || !name ? 'product' : name
  const url = '/' + name.replace('/', '-').split(' ').join('-') + '-p-' + id + '.html'
  return replaceHrefSpecChar({ href: url })
}

const getDetailUrlParams = () => {
  const { billNo, fromOneClickPay, fixedRatio, detailUrlExtendParam, mallCode } = props
  const result = {
    mallCode,
    imgRatio: fixedRatio,
    ...detailUrlExtendParam,
    billNo: fromOneClickPay ? billNo : undefined,
  }
  return result
}

const handleClick = () => {
  let {
    productInfo,
    gbCommonInfo,
    skuInfo,
    fromOneClickPay,
    priceInfo,
    saleAttr,
    analysisConfig,
  } = props
  let { goods_id, goods_sn, productRelationID } = productInfo
  let { salePrice, retailPrice } = priceInfo
  let { langPath } = gbCommonInfo || {}
  const params = getDetailUrlParams()
  const newUrl = `${langPath}${getGoodsUrl(
    saleAttr?.mainSaleAttribute?.info?.find(item => item.goods_id == goods_id)?.goods_url_name,
    goods_id,
  )}?${stringifyQueryString({ queryObj: params })}`

  let analysisDetail = {
    goods_id,
    goods_sn,
    productRelationID,
    salePrice,
    retailPrice,
  }
  if (analysisConfig?.sa?.from?.indexOf('get_the_look') > -1) {
    analysisInstance?.triggerNotice({
      id: 'click_get_the_look_details.comp_quick-add-cart-standard',
      data: {
        goods_id,
      },
    })
  } else if (analysisConfig?.from == 'theOptions') {
    analysisInstance?.triggerNotice({
      id: 'click_other_options_details.comp_quick-add-cart-standard',
      data: {
        goods_id,
      },
    })
  } else if (analysisConfig?.from === 'activityQuickAdd') {
    const { biClickReport, mod = {}, content_list = '' } = analysisConfig
    biClickReport &&
      biClickReport({
        mod, // 公共字段
        content_type: 35,
        content_list,
        index: analysisConfig?.index,
      })
  } else if (analysisConfig?.from == 'cartRechavingGoods') {
    analysisInstance?.triggerNotice({
      id: 'click_goods_list_popup_details.comp_quick-add-cart-standard',
      data: {
        quickShip: skuInfo?.isSupportQuickShip,
        detail: analysisDetail,
        currentData: { mallCode: props.mallCode }, // @Ming mo
        index: analysisConfig?.index,
        activity_from: 'fill_it_with_no_empty',
        style: 'popup',
        target: analysisConfig?.sourceTarget || null,
      },
    })
  } else if (analysisConfig?.from == 'newoutfit') {
    analysisInstance?.triggerNotice({
      id: 'click_goods_list_popup_details.comp_quick-add-cart-standard',
      data: {
        quickShip: skuInfo?.isSupportQuickShip,
        detail: analysisDetail,
        currentData: { mallCode: props.mallCode },
        index: analysisConfig?.index,
        activity_from: 'newoutfit',
        style: 'popup',
        target: analysisConfig?.sourceTarget || null,
        ...analysisConfig?.sa,
      },
    })
  } else {
    const { style, sa, isAttrFold = '0' } = analysisConfig
    const popupFrom = analysisConfig?.sa?.activity_from || analysisConfig?.sa?.from || analysisConfig?.from || ''
    analysisInstance?.triggerNotice({
      id: 'click_goods_list_popup_details.comp_quick-add-cart-standard',
      data: {
        quickShip: skuInfo?.isSupportQuickShip,
        detail: analysisDetail,
        currentData: { mallCode: props.mallCode },
        index: analysisConfig?.index,
        activity_from: fromOneClickPay ? 'one_tap_pay' : popupFrom,
        style: style || 'popup',
        feed_type: sa?.feed_type || '',
        is_attr_fold: isAttrFold,
        target: analysisConfig?.sourceTarget || null,
      },
    })
  }
  emits('click-details', newUrl) // @Ming mo
}
</script>

<style lang="less">
.quickAddName {
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__name-left {
    font-size: 12px;
    line-height: 14px;
    color: #333;

    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  &__jump {
    font-size: 12px;
    color: #2d68a8;
    text-decoration: none;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  &__more {
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: rgba(45, 104, 168, 0.1);
    margin-left: 4px;
  }
}
</style>
