<template>
  <div class="productAfterPayInfo">
    <div
      class="productAfterPayInfo__content"
      :da-event-click="isMulti ? '1-6-1-15' : ''"
      da-expose-code="product-intro-afterpays-code"
      @click.stop="showHideDrawer"
    >
      <p
        class="productAfterPayInfo__desc"
        da-event-expose="1-6-1-66"
        :data-payment-code="content.paymentCode"
      >
        {{ content.text }}
      </p>
      <div class="productAfterPayInfo__right">
        <img
          v-if="content.logoUrl"
          class="productAfterPayInfo__logo"
          :src="content.logoUrl"
        />
        <i
          v-if="content.jumpUrl && !isMulti"
          class="iconfont icon-px icon-_px productAfterPayInfo__qa"
          da-event-click="1-6-1-26"
          @click="clickJumpUrl(content.jumpUrl)"
        ></i>
        <i
          v-if="isMulti"
          :class="{
            'iconfont icon-down_new productAfterPayInfo__more': true,
            'productAfterPayInfo__more_open': showDrawer,
          }"
        ></i>
      </div>
    </div>
    <SDrawer
      :visible="showDrawer"
      @close-from-mask="showHideDrawer"
      custom-class="productAfterPayInfo__drawer">
      <div class="productAfterPayInfo__drawerTitle">
        {{ drawerTitle }}
        <i
          class="iconfont icon-close productAfterPayInfo__drawerClose"
          @click="showHideDrawer"
        ></i>
      </div>
      <div
        v-for="v in list" :key="v.id"
        class="productAfterPayInfo__drawerItem"
        :data-payment-code="v.paymentCode"
      >
        <p class="productAfterPayInfo__drawerItemText">{{ v.text }}</p>
        <img
          v-if="v.logoUrl"
          class="productAfterPayInfo__drawerItemLogo"
          :src="v.logoUrl"
          @click="clickJumpUrl(v.jumpUrl)"
        />
      </div>
      <div class="productAfterPayInfo__drawerHolder"></div>
    </SDrawer>
  </div>
</template>

<script setup name="AfterPayInfo">
import { ref, computed } from 'vue'
import { Drawer as SDrawer } from '@shein/sui-mobile'
// import { markPoint } from 'public/src/services/mark/index.js'

const props = defineProps({
  afterPayInfo: {
    type: Object,
    default: () => ({})
  }
})

const content = computed(() => props.afterPayInfo.content)

const list = computed(() => props.afterPayInfo.list)

const isMulti = computed(() => list.value.length > 1)

const drawerTitle = computed(() => props.afterPayInfo.drawerTitle)

const showDrawer = ref(false)

const showHideDrawer = () => {
  if (!isMulti.value) return
  showDrawer.value = !showDrawer.value
}

const clickJumpUrl = (url) => {
  if (!url) return
  // markPoint('toNextPageClick', 'public')
  window.location.href = url
}

</script>

<style lang="less">
.productAfterPayInfo {

  margin-bottom: .1067rem;

  &__content {
    display: flex;
    align-items: flex-end;
  }

  &__desc {
    color: #959595;
    font-size: 11px;
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__logo {
    height: 14px;
    vertical-align: baseline;
  }

  &__qa {
    margin-left: .1067rem;
    font-size: 11px;
    color: #767676;
  }
  &__more {
    margin-left: .1067rem;
    font-size: 11px;
    color: #999;
  }
  &__more_open {
    transform: rotate(180deg);
  }


  &__drawer {
    background-color: #fff;
  }
  &__drawerHolder {
    height: 1.28rem;
  }

  &__drawerTitle {
    color: #999;
    font-size: 14px;
    text-align: center;
    padding: 0.306rem 0;
    position: relative;
    border-bottom: 1px solid rgba(229, 229, 229, 0.83);
  }

  &__drawerClose {
    position: absolute;
    top: .12rem;
    right: .18rem;
    font-size: 12px;
    color: #999;
  }

  &__drawerItem {
    padding-left: .32rem;
    margin-top: .32rem;
    display: flex;
  }
  &__drawerItem {
    padding-left: .32rem;
    margin-top: .32rem;
    display: flex;
  }
  &__drawerItemText {
    color: #999;
  }
  &__drawerItemLogo {
    height: .3733rem;
    transform: translateY(.08rem)
  }
}
</style>