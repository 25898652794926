<template>
  <div class="bs-main-sales-attr__header">
    <span class="bs-main-sales-attr__header-title">
      {{ data.colorBlockText }}
    </span>
    <!-- 大图切换 -->
    <span
      v-if="config.needLargeImageMode"
      v-tap="{
        id: 'click_mainattr_switch_image.comp_main-sales-attr',
        data: { image_tp: config.showLargeImage ? 'large' : 'small' }
      }"
      class="bs-main-sales-attr__header-switch"
      @click="emits('onChange',!config.showLargeImage)"
    >
      <Icon
        :name="config.showLargeImage ? 'sui_icon_view_small_12px' : 'sui_icon_view_large_12px'"
        size="12px"
        is-responsive-name
        color="#2D68A8"
      />
      <span>{{ switchBtnLang }}</span>
    </span>
  </div>
</template>

<script name="BMainSalesAttrHeader" setup lang="ts">
import { Icon } from '@shein-aidc/icon-vue2'
import { computed, defineEmits, inject } from 'vue'
import { type DS_MainSalesAttr, AS_MainSalesAttr } from '../../types'

const analysisInstance = inject<AS_MainSalesAttr.AnalysisInstance>('analysisInstance')
const { vTap, vExpose } = analysisInstance || {}

interface HeaderData {
  colorBlockText: string;
  language?: DS_MainSalesAttr.LanguagesType;
}

interface HeaderConfig {
  needLargeImageMode: boolean;
  showLargeImage: boolean;
}

interface BGoodColorTitleProps {
  /** 标题数据 */
  data: HeaderData
  /** 标题配置 */
  config: HeaderConfig,
}

const props = withDefaults(defineProps<BGoodColorTitleProps>(), {
  data: () => ({
    colorBlockText: '',
  }),
  config: () => ({
    needLargeImageMode: false,
    showLargeImage: false,
  }),
})

const switchBtnLang = computed(() => {
  return props.config.showLargeImage
        ? props.data.language?.SHEIN_KEY_PWA_23119
        : props.data.language?.SHEIN_KEY_PWA_23118
})

const emits = defineEmits<{
  (event: 'onChange', params: boolean): void
}>()

</script>

<style lang="less" scoped>
.bs-main-sales-attr {
  &__header {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__header-title {
    color: #333;
  }

  &__header-switch {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2d68a8;

    span {
      margin-left: .106667rem;
    }
  }
}
</style>
