<script lang="jsx">
import SizeTitle from './SizeTitle.vue'
/**
 * @props
 *  @param { String } title 尺码标题
 *  @param { Boolean } bold 标题加粗
 *  @param { Boolean } active 选中状态
 *  @param { value } value 按钮现实内容
 */
export default {
  name: 'SizeClickToBuy',
  functional: true,
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    bold: {
      type: Boolean,
      default: false,
    },
    handleClickToBuy: {
      type: Function,
      default: () => {},
    },
  },
  render(h, { props = {} }) {
    const { active, title, bold, value, handleClickToBuy } = props

    return (
      <div class="goods-size__wrapper">
        <SizeTitle bold={bold} title={title}/>
        <div>
          <ul class="goods-size__sizes choose-size">
            <li
              class={[
                'j-select-to-buy',
                'goods-size__sizes-item',
                { 'size-active': active },
              ]}
              v-on:click={handleClickToBuy}
            >
              <span class="capsule-box">
                { value }
                <i class="iconfont icon-price_px_- capsule"></i>
              </span>
            </li>
          </ul>
        </div>
      </div>
    )
  },
}
</script>
