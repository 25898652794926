/** eslint-disiable */
// @ts-nocheck
/**
 * 获取sa goodsList
 * wiki.pageId=904754267
*/
export const getSaGoodsList = ({
    goodsId = '', sku = '', spu = '', index = 0, pageNum = 1, request_ext = '', operId = 0,
    recMark = '', extraMark = '', otherExtMark = '', otherDExtMark = '', extMarks = '', price = 0, originPrice = 0, reducePrice = 0, soldoutMark = 0, soldOutSign = 0,
    historicalPrice = '', followPrice = '', visitorLabel = '', labelCarousels = '',
    locateLabels = '', discountSpecial = '', discountTabs = '', cccTspBadges = '', promotionInfo = '', exclusivePrice = '', lable = '', seriesOrBrand = '', sellingPointLabel = '', videoIcon = '', saleAttr = '', spuImg = '', mallCode = '', priceCut = '', promotionId = '', typeId = '', brand = '', brandCode = '', quickShip = '', localShip = '', bestDeal = '', followLabel = '', promoLabel = '', userBehaviorLabel = '', userPreferencesLabel = '', userCommentKeywordsLabel = '', showNewuseronlyPriceLabel = '', beltLabel = '', rankingList = '', decisionAttr = '', findSimilarMode = '', estimatedPriceInfo = '', showLabelHot = '', labelLooping = '', serviceLabel = '', titleLabel = '', extraTag = '', salesLabel = '', saleCnt = '', showSellerLimitedLabel = '', hypernymGoods, isABPrice, oneClickPayAddBad = '', trendWordId = '', trendProductSelectId = '', trendShopCode = '', addCartBtnType = '', recommendInfo = '', lookType = '', lookName = '', realLeftStock = '', maskLayer = '',
    realtimePosition = '', realtimeFromCateId = '', realtimeFromGoodsId = '',
    useFrom = '', trendIsReviewBubble = '',
  }  = {}) => {
    const prices = [`pri_${price}`, `pri_${originPrice}`]
    if (estimatedPriceInfo[1]) prices.push(`estimated_pri_${estimatedPriceInfo[1]}`)
    let list = [
      goodsId, 
      sku, 
      spu, 
      Number(index) + 1, 
      pageNum, 
      operId, 
      recMark, 
      extraMark, 
      prices.join('|')
    ]
    // 其他标识 - S
    const otherMark = []
    if (reducePrice) otherMark.push(`reduced_${reducePrice}`)
    if (soldoutMark) otherMark.push('stock_soldout')
    if (!soldoutMark && soldOutSign) otherMark.push('show_sold_out') // 列表页的售罄标识另外定义
    if (promotionInfo) otherMark.push('show_promotioninfo')
    if (exclusivePrice) otherMark.push('show_s3vipprice')
    if (lable) otherMark.push(lable)
    if (seriesOrBrand) otherMark.push(seriesOrBrand)
    if (otherExtMark) otherMark.push(otherExtMark)
    if (sellingPointLabel) otherMark.push(sellingPointLabel)
    if (cccTspBadges) otherMark.push(cccTspBadges)
    if (videoIcon) otherMark.push(videoIcon)
    if (spuImg) otherMark.push(spuImg)
    if (priceCut) otherMark.push(`reduced_${priceCut}`)
    if (localShip) {
      otherMark.push('localship_1')
    } else if (quickShip) {
      otherMark.push(quickShip)
    }
    if (bestDeal) otherMark.push('show_label_Betention')
    if (promoLabel) otherMark.push('show_icon')
    if (userBehaviorLabel) otherMark.push(userBehaviorLabel)
    if (userPreferencesLabel) otherMark.push(userPreferencesLabel)
    if (userCommentKeywordsLabel) otherMark.push(userCommentKeywordsLabel)
    if (showNewuseronlyPriceLabel) otherMark.push(showNewuseronlyPriceLabel)
    if (beltLabel) otherMark.push(beltLabel)
    if (followLabel) otherMark.push('show_same_label')
    if (decisionAttr) otherMark.push(decisionAttr)
    if (locateLabels) otherMark.push(locateLabels)
    if (discountSpecial) otherMark.push(discountSpecial)
    if (discountTabs) otherMark.push(discountTabs)
    if (historicalPrice) otherMark.push(historicalPrice) 
    if (followPrice) otherMark.push(followPrice)
    if (visitorLabel) otherMark.push(`Recent_Viewers_${visitorLabel}_viewed`)
    if (labelCarousels) otherMark.push(labelCarousels)
    if (rankingList) otherMark.push(rankingList)
    if (estimatedPriceInfo[0]) otherMark.push(`estimated_price_${estimatedPriceInfo[0]}`)
    if (isABPrice) otherMark.push('estimated_price_3')
    if (findSimilarMode) otherMark.push(findSimilarMode)
    if (showLabelHot) otherMark.push(showLabelHot)
    if (labelLooping) otherMark.push(labelLooping)
    if (serviceLabel) otherMark.push(serviceLabel)
    if (titleLabel) otherMark.push(titleLabel) 
    if (extraTag) otherMark.push(extraTag)
    if (salesLabel) otherMark.push(salesLabel)
    if (saleCnt) otherMark.push(`sale_cnt_${saleCnt}`)
    if (showSellerLimitedLabel) otherMark.push(showSellerLimitedLabel)
    if (hypernymGoods) otherMark.push('search_query_upper')
    if (oneClickPayAddBad) otherMark.push(oneClickPayAddBad)
    if (trendWordId) otherMark.push(`trend_tag_${trendWordId}`)
    if (trendProductSelectId) otherMark.push(`product_select_id_${trendProductSelectId}`)
    if (trendShopCode) otherMark.push(`trend_shop_code_${trendShopCode}`)
    if (extMarks) otherMark.push(extMarks)
    if (addCartBtnType) otherMark.push(addCartBtnType) 
    if (lookType) otherMark.push(lookType) 
    if (lookName) otherMark.push(lookName) 
    if (realLeftStock) otherMark.push(realLeftStock)
    if (maskLayer) otherMark.push(maskLayer)
  
    // 趋势频道评论标签
    if (useFrom === 'trend' && +trendIsReviewBubble) otherMark.push('is_review_bubble')
  
    // 其他标识 - E
    if(recommendInfo) list.push(recommendInfo) // 点推商品用
    if(realtimePosition) list.push(realtimePosition) // 点推商品用
    if(realtimeFromCateId) list.push(realtimeFromCateId) // 点推商品用
    if(realtimeFromGoodsId) list.push(realtimeFromGoodsId) // 点推商品用
  
    // 销售或促销属性 - S
    const salePromotion = []
    salePromotion.push(saleAttr || '') // 销售属性
    if (promotionId && typeId) {
      const is_brand = brand ? 1 : 0
      promotionId.forEach((pId, index) => {
        salePromotion.push(['sale', typeId[index], pId, is_brand, brandCode].join('_')) // 促销属性
      })
    }
  
    // 销售或促销属性 - E
    list.push(otherMark.join('|'), salePromotion.join('|'))
  
    list.push(`${mallCode ? 'mall_' + mallCode : '-'}`) // mall
    
    list.push([otherDExtMark, request_ext].filter(_ => _).join('|')) // FR-9925
    return list.join('`')
  }
  


 export  const getLocateLabelsMark = (data) => {
    // 角标
    const labelConfig = {
      UPPER_LEFT_LABEL: 'lu_corner_mark',
      UPPER_RIGHT_LABEL: 'ru_corner_mark',
      LOWER_LEFT_LABEL: 'll_corner_mark',
      LOWER_RIGHT_LABEL: 'rl_corner_mark',
    }
    let iconContent = null
    data && Object.keys(data).forEach(key => {
      if (data[key].locateLabelId) {
        const isCustom = data[key].materialValueKey === 'custom'
        const keyStr = isCustom ? `custom_${data[key].subscriptType}` : data[key].materialValueKey
        if (iconContent === null) {
          iconContent = `${labelConfig[key]}_${keyStr}_${data[key].locateLabelId}`
        } else {
          iconContent += `|${labelConfig[key]}_${keyStr}_${data[key].locateLabelId}`
        }
      }
    })
    return iconContent
  }