<template>
  <!-- 最外层包一个 block 解决低版本safari高度坍塌 -->
  <div>
    <div
      id="priceContainer"
      class="productPriceContainer"
      :style="priceContainerStyle"
    >
      <FlashBeltWraper
        v-if="flashBeltWraperInfo.isFlash"
        :flash-belt-wraper-info="flashBeltWraperInfo"
      >
        <Price
          :main-price-info="mainPriceInfo"
          :discount-info="discountInfo"
          :estimated-info="estimatedInfo"
          :suggested-sale-price-info="suggestedSalePriceInfo"
          :exclusive-info="exclusiveInfo"
          :recommend-retail-price="recommendRetailPrice"
          @clickEstimatedTag="clickEstimatedTag"
        />
      </FlashBeltWraper>
      <template v-else>
        <Price
          :main-price-info="mainPriceInfo"
          :discount-info="discountInfo"
          :estimated-info="estimatedInfo"
          :suggested-sale-price-info="suggestedSalePriceInfo"
          :exclusive-info="exclusiveInfo"
          :recommend-retail-price="recommendRetailPrice"
          @clickEstimatedTag="clickEstimatedTag"
        />
        <EstimatedNewCountdown
          v-if="estimatedNewEndTime"
          :end-time="estimatedNewEndTime" />
      </template>
    </div>
  </div>
</template>

<script setup name="PriceContainer">
/**
 * @file
 * price 容器 引入 vuex 数据传入 price 组件
*/
import { computed, toRefs } from 'vue'
import usePrice from '../../hook/usePrice'
import Price from './Price/index.vue'
import FlashBeltWraper from './FlashBeltWraper/index.vue'
import EstimatedNewCountdown from './Price/components/EstimatedTag/EstimatedNewCountdown.vue'

const emits = defineEmits(['clickEstimatedTag'])

const props = defineProps({
  featureConfig: {
    type: Object,
    default: () => ({}),
  },
  skuInfo: {
    type: Object,
    default: () => ({}),
  },
  skuList: {
    type: Array,
    default: () => ([]),
  },
  isSoldOut: {
    type: Boolean,
    default: false,
  },
  currentStock: {
    type: Number,
    default: 1,
  },
})

const {
  featureConfig,
  skuInfo,
  skuList,
  isSoldOut,
  currentStock,
} = toRefs(props)

const {
  mainPriceInfo,
  discountInfo,
  estimatedInfo,
  suggestedSalePriceInfo,
  exclusiveInfo,
  flashBeltWraperInfo,
  estimatedNewEndTime,
  recommendRetailPrice,
} = usePrice({
  featureConfig,
  skuInfo,
  skuList,
  isSoldOut,
  currentStock,
})

const clickEstimatedTag = () => emits('clickEstimatedTag')

const priceContainerStyle = computed(() => {
  if (props.flashBeltWraperInfo?.isFlash) return {}
  return {
    'display': 'flex',
    'justify-content': 'space-between',
  }
})

</script>

<style lang="less">
.productPriceContainer {
  width: 100%;
  margin-bottom: .2133rem;
}
</style>
