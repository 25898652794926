<template>
  <div :class="['size-feedback', { 'no-border-bottom': status !== 'ready' }]">
    <Drawer
      :visible="drawerShow"
      direction="btt"
      :show-close="true"
      :append-to-body="true"
      @close-from-icon="sizeFeedBackClose"
      @close-from-mask="sizeFeedBackClose"
    >
      <!-- TODO: style="color: #333" -->
      <template #top>
        {{ title }}
        <div class="size-feedback_body">
          <SizeFeedBackSelectPanel
            v-if="status === 'ready'"
            :language="language"
            :status="status"
            :locationSelectShow="locationSelectShow"
            :feedBackSizeList="feedBackSizeList"
            :selectedSize="selectedSize"
            :country="selectCountry"
            @onOpenLocationBtn="onOpenLocationBtn"
            @selectSize="selectSize"
            @handleSubmit="handleSubmit"
          />
          <SizeFeedBackSuccessPanel
            v-else
            :language="language"
            :selectedSize="selectedSize"
            @handleShowSimilar="handleShowSimilar"
          />
        </div>
      </template>
    </Drawer>
  </div>
</template>

<script setup>
import { ref, defineComponent, defineProps, defineExpose, computed, onMounted } from 'vue'
import { template } from '@shein/common-function'
// TODO: 请求原子接口拿数据
// import itemSer from 'public/src/services/goodsItemInfo/interfaceSer'

import { Drawer } from '@shein/sui-mobile'
import SizeFeedBackSelectPanel from './SizeFeedBackSelectPanel'
import SizeFeedBackSuccessPanel from './SizeFeedBackSuccessPanel'
// TODO: 通过传参的方式打开
// import SimilarInstance from 'public/src/pages/common/similar_product_modal/index.js'

import analysisInstance from '../../utils/useAnalysisSdk'
const { triggerNotice } = analysisInstance || {}

defineComponent({
  name: 'SizeFeedBackDrawer',
})
const props = defineProps({
  language: {
    type: Object,
    default: () => ({}),
  },
  asyncContentReady: {
    type: Boolean,
    default: false,
  },
  countrySizeDataList: {
    type: Array,
    default: () => [],
  },
  notShowDefaultSizeText: {
    type: Boolean,
    default: false,
  },
  goodsId: {
    type: String,
    default: '',
  },
  country: {
    type: String,
    default: '',
  },
  localClick: {
    type: Function,
    default: () => {},
  },
  handleOpenFindSimilar: {
    type: Function,
    default: () => {},
  },
})
// ready: 待提交 | success: 提交成功
const status = ref('ready')
const drawerShow = ref(false)
const showSimilarFlag = ref(false)
const selectedSize = ref({
  key: '',
  type: -1,
})
const selectCountry = ref('')
const title = computed(() => status.value === 'ready' ? props.language.SHEIN_KEY_PWA_21088 : null)
const locationSelectShow = computed(() => props.countrySizeDataList?.length > 1)
const curLocalSizeData = computed(() => (props.countrySizeDataList?.find(item => item.countryCode === selectCountry.value)))
const hasLocalSizeOptions = computed(() => !!curLocalSizeData.value?.sizeLocalSizeDataList?.length)
const feedBackSizeList = computed(() => {
  // 默认拿第一条的数据
  const { sizeLocalSizeDataList } = curLocalSizeData.value || {}
  const hasLocalSize = !!sizeLocalSizeDataList?.length
  const localSizeData = sizeLocalSizeDataList || props.countrySizeDataList?.[0]?.sizeLocalSizeDataList || []
  return localSizeData.map((item, index) => {
    const { size, localSize } = item
    if (index === 0) {
      return {
        text: template(size, props.language?.SHEIN_KEY_PWA_21412 || ''),
        key: 'lt',
        type: 1,
      }
    }
    if (index === localSizeData.length - 1) {
      return {
        text: template(size, props.language.SHEIN_KEY_PWA_21413),
        key: 'gt',
        type: 1,
      }
    }
    const text = (hasLocalSize && !props.notShowDefaultSizeText && localSize) ? `${localSize} (${size})` : size
    return {
      text,
      key: size,
      localSize,
      type: 0,
    }
  })
})

const sizeFeedBackClose = () => {
  drawerShow.value = false
  if (status.value === 'ready') {
    return triggerNotice({
      id: 'click_goods_detail_feedback_close.comp_sub-sales-attr',
    })
  }
  if (!showSimilarFlag.value) {
    return triggerNotice({
      id: 'click_goods_detail_success_close.comp_sub-sales-attr',
    })
  }
}
const onSizeFeedbackBtnClick = () => {
  if (status.value === 'success') {
    status.value = 'ready'
    selectedSize.value = {
      key: '',
      type: -1,
    }
  }
  // 存在国家的时候再取默认国家
  if (props?.country) {
    const curLocalSizeData = props.countrySizeDataList?.find?.(item => item.isDefaultDisplay === '1') || {}
    selectCountry.value = curLocalSizeData?.countryCode || ''
  }else {
    selectCountry.value = ''
  }
  drawerShow.value = true
}
const onOpenLocationBtn = () => {
  props.localClick?.({
    changeSize: (country) => {
      selectCountry.value = country || ''
    },
  })
  // 上报埋点
  triggerNotice({ id: 'click_goods_detail_feedback_locations.comp_sub-sales-attr' })
}
const getReportSizeValue = (item) => {
  const { key, localSize } = item
  if (!key) {
    return ''
  } else if (key === 'lt') {
    return '1'
  } else if (key === 'gt') {
    return '2'
  }
  return `${key}${localSize ? `(${localSize})` : ''}`
}
const selectSize = (size) => {
  const { type, key, localSize } = size
  selectedSize.value = {
    key,
    type,
    localSize,
  }
  // 上报埋点
  const reportSize = getReportSizeValue(size)
  triggerNotice({
    id: 'click_goods_detail_feedback_size.comp_sub-sales-attr',
    extraData: {
      goods_id: props.goodsId,
      size: reportSize,
    },
  })
}
const handleSubmit = () => {
  const { key } = selectedSize.value
  if (!key) {
    return
  }
  status.value = 'success'
  // 上报埋点
  triggerNotice({
    id: 'click_goods_detail_feedback_submit.comp_sub-sales-attr',
    extraData: {
      goods_id: props.goodsId,
      location: hasLocalSizeOptions.value ? selectCountry.value : 'Local',
      size: getReportSizeValue(selectedSize.value),
    },
  })
}
const handleShowSimilar = async () => {
  const { key } = selectedSize.value
  // TODO: 扩展引入
  // const res = await itemSer.getAtomicInfo({
  //   goods: [{ goods_id: props.goodsId }],
  //   fields: {
  //     baseInfo: true,
  //     stock: true, // 库存
  //     mallInfo: true,
  //     promotion: true,
  //     prices: true,
  //     skuPrices: true,
  //     vipDiscountPrices: true,
  //     realTimePricesWithPromotion: true,
  //     sheinClubPromotionInfo: true,
  //     newFlashPromotion: true,
  //     estimatedPrice: true,
  //   },
  // })
  // const goodsInfo = res?.[0] || {}
  // SimilarInstance.showModal(goodsInfo,
  //   {
  //     similarFrom: 'out_of_stock',
  //     filterSize: key,
  //   })
  await props.handleOpenFindSimilar?.({
    goods_id: props.goodsId,
    filterSize: key,
  })
  showSimilarFlag.value = true
  drawerShow.value = false
  // 上报埋点
  triggerNotice({
    id: 'click_goods_detail_feedback_findsimilar.comp_sub-sales-attr',
    extraData: {
      goods_id: props.goodsId,
      size: key,
    },
  })
}
onMounted(() => {
  if (props.asyncContentReady) {
    onSizeFeedbackBtnClick()
  }
})

defineExpose({
  onSizeFeedbackBtnClick,
})
</script>

<style lang="less">
.size-feedback {
  position: relative;
  margin-bottom: 3.2vw;
  .ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &_title {
    color: #666;
    line-height: 1;
    span {
      color: #2D68A8;
      cursor: pointer;
    }
  }

  &_body {
    min-height: 77.86667vw;
    position: relative;

    .tips-band {
      padding: 0 0 0 3.2vw !important; /* stylelint-disable-line declaration-no-important */
      text-align: left;
      background-color: #f6f6f6;
      color: #767676;
      font-size: 12px;
    }

    .region-select-box {
      width: 100%;
      display: inline-flex;
      align-items: center;
      padding: 0 3.2vw !important; /* stylelint-disable-line declaration-no-important */
      position: relative;

      p {
        // width: 100% !important;
        font-size: 14px;
        color: #222;
        text-align: left;
      }
      &__local {
        display: inline-flex;
        margin-left: 1.6vw;
        padding: 0 1.6vw;
        align-items: center;
        right: 3.2vw;
        height: 5.2vw;
        background: #F6F6F6;
        color: #222;
        font-size: 12px;
        border-radius: 5.4054vw;
      }
    }

    .size-select-box {
      padding: 1.06667vw 3.2vw 3.2vw;
    }

    .size-select-list {
      display: flex;
      flex-wrap: wrap;

      li {
        display: inline-block;
        max-width: 100%;
        height: 7.46667vw;
        line-height: 7.2vw;
        padding: 0 3.33333vw;
        margin-right: 3.2vw;
        margin-bottom: 2.13333vw;
        border: 1px solid #e5e5e5;
        border-radius: 3.73333vw;
        text-align: center;
        font-size: 14px;
        color: #222;
        &.active {
          border: 1px solid #222;
          color: #222;
        }
      }
    }

    .submit-box {
      padding: 2.13333vw 3.2vw;
    }

    .success-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 77.86667vw;

      h2 {
        font-size: 16px;
        line-height: 5.06667vw;
        margin-bottom: 1.06667vw;
      }
      h3 {
        color: #222;
        font-size: 14px;
        line-height: 4.53333vw;
        margin-bottom: 8.53333vw;
        font-weight: 400;
      }

      p {
        color: #767676;
        font-size: 14px;
        text-align: center;
        line-height: 1.25;
        margin-bottom: 3.2vw;
        padding: 0 6.4vw;
      }
      .S-button {
        margin-bottom: 9.6vw;
      }
    }
  }

  &.no-border-bottom {
    .S-drawer__header {
      border-bottom: none;
    }
  }
}
</style>
