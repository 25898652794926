<script lang="tsx" name="InactiveButton">
export default {
    functional: true,
    props: {
        mainBtnFullWidth: {
            type: Boolean,
        },
        language: {
            type: Object,
        },
        btnStyle: {
            type: Number,
        },
        ariaLabel: {
            type: String,
        },
        ada: {
            type: Object,
        },
        btnText: {
            type: String,
        },
    },
    /* eslint-disable-next-line */
    render(h, { props }) {
        const { mainBtnFullWidth, ariaLabel, btnText } = props
        const cls = 'mshe-btn-black add-btn disabled'
        return (
            <button
                class={{ [cls]: true, 'fullWidth': mainBtnFullWidth }}
                role="text"
                aria-label={ariaLabel}
            >
                {btnText}
            </button>
        )
    },
}
</script>
