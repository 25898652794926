<template>
  <div
    class="productEstimatedTagNew"
    @click.stop="emits('clickEstimatedTag')"
  >
    <div
      class="productEstimatedTagNew__main"
      :class="{ 'productEstimatedTagNew_percentTypeStyleWrap': isWrap }"
      :style="{
        background: strategyStyle.mainBackground,
        color: strategyStyle.mainColor
      }"
    >
      <div class="productEstimatedTagNew__container">
        <div class="productEstimatedTagNew__estimated">
          {{ estimatedInfo.text }}
          <div
            v-show="!isWrap"
            class="productEstimatedTagNew__left-arrow"
            :class="{ 'productEstimatedTagNew__left-arrow_cssRight': cssRight }"
          >
            <svg
              width="5px"
              height="5px"
              viewBox="0 0 5 5"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <g
                id="页面-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd">
                <path
                  id="形状结合"
                  d="M5,5 L0,5 L0.216889622,4.99538049 C2.87769329,4.88181811 5,2.6887547 5,-2.22044605e-16 L5,5 Z"
                  :fill="strategyStyle.mainBackground" />
              </g>
            </svg>
          </div>
          <div
            v-show="isWrap"
            class="productEstimatedTagNew__top-arrow"
            :style="{
              background: strategyStyle.mainBackground,
            }"
          ></div>
        </div>
        <div
          class="productEstimatedTagNew__percent"
          :style="{
            background: strategyStyle.percentBackground,
            color: strategyStyle.percentColor
          }"
        >
          {{ discountPercent }}
        </div>
      </div>
    </div>
    <p
      class="productEstimatedTagNew__retail"
      :style="estimatedInfo.retailPriceStyle"
    >{{ retailPrice }}</p>
    <i
      v-if="hasLink"
      class="suiiconfont sui_icon_more_right_12px_2 price-new-estimated__sale-icon"
      :style="estimatedInfo.retailPriceStyle"
    ></i>
  </div>
</template>

<script setup name="EstimatedTagNew">
import { computed } from 'vue'

const emits = defineEmits(['clickEstimatedTag'])

const props = defineProps({
  estimatedInfo: {
    type: Object,
    default: () => ({}),
  },
  isWrap: {
    type: Boolean,
    default: false,
  },
})

const strategyStyle = computed(() => {
  // 四种样式
  // 常规(同特殊闪购)、付费会员、腰带Old、腰带New、品牌闪购腰带
  const ESTIMATED_TAG_NEW_STYLE = {
    normal: {
      mainColor: '#FA6338',
      mainBackground: '#FFECE9',
      percentColor: '#FA6338',
      percentBackground: '#FFD9CE',
    },
    paidVip: {
      mainColor: '#873C00',
      mainBackground: '#FFECE9',
      percentColor: '#873C00',
      percentBackground: '#FFD9CE',
    },
    flash: {
      mainColor: '#FA6338',
      mainBackground: '#FFECE9',
      percentColor: '#FFF',
      percentBackground: '#FA6338',
    },
    brandFlash: {
      mainColor: '#FF3D00',
      mainBackground: '#FFE3D9',
      percentColor: '#FF3D00',
      percentBackground: '#FFF',
    },
    quickAddNoramaBrandlFlash: {
      mainColor: '#FF3D00',
      mainBackground: '#FFF',
      percentColor: '#FFF',
      percentBackground: '#F54811',
    },
    s3Brown: {
      mainColor: '#806208',
      mainBackground: '#F1E7D4',
      percentColor: '#806208',
      percentBackground: '#E9DABA',
    },
  }
  const { normal, paidVip, flash, brandFlash, quickAddNoramaBrandlFlash, s3Brown } = ESTIMATED_TAG_NEW_STYLE

  const {
    sheinClubPromotionInfo,
    isPaidUser,
    isQuickAdd,
    isFlash,
    isNormalFlash,
    isBrandFlash,
    exclusiveInfo,
  } = props.estimatedInfo.otherInfo
  if (exclusiveInfo) return s3Brown
  if (isFlash) {
    if (isQuickAdd) {
      if (isBrandFlash) return quickAddNoramaBrandlFlash
      if (isNormalFlash) return quickAddNoramaBrandlFlash
    }
    if (isBrandFlash) return brandFlash
    return flash
  }
  if (!!sheinClubPromotionInfo && isPaidUser) return paidVip
  return normal
})

const cssRight = computed(() => props.estimatedInfo.otherInfo.cssRight)

const discountPercent = computed(() => props.estimatedInfo.discountPercent)

const retailPrice = computed(() => props.estimatedInfo.retailPrice?.amountWithSymbol)
const hasLink = computed(() => props.estimatedInfo.otherInfo.hasLink)

</script>

<style lang="less">
.productEstimatedTagNew {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  &__main {
    font-size: 10px;
    line-height: 1;
    height: 16px;
    border-radius: 2px 2px 2px 0;
    margin-right: .2133rem;
  }

  &__retail {
    color: #666;
    font-size: 10px;
    text-decoration: line-through;
  }

  &_percentTypeStyleWrap {
    border-radius: 2px;
  }

  &__container {
    display: flex;
  }

  &__estimated {
    height: 16px;
    line-height: 12px;
    padding: 0 3px;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 2px;
  }

  &__percent {
    color: #fff;
    padding: 1px 2px 1px;
    height: 16px;
    display: flex;
    align-items: center;
    border-radius: 0px 2px 2px 0;
    font-weight: 700;
    direction: ltr;
  }

  &__left-arrow {
    position: absolute;
    left: -5px;
    bottom: 0;
    width: 5px;
    height: 5px;

    &>svg {
      display: block;
    }
  }

  &__left-arrow_cssRight {
    transform: scalex(-1);
  }

  &__top-arrow {
    position: absolute;
    width: 9px;
    height: 5px;
    top: -3px;
    left: calc(50% + 9px);
    clip-path: polygon(50% 0, 0% 100%, 100% 100%);
  }
}
</style>
