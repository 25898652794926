<template>
  <div
    class="bsc-model-intro"
    aria-hidden="true"
  >
    <div class="bsc-model-intro__image">
      <img
        :data-src="thumbnailImg(modelInfo.image, '220x')"
        data-design-width="100"
        class="lazy lazyload"
      />
    </div>
    <div class="bsc-model-intro__content">
      <div class="bsc-model-intro__size">
        <span class="key">{{ modelInfo.size ? language.SHEIN_KEY_PWA_20169 + ":" : "" }}</span>
        <span class="value">{{ modelInfo.size }}{{ modelLocalSize }}</span>
      </div>
      <div class="bsc-model-intro__desc">
        <template v-for="(value, key) in modelDesc">
          <div
            v-if="value"
            :key="key"
            class="bsc-model-intro__desc-item">
            <span>{{ getLabel(key) }}</span>
            <span class="value">{{ value }}</span>
          </div>
        </template>

      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { DS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile-vue2'
import { transformImg } from '@shein/common-function'
import { defineComponent, PropType } from 'vue'
import { DS_SizeGuide } from '@shein-aidc/bs-size-guide-mobile-vue2'
import { computed } from 'vue'


export default defineComponent({
  name: 'ModelIntro',
  props: {
    sizeUnit: {
      type: String as PropType<'cm' | 'inch'>,
      default: 'cm',
    },
    language: {
      type: Object as PropType<DS_SizeGuide.LANGUAGE>,
      default: () => ({}),
    },
    localSizes: {
      type: Object as PropType<Record<string, DS_QuickAddCartStandard.AttrValueLocalSize[] | undefined>>,
      default: () => { },
    },
    modelInfo: {
      type: Object as PropType<DS_QuickAddCartStandard.ModelsInfo>,
      default: () => ({}),
    },
    currentLocalCountry: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    function thumbnailImg(fileUrl, size) {
      let fileName = transformImg({ img: fileUrl })
      let suffix = `.${fileName.split('.').pop()}`
      return fileName.replace(suffix, `_thumbnail_${size}${suffix}`)
    }

    const modelLocalSize = computed(() => {
      if (props.currentLocalCountry === 'default' || !props.localSizes || !props.modelInfo?.size) return ''
      const localSize = props.localSizes?.[props.modelInfo?.size]
      const size = localSize?.find((item) => item.country_code === props.currentLocalCountry)?.attr_local_size_value || ''
      if(size) {
        return `(${props.currentLocalCountry}: ${size})`
      }else {
        return ''
      }
    })

    const modelDesc = computed(() => {
      if(props.sizeUnit === 'cm') {
        return props.modelInfo?.attrcm
      }else if(props.sizeUnit === 'inch') {
        return props.modelInfo?.attrinch
      } else {
        return props.modelInfo?.attr
      }
    })

    function getLabel(key: string) {
      if(key === 'Height') {
        return props.language.SHEIN_KEY_PWA_14993
      } else if(key === 'Bust') {
        return props.language.SHEIN_KEY_PWA_14996
      } else if(key === 'Waist') {
        return props.language.SHEIN_KEY_PWA_14997
      } else if(key === 'Hip') {
        return props.language.SHEIN_KEY_PWA_14998
      } else if(key === 'BallGirth') {
        return props.language.SHEIN_KEY_PWA_18331
      } else if(key === 'FootLength') {
        return props.language.SHEIN_KEY_PWA_18330
      } else {
        return key+':'
      }
    }

    return { props, thumbnailImg, modelLocalSize, modelDesc, getLabel }
  },
})

</script>

<style lang="less">
.bsc-model-intro {
  padding: 3.2vw;
  background: #fff;
  display: flex;
  align-items: center;

  &__image {
    margin-right: 3.2vw;
    overflow: hidden;
    width: 12vw;
    height: 12vw;
    border-radius: 50%;
    flex-shrink: 0;

    img {
      width: 100%;
      display: inline-block;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    min-height: 12vw;
  }

  &__size {
    width: 100%;
    line-height: 1.2;
    color: #222;
    font-size: 3.733vw;
    margin-bottom: 1.067vw;
    .key {
      margin-right: 4px;
    }
  }

  &__desc {
    font-size: 3.2vw;
    color: #767676;
    display: flex;
    flex-wrap: wrap;
    &-item {
      white-space: nowrap;
      margin-right: 4px;
      .value {
        margin-left: 2px;
      }
      &:after {
        content: ",";
      }
      &:last-child {
        margin-right: 0;
        &:after {
          content: "";
        }
      }
    }
  }
}
</style>
