<template>
  <div
    class="productPrice"
    :style="calcContainerStyle"
  >
    <div
      ref="mainPriceRef"
      class="productPrice__main"
      :style="{ color: mainPriceInfo.color }"
      v-html="mainPriceInfo.valueHtml"
    >
    </div>

    <template v-if="exclusiveInfo && exclusiveInfo.isS3newpriceAbtNew">
      <div class="productPrice__gap-2px"></div>
      <S3TagNew :style="bigSmallWordSizeS3TagNewStyle" />
    </template>

    <div class="productPrice__gap-2px"></div>

    <div class="productPrice__gap-2px"></div>

    <!-- 非合规站点 discount info  -->
    <DiscountInfo
      v-if="discountInfo.show"
      :assign-style="bigSmallWordSizeDiscountStyle"
      :retail-price="discountInfo.retailPrice"
      :discount-percent="discountInfo.discountPercent"
      :assign-retail-style="discountInfo.retailPriceStyle"
      :discount-percent-assign-style="discountInfo.discountPercentStyle"
    />

    <!-- 合规站点 discount info  -->
    <template v-if="suggestedSalePriceInfo.show">

      <!-- 法国fr -->
      <DiscountInfo
        v-if="suggestedSalePriceInfo.fr.show"
        :assign-style="bigSmallWordSizeDiscountStyle"
        :assign-retail-style="{'font-size': '11px'}"
        :retail-price="suggestedSalePriceInfo.fr.value"
        :discount-percent="suggestedSalePriceInfo.fr.discountPercent"
        :discount-percent-assign-style="discountInfo.discountPercentStyle"
      />
      <!-- 波兰pl -->
      <DiscountInfoPl
        v-if="suggestedSalePriceInfo.pl.show"
        :assign-style="bigSmallWordSizeDiscountStyle"
        :retail-price="suggestedSalePriceInfo.pl.value"
        :discount-percent="suggestedSalePriceInfo.pl.discountPercent"
        :discount-percent-assign-style="discountInfo.discountPercentStyle"
        :retail-price-tip="suggestedSalePriceInfo.pl.tip"
      />
      <!-- 德国de -->
      <template v-if="suggestedSalePriceInfo.de.show">
        <DiscountInfoDe
          v-if="suggestedSalePriceInfo.de.retailPriceShow"
          :assign-style="bigSmallWordSizeDiscountStyle"
          :retail-price="suggestedSalePriceInfo.de.retailPrice"
          :discount-percent="suggestedSalePriceInfo.de.retailPriceDiscountPercent"
          :discount-percent-assign-style="discountInfo.discountPercentStyle"
          :retail-price-tip="suggestedSalePriceInfo.de.retailTip"
        />
        <DiscountInfoDe
          :assign-style="{
            width: suggestedSalePriceInfo.de.retailPriceShow ? '9rem' : '',
            'margin-top': suggestedSalePriceInfo.de.retailPriceShow ? '.2133rem' : '',
          }"
          :retail-price="suggestedSalePriceInfo.de.value"
          :discount-percent="suggestedSalePriceInfo.de.discountPercent"
          :discount-percent-assign-style="discountInfo.discountPercentStyle"
          :retail-price-tip="suggestedSalePriceInfo.de.valueTip"
        />
      </template>
    </template>

    <template v-if="recommendRetailPrice">
      <div class="productPrice__gap-4px"></div>
      <RecommendRetailPrice
        :is-floor-type="isFloorType"
        :recommend-retail-price="recommendRetailPrice"
      />
    </template>

    <div class="productPrice__gap-4px"></div>

    <S3Tag
      v-if="exclusiveInfo && !exclusiveInfo.isS3newpriceAbtNew"
      :exclusive-info="exclusiveInfo" />

    <div
      v-if="estimatedInfo"
      ref="estimatedTagRef"
      class="productPrice__estimated"
      :style="{'margin-top': estiamtedTagIsWrap ? '.16rem' : ''}"
    >
      <EstimatedTag
        :estimated-info="estimatedInfo"
        :is-wrap="estiamtedTagIsWrap"
        @clickEstimatedTag="emits('clickEstimatedTag')"
        @loaded="calcuEstiamtedTagIsWrap"
      />
    </div>
  </div>
</template>

<script setup name="Price">
/**
 * @components Price 组件不引用 vuex，纯组件
*/
import { computed, onMounted, ref, defineProps, defineAsyncComponent, nextTick, watch, inject } from 'vue'

const S3Tag = defineAsyncComponent(() => import('./components/S3Tag.vue'))
const S3TagNew = defineAsyncComponent(() => import('./components/S3TagNew.vue'))
const EstimatedTag = defineAsyncComponent(() => import('./components/EstimatedTag/index.vue'))
const DiscountInfo = defineAsyncComponent(() => import('./ui/discountInfo/DiscountInfo.vue'))
const DiscountInfoPl = defineAsyncComponent(() => import('./ui/discountInfo/DiscountInfoPl.vue'))
const DiscountInfoDe = defineAsyncComponent(() => import('./ui/discountInfo/DiscountInfoDe.vue'))
const RecommendRetailPrice = defineAsyncComponent(() => import('./ui/RecommendRetailPrice.vue'))

const { triggerNotice } = inject('analysisInstance')


const props = defineProps({
  mainPriceInfo: {
    type: Object,
    default: () => ({}),
  },
  discountInfo: {
    type: Object,
    default: () => ({}),
  },
  estimatedInfo: {
    type: Object,
    default: () => ({}),
  },
  suggestedSalePriceInfo: {
    type: Object,
    default: () => ({}),
  },
  recommendRetailPrice: {
    type: Object,
    default: () => ({}),
  },
  exclusiveInfo: {
    type: Object,
    default: () => ({}),
  },
  config: {
    type: Object,
    default: () => ({
      scene: 'MAIN',
    }),
  },
})


const emits = defineEmits(['clickEstimatedTag'])

const estiamtedTagIsWrap = ref(false)

const mainPriceRef = ref(null)
const estimatedTagRef = ref(null)



const isSheinappwordsizeNew = computed(() => props.mainPriceInfo.isSheinappwordsizeNew)
const calcContainerStyle = computed(() => {
  if (props.estimatedInfo) return {}
  return {
    'align-items': isSheinappwordsizeNew.value ? 'flex-end' : 'center',
  }
})

const bigSmallWordSizeDiscountStyle = computed(() => {
  return {
    'align-items': isSheinappwordsizeNew.value ? 'flex-end' : 'center',
    'transform': isSheinappwordsizeNew.value ? 'translateY(-.0267rem)' : '',
  }
})
const bigSmallWordSizeS3TagNewStyle = computed(() => {
  return {
    'align-items': isSheinappwordsizeNew.value ? 'flex-end' : 'center',
    'transform': isSheinappwordsizeNew.value ? 'translateY(-.0533rem)' : '',
  }
})

const SCENE_MAP = {
  MAIN: 'MAIN', // 主价格
  FLOOR: 'FLOOR', // 悬浮
  SIMPLE: 'SIMPLE', // 简易
}

const isFloorType = computed(() => props.config.scene === SCENE_MAP.FLOOR)
const isSimpleType = computed(() => props.config.scene === SCENE_MAP.SIMPLE)


// 计算两个 dom 是否换行了
const checkTowDomWrap = (mainDom, wrapDom) => {
  let mainDomTop = mainDom?.getBoundingClientRect?.().top
  let wrapDomTop = wrapDom?.getBoundingClientRect?.().top
  let mainDomHeight = mainDom?.getBoundingClientRect?.().height
  return (mainDomTop + mainDomHeight) <= wrapDomTop
}
const calcuEstiamtedTagIsWrap = () => {
  if (!props.estimatedInfo) return
  if (isFloorType.value || isSimpleType.value) return
  estiamtedTagIsWrap.value = checkTowDomWrap(mainPriceRef.value, estimatedTagRef.value)
}

watch(
  [() => props.estimatedInfo, () => props.estimatedInfo?.otherInfo?.realTimeReady],
  async () => {
    await nextTick()
    calcuEstiamtedTagIsWrap()
  },
)

onMounted(() => {
  calcuEstiamtedTagIsWrap()
  if (props.suggestedSalePriceInfo.show && props.config.scene === SCENE_MAP.MAIN) {
    triggerNotice({ id: 'expose_legallowestprice.comp_quick-add-cart-standard' })
  }
})

</script>

<style lang="less">
.productPrice {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  align-items: center;

  &__from {
    line-height: 1;
  }

  &__main {
    font-weight: bold;
    direction: ltr /*rtl:ignore*/;
    color: #222;
    line-height: 1;
  }

  &__discount {
    display: flex;
    align-items: center;
    max-width: 100%;
  }

  &__estimated {
    display: flex;
  }

  &__gap-2px {
    width: .0533rem;
  }

  &__gap-4px {
    width: .1067rem;
  }
}
</style>
